import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { CssVariables } from "../../types/CustomTypes";
import { Liga2Team } from "../../types/Liga2Types";
import { Liga2TeamV2 } from "../../types/Liga2TypesV2";
import { TeamComponent } from "../team/TeamComponent";
import { ShortcutButton } from "./ShortcutButton";

interface Props {
    teamsList: Liga2TeamV2[];
}

const ComponentContainer = styled.div<{}>`
    margin: auto;
    display: flex;
    width: 100%;
    max-width: 1280px;
    border-bottom: 1px solid ${CssVariables.main_color_lightgreen};
`;

const HomeShortcutContainer = styled.div<{}>`
    display: inline-block;
    margin: auto auto auto 0;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        width: 100%;
        display: flex;
    }

    ${mediaQuery.sm} {
    }
`;

const HomeShortcutWrapper = styled.div<{}>`
    display: inline-block;
    margin: auto auto auto 0;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        margin: auto;
    }

    ${mediaQuery.sm} {
    }
`;

// const HomeShortcutImg = styled.div<{}>`
//     width: 60px;
//     height: 60px;
//     background-image: url(${liga2Img});
//     background-size: contain;
//     background-position: center;
//     background-repeat: no-repeat;
// `;

const HeaderContainer = styled.div<{}>`
    display: inline-block;
    margin: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        display: none;
    }

    ${mediaQuery.sm} {
    }
`;

const Container = styled.div<{ comingsoon?: string }>`
    display: flex;
    width: fit-content;
    margin: auto;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const Wrapper = styled.div<{}>`
    padding: 5px;
    margin: auto;
    width: fit-content;
    text-align: center;
`;

// const TeamLogo = styled.div<{ urllogo?: string }>`
//     ${mediaQuery.md} {
//     }

//     ${mediaQuery.sm} {
//     }
// `;

function teamClick(team: Liga2Team): void {
    var url = new URL("/teamdetails", window.location.href);
    url.searchParams.set("id", team.id);
    window.location.href = url.href;
}

export const HeaderComponent: React.FC<Props> = ({ teamsList }) => {
    const _isHomePage: boolean = new URL(window.location.href).pathname === "/";

    return (
        <>
            <ComponentContainer>
                {_isHomePage ? null : (
                    <HomeShortcutContainer>
                        <HomeShortcutWrapper>
                            {/* <HomeShortcutImg></HomeShortcutImg> */}
                            <ShortcutButton text="Home" onClickAction={() => (window.location.href = "/")}></ShortcutButton>
                        </HomeShortcutWrapper>
                    </HomeShortcutContainer>
                )}
                <HeaderContainer>
                    <Container>
                        <Wrapper>
                            {teamsList!
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((team, index) => {
                                    return (
                                        <TeamComponent
                                            showTitle={true}
                                            imgSize={50}
                                            key={team.id}
                                            team={team.id}
                                            hasclick="true"
                                            hastext="false"
                                            teamsList={teamsList}
                                            onClickAction={() => teamClick(team)}></TeamComponent>
                                    );
                                })}
                        </Wrapper>
                    </Container>
                </HeaderContainer>
            </ComponentContainer>
        </>
    );
};
