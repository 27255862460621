import styled from "styled-components";
import { LIGA2OFFICIAL_PLAYERIMAGEDEFAULT, SOFASCORE_PLAYER_IMAGE, SOFASCORE_TEAM_IMAGE, SOFASCORE__URL } from "../../helpers/Constants";
import { getImgForPlayerLiga2, getSofascoreRatingColor, mediaQuery, stringFormat } from "../../helpers/Helpers";
import ballImg from "../../img/ball.png";
import redImg from "../../img/redcard.svg";
import subImg from "../../img/sub.png";
import yellowImg from "../../img/yellowcard.svg";
import { CssVariables, Liga2OccurrenceTypeId } from "../../types/CustomTypes";
import { Liga2OccurrenceV2, Liga2PlayerDetailsV2 } from "../../types/Liga2TypesV2";
import { SofascoreTeamOfTheWeekPlayerV2 } from "../../types/SofaScoreTypes";

interface Props {
    playeroftheweek?: SofascoreTeamOfTheWeekPlayerV2 | undefined;
    playermatch?: Liga2PlayerDetailsV2 | undefined;
    imgSize?: number;
    isSub?: boolean;
    isMvp?: boolean;
    occurences?: Liga2OccurrenceV2[];
}

const Container = styled.div<{}>`
    text-align: center;
    position: relative;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        margin: auto;
    }
`;

const PlayerImageWrapper = styled.div<{}>`
    padding: 2px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        margin: auto;
    }
`;

const PlayerImage = styled.div<{ $playerImg: string; $isSub?: boolean }>`
    background-image: url(${(props) => (props.$playerImg ? props.$playerImg : LIGA2OFFICIAL_PLAYERIMAGEDEFAULT)});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 80px;
    width: 80px;
    margin: auto;
    border-radius: 50%;
    background-color: ${CssVariables.white};
    ${(props) => (props.$isSub ? "border: 1px solid black" : null)};

    ${mediaQuery.lg} {
        width: 70px;
        height: 70px;
    }

    ${mediaQuery.md} {
        width: 35px;
        height: 35px;
    }

    ${mediaQuery.sm} {
        width: 30px;
        height: 30px;
    }
`;

const TeamImage = styled.div<{ $teamImg: string }>`
    background-image: url(${(props) => props.$teamImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 36px;
    width: 36px;
    margin-left: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 18px;
        height: 18px;
    }
`;

const NameWrapper = styled.div<{}>`
    margin: auto;
    font-size: large;
    text-overflow: ellipsis;
    padding: 2px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
        background-color: transparent;
    }
`;

const RatingWrapper = styled.div<{}>`
    width: fit-content;
    display: flex;
    position: absolute;
    top: 0px;
    left: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        top: 10px;
        left: 5px;
        font-size: small;
    }
`;

const TeamWrapper = styled.div<{}>`
    width: fit-content;
    display: flex;
    position: absolute;
    top: 70px;
    right: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        top: 40px;
    }
`;

const Rating = styled.span<{ $rating: number }>`
    border-radius: 25%;
    width: fit-content;
    padding: 5px;
    background-color: ${(props) => getSofascoreRatingColor(props.$rating)};
    border: 2px solid ${CssVariables.black};
    display: flex;
    align-items: center;
    color: ${CssVariables.black};
    font-family: ${CssVariables.font_family_base};
    font-weight: bold;
    font-size: ${CssVariables.sofascore_rating_size};

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 2px;
        font-size: ${CssVariables.font_size_sm};
        border-width: 1px;
    }
`;

const SpanText = styled.span<{ $isSub?: boolean }>`
    margin: auto;
    color: ${(props) => (props.$isSub ? `${CssVariables.black}` : `${CssVariables.white}`)};

    font-weight: bold;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${CssVariables.font_size_xs};

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_xs};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_xxs};
        white-space: ${(props) => (props.$isSub ? null : "pre")};
    }
`;

const PlayerSubWrapper = styled.div<{ $playerImgSize?: number; $isSub?: boolean }>`
    border: 1px solid black;
    border-radius: 50%;
    width: fit-content;
    height: fit-content;
    top: 0;
    left: ${(props) => (props.$isSub ? "0" : "19px")};
    position: absolute;
    background: ${CssVariables.white};

    ${mediaQuery.sm} {
        right: ${(props) => (props.$isSub ? "0" : "12px")};
    }
`;

const PlayerSubImg = styled.div<{ $playerImgSize?: number }>`
    background-image: url(${subImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 14px;
    width: 14px;

    ${mediaQuery.sm} {
        height: 10px;
        width: 10px;
    }
`;

const PlayerYellowWrapper = styled.div<{ $playerImgSize?: number; $isSub?: boolean }>`
    width: fit-content;
    height: fit-content;
    top: 0px;
    right: ${(props) => (props.$isSub ? "0" : "19px")};
    position: absolute;

    ${mediaQuery.sm} {
        right: ${(props) => (props.$isSub ? "0" : "12px")};
    }
`;

const PlayerYellowImg = styled.div<{ $playerImgSize?: number }>`
    background-image: url(${yellowImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 18px;
    width: 9px;
`;

const PlayerDoubleYellowWrapper = styled.div<{ $playerImgSize?: number; $isSub?: boolean }>`
    width: fit-content;
    height: fit-content;
    top: 0px;
    right: ${(props) => (props.$isSub ? "0" : "15px")};
    position: absolute;
    display: flex;

    ${mediaQuery.sm} {
        right: ${(props) => (props.$isSub ? "0" : "8px")};
    }
`;

const PlayerRedWrapper = styled.div<{ $playerImgSize?: number; $isSub?: boolean }>`
    width: fit-content;
    height: fit-content;
    top: 0px;
    right: ${(props) => (props.$isSub ? "0" : "19px")};
    position: absolute;

    ${mediaQuery.sm} {
        right: ${(props) => (props.$isSub ? "0" : "12px")};
    }
`;

const PlayerRedImg = styled.div<{ $playerImgSize?: number }>`
    background-image: url(${redImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 18px;
    width: 9px;
`;

const GoalWrapper = styled.div<{ $playerImgSize?: number; $isSub?: boolean }>`
    width: fit-content;
    height: fit-content;
    bottom: 14px;
    right: ${(props) => (props.$isSub ? "0" : "19px")};
    position: absolute;

    ${mediaQuery.sm} {
        right: ${(props) => (props.$isSub ? "0" : "12px")};
    }
`;

const GoalImg = styled.div<{ $playerImgSize?: number }>`
    background-image: url(${ballImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 12px;
    width: 12px;
    border: 1px solid white;
    border-radius: 50%;
`;

function getPlayerImg(_playerId: number) {
    const urlSofascorePlayerImage = SOFASCORE__URL + SOFASCORE_PLAYER_IMAGE;
    const urlPlayerImage = stringFormat(urlSofascorePlayerImage, _playerId.toString());
    return urlPlayerImage;
}

function getTeamImg(_teamId: number) {
    const urlSofascoreTeamImage = SOFASCORE__URL + SOFASCORE_TEAM_IMAGE;
    const urlTeamImage = stringFormat(urlSofascoreTeamImage, _teamId.toString());
    return urlTeamImage;
}

export const PlayerComponentResp: React.FC<Props> = ({ playeroftheweek, playermatch, imgSize, isSub, isMvp, occurences }) => {
    const wasSubbedIn = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Substituição Sai"]);
    const wasSubbedOut = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Substituição Entra"]);
    const hasYellow = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Cartão Amarelo"]);
    const hasDoubleYellow = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Segundo Amarelo"]);
    const hasRed = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Cartão Vermelho"]);
    const hasGoal = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Golo"]);

    return (
        <>
            {playeroftheweek ? (
                <Container key={playeroftheweek.id}>
                    <PlayerImageWrapper>
                        <PlayerImage $playerImg={getPlayerImg(playeroftheweek.id)}></PlayerImage>
                    </PlayerImageWrapper>
                    <NameWrapper>
                        <SpanText>{playeroftheweek.name}</SpanText>
                    </NameWrapper>
                    <RatingWrapper>
                        <Rating $rating={playeroftheweek.rating}>{playeroftheweek.rating}</Rating>
                    </RatingWrapper>
                    <TeamWrapper>
                        <TeamImage $teamImg={getTeamImg(playeroftheweek.teamId)}></TeamImage>
                    </TeamWrapper>
                </Container>
            ) : null}

            {playermatch ? (
                <Container key={playermatch.id}>
                    <PlayerImageWrapper>
                        <PlayerImage $isSub={isSub} $playerImg={getImgForPlayerLiga2(playermatch.mediaUrl, 50)}></PlayerImage>
                    </PlayerImageWrapper>
                    <NameWrapper>
                        <SpanText $isSub={isSub}>{playermatch.name}</SpanText>
                    </NameWrapper>

                    {wasSubbedIn || wasSubbedOut ? (
                        <PlayerSubWrapper $isSub={isSub} $playerImgSize={imgSize ? imgSize / 3 : null}>
                            <PlayerSubImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerSubImg>
                        </PlayerSubWrapper>
                    ) : null}

                    {hasDoubleYellow ? (
                        <PlayerDoubleYellowWrapper $isSub={isSub} $playerImgSize={imgSize ? imgSize / 3 : null}>
                            <PlayerYellowImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerYellowImg>
                            <PlayerYellowImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerYellowImg>
                        </PlayerDoubleYellowWrapper>
                    ) : (
                        <>
                            {hasYellow ? (
                                <PlayerYellowWrapper $isSub={isSub} $playerImgSize={imgSize ? imgSize / 3 : null}>
                                    <PlayerYellowImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerYellowImg>
                                </PlayerYellowWrapper>
                            ) : null}
                        </>
                    )}

                    {hasRed ? (
                        <PlayerRedWrapper $isSub={isSub} $playerImgSize={imgSize ? imgSize / 3 : null}>
                            <PlayerRedImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerRedImg>
                        </PlayerRedWrapper>
                    ) : null}

                    {hasGoal ? (
                        <GoalWrapper $isSub={isSub} $playerImgSize={imgSize ? imgSize / 3 : null}>
                            <GoalImg $playerImgSize={imgSize ? imgSize / 3 : null}></GoalImg>
                        </GoalWrapper>
                    ) : null}
                </Container>
            ) : null}
        </>
    );
};
