import {
    SofascoreEventDetails,
    SofascoreEventPlayerStatistics,
    SofascoreEventStatistics,
    SofascoreEvents,
    SofascoreLineups,
    SofascoreSeasons,
    SofascoreStandings,
    SofascoreTeamOfTheWeekPlayerV2,
    SofascoreTeamOfTheWeekResponse,
    SofascoreTeamOfTheWeekRoundsResponse,
    SofascoreTeamOfTheWeekV2,
    SofascoreUniqueTournaments,
} from "../types/SofaScoreTypes";
import { getApiDataGeneric } from "./ApiData";
import {
    LIGA2_USECACHE,
    SOFASCORE_EVENTDETAILS,
    SOFASCORE_EVENTLINEUPS,
    SOFASCORE_EVENTPLAYERSTATISTICS,
    SOFASCORE_EVENTSTATISTICS,
    SOFASCORE_TEAMOFTHEWEEK,
    SOFASCORE_TEAMOFTHEWEEK_ROUNDS,
    SOFASCORE_TOURNAMENTEVENTSAFTER,
    SOFASCORE_TOURNAMENTEVENTSBEFORE,
    SOFASCORE_TOURNAMENTS,
    SOFASCORE_TOURNAMENTSEASONS,
    SOFASCORE_TOURNAMENTSTANDINGS,
    SOFASCORE__URL,
    apiDelay,
} from "./Constants";
import { delay, stringFormat } from "./Helpers";

export async function ManageSofascoreTournamentsSessionData(): Promise<SofascoreUniqueTournaments> {
    var _output: SofascoreUniqueTournaments;
    const urlApi = SOFASCORE__URL + SOFASCORE_TOURNAMENTS;
    const sessionID = "SofascoreUniqueTournamentsResponse";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreUniqueTournaments;
    } else {
        const _apiResponse = await getApiDataGeneric(urlApi);
        _output = _apiResponse["groups"][0];
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreSeasonsSessionData(): Promise<SofascoreSeasons> {
    var _output: SofascoreSeasons;
    const urlApi = SOFASCORE__URL + SOFASCORE_TOURNAMENTSEASONS;
    const sessionID = "SS_SEASONS";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreSeasons;
    } else {
        _output = await getApiDataGeneric(urlApi);
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreStandingsSessionData(_season: number): Promise<SofascoreStandings> {
    var _output: SofascoreStandings;
    const urlApi = SOFASCORE__URL + SOFASCORE_TOURNAMENTSTANDINGS;
    const urlApiFormatted = stringFormat(urlApi, _season.toString());
    const sessionID = "SofascoreStandingsResponse" + _season;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreStandings;
    } else {
        const _apiResponse = await getApiDataGeneric(urlApiFormatted);
        _output = _apiResponse["standings"][0];
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreEventsBeforeSessionData(_season: number): Promise<SofascoreEvents> {
    var _output: SofascoreEvents;
    const urlApi = SOFASCORE__URL + SOFASCORE_TOURNAMENTEVENTSBEFORE;
    const urlApiFormatted = stringFormat(urlApi, _season.toString(), "0");
    const sessionID = "SofascoreEventsBeforeResponse" + _season;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreEvents;
    } else {
        const _apiResponse = await getApiDataGeneric(urlApiFormatted);
        _output = _apiResponse;
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreEventsAfterSessionData(_season: number): Promise<SofascoreEvents> {
    var _output: SofascoreEvents;
    var _apiIndex = 0;
    var _hasNextPage = false;
    const urlApi = SOFASCORE__URL + SOFASCORE_TOURNAMENTEVENTSAFTER;
    const urlApiFormatted = stringFormat(urlApi, _season.toString(), _apiIndex);
    const sessionID = "SofascoreEventsAfterResponse" + _season;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreEvents;
    } else {
        const _apiResponse: SofascoreEvents = await getApiDataGeneric(urlApiFormatted);
        var _tempOutput = _apiResponse;
        _hasNextPage = _apiResponse.hasNextPage;

        while (_hasNextPage) {
            await delay(apiDelay);
            _apiIndex += 1;
            const urlApiFormatted = stringFormat(urlApi, _season.toString(), _apiIndex);
            const _apiResponseNextPage: SofascoreEvents = await getApiDataGeneric(urlApiFormatted);
            _tempOutput.events = _tempOutput.events.concat(_apiResponseNextPage.events);
            _hasNextPage = _apiResponseNextPage.hasNextPage;
        }

        _output = _tempOutput;
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreEventDetailsSessionData(_eventId: number): Promise<SofascoreEventDetails> {
    var _output: SofascoreEventDetails;
    const urlApi = SOFASCORE__URL + SOFASCORE_EVENTDETAILS;
    const urlApiFormatted = stringFormat(urlApi, _eventId.toString());
    const sessionID = "SofascoreEventDetailsResponse" + _eventId;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreEventDetails;
    } else {
        const _apiResponse = await getApiDataGeneric(urlApiFormatted);
        _output = _apiResponse;
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreEventLineupsSessionData(_eventId: number): Promise<SofascoreLineups> {
    var _output: SofascoreLineups;
    const urlApi = SOFASCORE__URL + SOFASCORE_EVENTLINEUPS;
    const urlApiFormatted = stringFormat(urlApi, _eventId.toString());
    const sessionID = "SofascoreEventLineupsResponse" + _eventId;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreLineups;
    } else {
        const _apiResponse = await getApiDataGeneric(urlApiFormatted);
        _output = _apiResponse;
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreEventStatisticsSessionData(_eventId: number): Promise<SofascoreEventStatistics> {
    var _output: SofascoreEventStatistics;
    const urlApi = SOFASCORE__URL + SOFASCORE_EVENTSTATISTICS;
    const urlApiFormatted = stringFormat(urlApi, _eventId.toString());
    const sessionID = "SofascoreEventStatisticsResponse" + _eventId;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreEventStatistics;
    } else {
        const _apiResponse = await getApiDataGeneric(urlApiFormatted);
        _output = _apiResponse;
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreEventPlayerStatisticsSessionData(_eventId: number, _playerId: number): Promise<SofascoreEventPlayerStatistics> {
    var _output: SofascoreEventPlayerStatistics;
    const urlApi = SOFASCORE__URL + SOFASCORE_EVENTPLAYERSTATISTICS;
    const urlApiFormatted = stringFormat(urlApi, _eventId.toString(), _playerId.toString());
    const sessionID = "SofascoreEventPlayerStatisticsResponse" + _eventId + _playerId;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreEventPlayerStatistics;
    } else {
        const _apiResponse = await getApiDataGeneric(urlApiFormatted);
        _output = _apiResponse;
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreTeamOfTheWeekRoundsSessionData(_season: number): Promise<SofascoreTeamOfTheWeekRoundsResponse> {
    var _output: SofascoreTeamOfTheWeekRoundsResponse;
    const urlApi = SOFASCORE__URL + SOFASCORE_TEAMOFTHEWEEK_ROUNDS;
    const urlApiFormatted = stringFormat(urlApi, _season.toString());

    const sessionID = "SS_TOTW_SEASON_" + _season;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreTeamOfTheWeekRoundsResponse;
    } else {
        _output = await getApiDataGeneric(urlApiFormatted);
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreTeamOfTheWeekSessionData(_season: number, _round: number): Promise<SofascoreTeamOfTheWeekResponse> {
    var _output: SofascoreTeamOfTheWeekResponse;
    const urlApi = SOFASCORE__URL + SOFASCORE_TEAMOFTHEWEEK;
    const urlApiFormatted = stringFormat(urlApi, _season.toString(), _round.toString());

    const sessionID = "SofascoreTeamOfTheWeekResponse" + _season + _round;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreTeamOfTheWeekResponse;
    } else {
        _output = await getApiDataGeneric(urlApiFormatted);
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageSofascoreTeamOfTheWeekSessionDataV2(_season: number, _round: number): Promise<SofascoreTeamOfTheWeekV2> {
    var _output: SofascoreTeamOfTheWeekV2;
    const urlApi = SOFASCORE__URL + SOFASCORE_TEAMOFTHEWEEK;
    const urlApiFormatted = stringFormat(urlApi, _season.toString(), _round.toString());

    const sessionID = "SS_TOTW_" + _season + "_" + _round;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as SofascoreTeamOfTheWeekV2;
    } else {
        const sofascoreTeamOfTheWeekSessionData = await getApiDataGeneric(urlApiFormatted);

        if (sofascoreTeamOfTheWeekSessionData !== "undefined") {
            const _outputPlayerList: SofascoreTeamOfTheWeekPlayerV2[] = [];
            sofascoreTeamOfTheWeekSessionData.players.forEach((_player) => {
                const _outputElementPlayer: SofascoreTeamOfTheWeekPlayerV2 = {
                    id: _player.player.id,
                    position: _player.player.position,
                    name: _player.player.name,
                    rating: _player.rating,
                    teamId: _player.team.id,
                };
                _outputPlayerList.push(_outputElementPlayer);
            });

            _output = {
                round: _round,
                players: _outputPlayerList,
            };

            if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
        }
    }
    return _output;
}
