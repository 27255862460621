import { useEffect, useState } from "react";
import { CustomDropdown } from "../components/app/CustomDropdown";
import { HeaderTabsComponent } from "../components/app/HeaderTabsComponent";
import { Liga2Toggle } from "../components/app/Liga2Toggle";
import { LoadingComponent } from "../components/app/LoadingComponent";
import { LeadersByWeek } from "../components/league/LeadersByWeek";
import { MatchListComponent } from "../components/league/MatchListComponent";
import { SofascoreTeamComponent } from "../components/league/SofascoreTeamComponent";
import { StandingsComponent } from "../components/league/StandingsComponent";
import { MatchListRefereesComponent } from "../components/refs/MatchListRefereesComponent";
import {
    GetLiga2V2CurrentRound,
    GetLiga2V2IsLastRound,
    GetLiga2V2PlayedRounds,
    ManageLiga2V2NominationsData,
    ManageLiga2V2RoundMatchesSessionData,
    ManageLiga2V2RoundsSessionData,
    ManageLiga2V2SeasonsSessionData,
    ManageLiga2V2StandingsSessionData,
    ManageLiga2V2TeamsSessionData,
    getChartDataForPoints,
    getChartDataForWeeklyLeaders,
} from "../helpers/ApiResponseLiga2V2";
import * as ApiResponseSS from "../helpers/ApiResponseSofascore";
import { getDdlItems } from "../helpers/Helpers";
import "../styles/home.scss";
import { Liga2WeeklyLeadersRechartType, MediaSize } from "../types/CustomTypes";
import { Liga2MatchInfoV2, Liga2NominationsV2, Liga2RoundsV2, Liga2SeasonsV2, Liga2StandingsByRoundsV2, Liga2StandingsV2, Liga2TeamV2 } from "../types/Liga2TypesV2";
import { SofascoreTeamOfTheWeekRoundsResponse, SofascoreTeamOfTheWeekV2 } from "../types/SofaScoreTypes";

const Home = () => {
    const [headerTabSelected, headerTabSelectedSet] = useState<number>(1);

    const [sofascoreTeamsOfTheWeekRounds, sofascoreTeamsOfTheWeekRoundsSet] = useState<SofascoreTeamOfTheWeekRoundsResponse | undefined>(undefined);
    const [sofascoreTeamsOfTheWeekV2, sofascoreTeamsOfTheWeekV2Set] = useState<SofascoreTeamOfTheWeekV2[] | undefined>(undefined);
    const [sofascoreCurrentTeamOfTheWeekV2, sofascoreCurrentTeamOfTheWeekV2Set] = useState<SofascoreTeamOfTheWeekV2 | undefined>(undefined);

    //V2
    const [liga2V2Seasons, liga2V2SeasonsSet] = useState<Liga2SeasonsV2[] | undefined>(undefined);
    const [liga2V2Standings, liga2V2StandingsSet] = useState<Liga2StandingsV2[] | undefined>(undefined);
    const [liga2V2Rounds, liga2V2RoundsSet] = useState<Liga2RoundsV2[] | undefined>(undefined);
    const [liga2V2CurrentRound, liga2V2CurrentRoundSet] = useState<number | undefined>(undefined);
    const [liga2V2PlayedRounds, liga2V2PlayedRoundsSet] = useState<Liga2RoundsV2[] | undefined>(undefined);
    const [liga2V2TeamList, liga2V2TeamListSet] = useState<Liga2TeamV2[] | undefined>(undefined);
    const [liga2V2RoundMatches, liga2V2RoundMatchesSet] = useState<Liga2MatchInfoV2[] | undefined>(undefined);
    const [liga2V2NextRoundMatches, liga2V2NextRoundMatchesSet] = useState<Liga2MatchInfoV2[] | undefined>(undefined);
    const [liga2V2IsLastRound, liga2V2IsLastRoundSet] = useState<boolean>(false);

    const [liga2V2StandingsAllRounds, liga2V2StandingsAllRoundsSet] = useState<Liga2StandingsByRoundsV2[] | undefined>(undefined);
    const [liga2V2ChartDataForWeeklyLeaders, liga2V2ChartDataForWeeklyLeadersSet] = useState<Liga2WeeklyLeadersRechartType[] | undefined>(undefined);
    const [liga2V2ChartDataForPoints, liga2V2ChartDataForPointsSet] = useState<Liga2WeeklyLeadersRechartType[] | undefined>(undefined);

    const [pageW, pageWSet] = useState<number>(window.innerWidth);
    const [isMobile, isMobileWSet] = useState<boolean>(window.innerWidth < MediaSize.md);
    const [showWeeks, showWeeksSet] = useState<boolean>(false);
    const [showWeeksNext, showWeeksNextSet] = useState<boolean>(false);

    const [nominationsData, nominationsDataSet] = useState<Liga2NominationsV2[] | undefined>(undefined);
    const [ddlNominations, ddlNominationsSet] = useState<number | undefined>(undefined);

    useEffect(() => {
        const getData = async () => {
            // #region SofaScore
            const sofascoreSeasons = await ApiResponseSS.ManageSofascoreSeasonsSessionData();

            if (sofascoreSeasons && sofascoreSeasons.seasons.length > 0) {
                const sofascoreCurrentSeason = sofascoreSeasons.seasons[0].id;

                const sofascoreWeekRounds = await ApiResponseSS.ManageSofascoreTeamOfTheWeekRoundsSessionData(sofascoreCurrentSeason);
                sofascoreTeamsOfTheWeekRoundsSet(sofascoreWeekRounds);

                const _sofascoreTeamOfTheWeekListV2: SofascoreTeamOfTheWeekV2[] = [];
                if (sofascoreWeekRounds && sofascoreWeekRounds.rounds.length > 0) {
                    for (let index = 0; index < sofascoreWeekRounds.rounds.length; index++) {
                        const _sofascoreTeamsOfTheWeekV2 = await ApiResponseSS.ManageSofascoreTeamOfTheWeekSessionDataV2(sofascoreCurrentSeason, sofascoreWeekRounds.rounds[index].id);
                        _sofascoreTeamOfTheWeekListV2.push(_sofascoreTeamsOfTheWeekV2);
                    }
                }

                sofascoreTeamsOfTheWeekV2Set(_sofascoreTeamOfTheWeekListV2);
                sofascoreCurrentTeamOfTheWeekV2Set(_sofascoreTeamOfTheWeekListV2[0]);
            }
            // #endregion SofaScore

            // #region Liga2
            const _liga2V2StandingsList = await ManageLiga2V2StandingsSessionData();
            liga2V2StandingsSet(_liga2V2StandingsList);

            const _liga2V2TeamsList = await ManageLiga2V2TeamsSessionData();
            liga2V2TeamListSet(_liga2V2TeamsList);

            const _liga2V2RoundsList = await ManageLiga2V2RoundsSessionData();
            liga2V2RoundsSet(_liga2V2RoundsList);

            const _liga2CurrentRound = await GetLiga2V2CurrentRound();
            liga2V2CurrentRoundSet(_liga2CurrentRound);

            // const _liga2StandingsAllRounds = await GetLiga2V2StandingsAllRounds();
            // liga2V2StandingsAllRoundsSet(_liga2AllRounds);

            const _liga2V2PlayedRounds = await GetLiga2V2PlayedRounds();
            liga2V2PlayedRoundsSet(_liga2V2PlayedRounds);

            // const _liga2V2StandingsByRound = await GetLiga2V2StandingsByRound(1);

            const _liga2V2Seasons = await ManageLiga2V2SeasonsSessionData();
            liga2V2SeasonsSet(_liga2V2Seasons);

            const _roundMatches = await ManageLiga2V2RoundMatchesSessionData(_liga2CurrentRound);
            liga2V2RoundMatchesSet(_roundMatches);

            const _isLastRound = await GetLiga2V2IsLastRound();
            liga2V2IsLastRoundSet(_isLastRound);

            if (!_isLastRound) {
                const _roundMatchesNext = await ManageLiga2V2RoundMatchesSessionData(_liga2CurrentRound + 1);
                liga2V2NextRoundMatchesSet(_roundMatchesNext);
            }
            // #endregion Liga2

            const chartdataforweeklyleaders = await getChartDataForWeeklyLeaders();
            liga2V2ChartDataForWeeklyLeadersSet(chartdataforweeklyleaders);

            const chartdataforpoints = await getChartDataForPoints();
            liga2V2ChartDataForPointsSet(chartdataforpoints);

            const _noms = await ManageLiga2V2NominationsData(_liga2CurrentRound);
            nominationsDataSet(_noms);
        };

        getData();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            pageWSet(window.innerWidth);
            isMobileWSet(window.innerWidth < MediaSize.md);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        return () => {};
    });

    function setDDL() {
        const _value: number[] = [];
        const _label: string[] = [];

        sofascoreTeamsOfTheWeekRounds?.rounds.forEach((element, index) => {
            _value.push(index);
            _label.push("Week " + element.roundName);
        });

        return getDdlItems(_value, _label, true);
    }

    function onchangeDDL(_value: number) {
        sofascoreCurrentTeamOfTheWeekV2Set(sofascoreTeamsOfTheWeekV2![_value]);
    }

    function tabclick(_tab: number) {
        headerTabSelectedSet(_tab);
    }

    function showWeeksclick(_value: boolean) {
        showWeeksSet(_value);
    }

    function showWeeksNextclick(_value: boolean) {
        showWeeksNextSet(_value);
    }

    function setDDLNominations() {
        const _value: number[] = [];
        const _label: string[] = [];

        const roundsSortedReverse = liga2V2PlayedRounds.sort((a, b) => b.number - a.number);
        console.log(roundsSortedReverse);

        roundsSortedReverse?.forEach((element, index) => {
            _value.push(element.number);
            _label.push("Round " + element.number);
        });

        return getDdlItems(_value, _label, true);
    }

    async function onchangeDDLNominations(_value: string) {
        const _valueSelected = Number.parseInt(_value);
        ddlNominationsSet(_valueSelected + 1);
        nominationsDataSet(undefined);
        const _noms = await ManageLiga2V2NominationsData(_valueSelected + 1);
        nominationsDataSet(_noms);
    }

    return (
        <div className="homePage">
            {liga2V2TeamList && liga2V2Standings && liga2V2RoundMatches ? (
                <>
                    {sofascoreCurrentTeamOfTheWeekV2 && liga2V2ChartDataForWeeklyLeaders && liga2V2ChartDataForPoints ? (
                        <div className="homePage__tabs">
                            <HeaderTabsComponent onclicktab={tabclick}></HeaderTabsComponent>
                        </div>
                    ) : (
                        <LoadingComponent></LoadingComponent>
                    )}

                    {isMobile && headerTabSelected === 1 ? (
                        <div className="homePage__toggles">
                            <Liga2Toggle text="Show Week" onClickAction={showWeeksclick}></Liga2Toggle>
                            {showWeeks ? <Liga2Toggle text="Next" onClickAction={showWeeksNextclick}></Liga2Toggle> : null}
                        </div>
                    ) : null}

                    <div className="homePage__homeData">
                        {headerTabSelected === 1 ? (
                            <>
                                {(isMobile && !showWeeks) || !isMobile ? (
                                    <div className="homePage__homeData--standings">
                                        <StandingsComponent teamList={liga2V2TeamList} sortedcolumn={0} standings={liga2V2Standings} matchWeek={liga2V2CurrentRound}></StandingsComponent>
                                    </div>
                                ) : null}
                                {(isMobile && showWeeks && !showWeeksNext) || !isMobile ? (
                                    <div className="homePage__homeData--currentWeek">
                                        <MatchListComponent roundNumber={liga2V2CurrentRound} teamsList={liga2V2TeamList} matchListWeek={liga2V2RoundMatches}></MatchListComponent>
                                    </div>
                                ) : null}

                                {(isMobile && showWeeks && showWeeksNext) || !isMobile ? (
                                    <>
                                        {!liga2V2IsLastRound ? (
                                            <div className="homePage__homeData--nextWeek">
                                                <MatchListComponent roundNumber={liga2V2CurrentRound + 1} teamsList={liga2V2TeamList} matchListWeek={liga2V2NextRoundMatches}></MatchListComponent>
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                            </>
                        ) : null}
                        {headerTabSelected === 2 ? (
                            <div className="homePage__homeData--teamOfTheWeek">
                                <CustomDropdown ddItems={setDDL()} onChangeDrop={onchangeDDL}></CustomDropdown>
                                <SofascoreTeamComponent playerlist={sofascoreCurrentTeamOfTheWeekV2}></SofascoreTeamComponent>
                            </div>
                        ) : null}
                        {headerTabSelected === 3 ? (
                            <div className="homePage__homeData--weekLeaders">
                                <div className="weekLeaders__content">
                                    <LeadersByWeek teamList={liga2V2TeamList} chartDataStandings={liga2V2ChartDataForWeeklyLeaders} chartDataPoints={liga2V2ChartDataForPoints}></LeadersByWeek>
                                </div>
                            </div>
                        ) : null}

                        {headerTabSelected === 4 ? (
                            <div className="homePage__homeData--refs">
                                <div className="refs__content">
                                    <CustomDropdown ddItems={setDDLNominations()} onChangeDrop={onchangeDDLNominations}></CustomDropdown>
                                    {nominationsData ? <MatchListRefereesComponent refList={nominationsData} teamsList={liga2V2TeamList}></MatchListRefereesComponent> : <LoadingComponent></LoadingComponent>}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            ) : (
                <>
                    <LoadingComponent></LoadingComponent>
                </>
            )}
        </div>
    );
};

export default Home;
