import React from "react";
import styled from "styled-components";
import { getImgForTeamLiga2, mediaQuery } from "../../helpers/Helpers";
import { Liga2TeamV2 } from "../../types/Liga2TypesV2";

interface Props {
    team: Liga2TeamV2;
}

const Container = styled.div<{}>`
    width: fit-content;
    text-align: center;
    margin: auto;
    display: flex;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const Stadium = styled.div<{}>`
    display: block;
    margin: auto auto auto 0;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        margin: auto;
    }
`;

const Wrapper = styled.div<{}>`
    width: fit-content;
    background-color: #fff;
    margin: auto 0 auto auto;
    display: flex;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        margin: auto;
    }
`;

const TeamImg = styled.div<{ $img: string }>`
    background-image: url(${(props) => props.$img});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 85px;
    width: 85px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const TeamDescText = styled.span<{}>`
    display: block;
`;

export const TeamDetailsComponent: React.FC<Props> = ({ team }) => {
    return (
        <Container>
            <Wrapper>
                <TeamImg $img={getImgForTeamLiga2(team.imgUrl, 85)}></TeamImg>
            </Wrapper>
            <Stadium>
                <TeamDescText>{team.abbreviation}</TeamDescText>
                <TeamDescText>{team.name}</TeamDescText>
            </Stadium>
        </Container>
    );
};
