import React from "react";
import styled from "styled-components";
import { dateFormatDateOnly, dateFormatMatchComponentSmaller } from "../../helpers/Constants";
import { getDateInFormat, mediaQuery } from "../../helpers/Helpers";
import { CssVariables } from "../../types/CustomTypes";
import { Liga2MatchInfoV2, Liga2TeamV2 } from "../../types/Liga2TypesV2";
import { TeamComponent } from "../team/TeamComponent";

interface Props {
    match: Liga2MatchInfoV2;
    noBorder?: boolean;
    matchweek?: number;
    teamsList: Liga2TeamV2[];
    imgSize?: number;
    hideName?: boolean;
    smallerSize?: boolean;
}

const Container = styled.div<{ $clickable: boolean; $smallerSize?: boolean }>`
    width: ${(props) => (props.$smallerSize ? "fit-content" : "320px")};
    background: #ffffff;
    padding: 2px;
    display: inline-block;

    * {
        cursor: ${(props) => (props.$clickable ? "pointer" : undefined)};
    }

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: ${(props) => (props.$smallerSize ? "fit-content" : "260px")};
        font-size: ${CssVariables.font_size_sm};
    }
`;

const TeamWrapper = styled.div<{}>`
    background-color: #fff;
    margin: auto;
    width: 40%;
    display: flex;
`;

const ScoreWrapper = styled.div<{ $smallerSize?: boolean }>`
    background-color: #fff;
    margin: auto;
    width: ${(props) => (props.$smallerSize ? "fit-content" : "20%")};
    ${(props) => (props.$smallerSize ? "  white-space: pre;" : null)}
`;

const DateWrapper = styled.div<{ $smallerSize?: boolean }>`
    display: flex;
    width: 100%;
    padding-bottom: ${(props) => (props.$smallerSize ? null : "10px")};
    text-transform: uppercase;
    > span {
        border-bottom: ${(props) => (props.$smallerSize ? null : "1px dashed #0c785e")};
    }
`;

const TeamsWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
`;

const Border = styled.div<{ $noBorder: boolean; $smallerSize?: boolean }>`
    background-color: #fff;
    ${(props) => (props.$noBorder ? undefined : "border: 1px solid #0c785e;")}
    display: flex;
    flex-direction: ${(props) => (props.$smallerSize ? "row" : "column")};
    padding: ${(props) => (props.$smallerSize ? "0" : "5px")};
    text-align: center;
`;

const TeamDescText = styled.span<{}>`
    width: 50%;
`;

const TeamDescText100 = styled.span<{ $smallerSize?: boolean }>`
    width: fit-content;
    padding: 0px 5px;
    margin: auto;
`;

function matchClick(_matchId: string, _week: number): void {
    var url = new URL("/matchdetails", window.location.href);
    url.searchParams.set("matchid", _matchId);
    url.searchParams.set("week", _week.toString());
    window.location.href = url.href;
}

export const MatchComponent: React.FC<Props> = ({ match, teamsList, noBorder, matchweek, imgSize, hideName, smallerSize }) => {
    return (
        <>
            <Container $smallerSize={smallerSize} $clickable={match.fixtureStateTypeId > 0} onClick={(event) => (match.fixtureStateTypeId > 0 ? matchClick(match.id!, matchweek!) : null)}>
                <Border $smallerSize={smallerSize} $noBorder={noBorder ? noBorder : false}>
                    {!smallerSize ? (
                        <DateWrapper $smallerSize={smallerSize}>
                            {match.fixtureDateIsDefined ? (
                                <TeamDescText100>{getDateInFormat(match.matchDate)}</TeamDescText100>
                            ) : (
                                <TeamDescText100>{getDateInFormat(match.matchDate, dateFormatDateOnly)}</TeamDescText100>
                            )}
                        </DateWrapper>
                    ) : null}

                    <TeamsWrapper>
                        <TeamWrapper>
                            <TeamComponent team={match.homeTeamId.toString()} imgSize={imgSize} hastext={hideName ? "false" : "true"} hasclick="false" teamsList={teamsList}></TeamComponent>
                        </TeamWrapper>
                        <ScoreWrapper $smallerSize={smallerSize}>{match.fixtureStateTypeId > 0 ? <TeamDescText>{match.homeTeamGoals + " - " + match.awayTeamGoals}</TeamDescText> : null}</ScoreWrapper>
                        <TeamWrapper>
                            <TeamComponent team={match.awayTeamId.toString()} imgSize={imgSize} hastext={hideName ? "false" : "true"} hasclick="false" teamsList={teamsList}></TeamComponent>
                        </TeamWrapper>
                    </TeamsWrapper>

                    {smallerSize ? <TeamDescText100 $smallerSize={smallerSize}>{getDateInFormat(match.matchDate, dateFormatMatchComponentSmaller)}</TeamDescText100> : null}
                </Border>
            </Container>
        </>
    );
};
