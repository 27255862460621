import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { Liga2MatchInfoV2, Liga2TeamV2 } from "../../types/Liga2TypesV2";
import { MatchComponent } from "./MatchComponent";

interface Props {
    matchListWeek?: Liga2MatchInfoV2[];
    matchListCal?: Liga2MatchInfoV2[];
    teamsList: Liga2TeamV2[];
    roundNumber?: number;
    matchListHistory?: Liga2MatchInfoV2[];
    inputRef?: any;
    currentRound?: number;

    onRendComp?: Function;
}

const Container = styled.div<{}>`
    background: #ffffff;
    width: fit-content;
    padding: 15px;
`;

const RoundNumberContainer = styled.div<{}>`
    width: fit-content;
    padding: 10px;
    margin: auto;
`;

const RoundNumberTxt = styled.span<{}>`
    font-size: 18px;
    ${mediaQuery.md} {
        font-size: 15px;
    }

    ${mediaQuery.sm} {
        font-size: 13px;
    }
`;

export const MatchListComponent: React.FC<Props> = ({ matchListWeek, teamsList, matchListCal, roundNumber, matchListHistory, inputRef, currentRound, onRendComp }) => {
    if (onRendComp) onRendComp();

    return (
        <>
            <Container>
                {roundNumber ? (
                    <RoundNumberContainer>
                        <RoundNumberTxt>Round {roundNumber}</RoundNumberTxt>
                    </RoundNumberContainer>
                ) : null}
                {matchListWeek
                    ? matchListWeek.map((match, index) => {
                          return (
                              <div key={match.id}>
                                  <MatchComponent match={match} matchweek={match.matchweekNumber} teamsList={teamsList} imgSize={50}></MatchComponent>
                              </div>
                          );
                      })
                    : null}

                {matchListCal
                    ? matchListCal.map((match, index) => {
                          return (
                              <div key={index} ref={match.matchweekNumber === currentRound && inputRef ? inputRef : null}>
                                  <MatchComponent match={match} matchweek={match.matchweekNumber} teamsList={teamsList} imgSize={50}></MatchComponent>
                              </div>
                          );
                      })
                    : null}

                {matchListHistory
                    ? matchListHistory.map((match, index) => {
                          return (
                              <div data- key={index}>
                                  <MatchComponent smallerSize={true} hideName={true} match={match} matchweek={match.matchweekNumber} teamsList={teamsList} imgSize={30}></MatchComponent>
                              </div>
                          );
                      })
                    : null}
            </Container>
        </>
    );
};
