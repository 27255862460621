import React from "react";
import styled from "styled-components";
import { getPlayerDetails } from "../../helpers/ApiResponseLiga2V2";
import { getImgFromIdForTeamLiga2, mediaQuery } from "../../helpers/Helpers";
import fieldImg from "../../img/football_field_new_rotated1.png";
import { Liga2FormationTeamV2, Liga2OccurrenceV2, Liga2ParticipantV2, Liga2PlayerDetailsV2, Liga2TeamV2 } from "../../types/Liga2TypesV2";
import { PlayerComponentResp } from "../player/PlayerComponentResp";

interface Props {
    ligaPlayerList: Liga2FormationTeamV2;
    teamId: string;
    isHome: boolean;
    teamsList: Liga2TeamV2[];
    ligaPlayerSubsList?: Liga2ParticipantV2[];
    occurences?: Liga2OccurrenceV2[];
}

const ComponentContainer = styled.div<{ $isHome: boolean }>`
    ${(props) => (props.$isHome ? "margin: 15px auto 15px 5px;" : "margin: 15px 5px 15px auto;")}

    ${mediaQuery.lg} {
        width: 690px;
    }

    ${mediaQuery.md} {
        width: 440px;
    }

    ${mediaQuery.sm} {
        ${(props) => (props.$isHome ? null : "justify-content: right;")}
        width: 337px;
    }
`;

const FieldContainer = styled.div<{ $isHome: boolean }>`
    display: flex;
    background-image: url(${fieldImg});
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    ${(props) => (props.$isHome ? null : "justify-content: right;")}

    ${mediaQuery.lg} {
        width: 690px;
        height: 460px;
    }

    ${mediaQuery.md} {
        width: 440px;
        height: 293px;
    }

    ${mediaQuery.sm} {
        width: 337px;
        height: 225px;
    }
`;

const PositionWrapper = styled.div<{}>`
    height: fit-content;
    margin: auto 0;
    width: 20%;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const TeamLogo = styled.div<{ $teamLogo: string; $isHome: boolean }>`
    ${(props) =>
        props.$teamLogo
            ? `background-image: url(${props.$teamLogo}); 
    background-size: 100%; 
    background-position: top; 
    position: absolute;
    top: 5px;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;`
            : null}

    ${(props) => (props.$isHome ? "left: 0px;" : " right: 0px;")}


    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const SubsContainer = styled.div<{ $isHome: boolean }>`
    width: 100%;
    display: flex;

    ${(props) => (props.$isHome ? null : "justify-content: end;")}
`;

const SubsWrapper = styled.div<{}>`
    width: 11%;
`;

function getLigaGK(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2 | undefined {
    return getPlayerDetails(playerlist.participants[0].playerId!, teamId, playerlist);
}

function getLigaDF(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2[] {
    const numOfDefenders = +playerlist.formationDescription[0];
    const output: Liga2PlayerDetailsV2[] = [];

    for (let index = numOfDefenders; index > 0; index--) {
        output.push(getPlayerDetails(playerlist.participants[index].playerId!, teamId, playerlist));
    }

    return output;
}

function getLigaMF(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2[] {
    const numOfDefenders = +playerlist.formationDescription[0] + 1;
    const numOfMidfelders = +playerlist.formationDescription[1];
    const output: Liga2PlayerDetailsV2[] = [];

    for (let index = 0; index < numOfMidfelders; index++) {
        output.push(getPlayerDetails(playerlist.participants[numOfDefenders + index].playerId!, teamId, playerlist));
    }

    return output;
}

function getLigaMF1(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2[] {
    const numOfDefenders = +playerlist.formationDescription[0] + +playerlist.formationDescription[1] + 1;
    const numOfMidfelders = +playerlist.formationDescription[2];
    const output: Liga2PlayerDetailsV2[] = [];

    for (let index = 0; index < numOfMidfelders; index++) {
        output.push(getPlayerDetails(playerlist.participants[numOfDefenders + index].playerId!, teamId, playerlist));
    }

    return output;
}

function getLigaFW(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2[] {
    const numOfAtt = +playerlist.formationDescription[playerlist.formationDescription.length - 1];
    const output: Liga2PlayerDetailsV2[] = [];

    for (let index = 0; index < numOfAtt; index++) {
        output.push(getPlayerDetails(playerlist.participants[10 - index].playerId!, teamId, playerlist));
    }

    return output;
}

function getLigaSubs(_player: Liga2ParticipantV2, _teamId: string): Liga2PlayerDetailsV2 {
    const _output: Liga2PlayerDetailsV2 = {
        id: _player.id,
        number: _player.number,
        name: _player.name,
        fullName: _player.fullName,
        position: _player.position,
        nationality: _player.nationality,
        mediaUrl: _player.mediaUrl,
        mediaBodyUrl: _player.mediaBodyUrl,
        teamId: Number.parseInt(_teamId),
    };
    return _output;
}

export const FieldTeamComponentResp: React.FC<Props> = ({ ligaPlayerList, teamId, isHome, teamsList, ligaPlayerSubsList, occurences }) => {
    return (
        <>
            <ComponentContainer $isHome={isHome}>
                {isHome ? (
                    <>
                        <SubsContainer $isHome={isHome}>
                            {ligaPlayerSubsList
                                ? ligaPlayerSubsList.map((subPlayer, index) => {
                                      return (
                                          <SubsWrapper key={index}>
                                              <PlayerComponentResp occurences={occurences} isSub={true} playermatch={getLigaSubs(subPlayer, teamId)} imgSize={40}></PlayerComponentResp>
                                          </SubsWrapper>
                                      );
                                  })
                                : null}
                        </SubsContainer>

                        <FieldContainer $isHome={isHome}>
                            <TeamLogo $teamLogo={getImgFromIdForTeamLiga2(teamsList, teamId, 50)} $isHome={isHome}></TeamLogo>
                            <PositionWrapper key="GK">
                                <PlayerComponentResp occurences={occurences} playermatch={getLigaGK(ligaPlayerList, teamId)}></PlayerComponentResp>
                            </PositionWrapper>
                            <PositionWrapper key="DF">
                                {getLigaDF(ligaPlayerList, teamId)
                                    ? getLigaDF(ligaPlayerList, teamId).map((_df, index) => {
                                          return <PlayerComponentResp occurences={occurences} key={index} playermatch={_df}></PlayerComponentResp>;
                                      })
                                    : null}
                            </PositionWrapper>
                            <PositionWrapper key="MF">
                                {getLigaMF(ligaPlayerList, teamId)
                                    ? getLigaMF(ligaPlayerList, teamId).map((_mf, index) => {
                                          return <PlayerComponentResp occurences={occurences} key={index} playermatch={_mf}></PlayerComponentResp>;
                                      })
                                    : null}
                            </PositionWrapper>
                            {ligaPlayerList.formationDescription.length === 4 ? (
                                <PositionWrapper key="MF1">
                                    {getLigaMF1(ligaPlayerList, teamId)
                                        ? getLigaMF1(ligaPlayerList, teamId).map((_mf, index) => {
                                              return <PlayerComponentResp occurences={occurences} key={index} playermatch={_mf}></PlayerComponentResp>;
                                          })
                                        : null}
                                </PositionWrapper>
                            ) : null}
                            <PositionWrapper key="FW">
                                {getLigaFW(ligaPlayerList, teamId)
                                    ? getLigaFW(ligaPlayerList, teamId).map((_fw, index) => {
                                          return <PlayerComponentResp occurences={occurences} key={index} playermatch={_fw}></PlayerComponentResp>;
                                      })
                                    : null}
                            </PositionWrapper>
                        </FieldContainer>
                    </>
                ) : (
                    <>
                        <FieldContainer $isHome={isHome}>
                            <TeamLogo $teamLogo={getImgFromIdForTeamLiga2(teamsList, teamId, 50)} $isHome={isHome}></TeamLogo>
                            <PositionWrapper key="FW">
                                {getLigaFW(ligaPlayerList, teamId)
                                    ? getLigaFW(ligaPlayerList, teamId).map((_fw, index) => {
                                          return <PlayerComponentResp occurences={occurences} key={index} playermatch={_fw}></PlayerComponentResp>;
                                      })
                                    : null}
                            </PositionWrapper>
                            {ligaPlayerList.formationDescription.length === 4 ? (
                                <PositionWrapper key="MF1">
                                    {getLigaMF1(ligaPlayerList, teamId)
                                        ? getLigaMF1(ligaPlayerList, teamId).map((_mf, index) => {
                                              return <PlayerComponentResp occurences={occurences} key={index} playermatch={_mf}></PlayerComponentResp>;
                                          })
                                        : null}
                                </PositionWrapper>
                            ) : null}
                            <PositionWrapper key="MF">
                                {getLigaMF(ligaPlayerList, teamId)
                                    ? getLigaMF(ligaPlayerList, teamId).map((_mf, index) => {
                                          return <PlayerComponentResp occurences={occurences} key={index} playermatch={_mf}></PlayerComponentResp>;
                                      })
                                    : null}
                            </PositionWrapper>
                            <PositionWrapper key="DF">
                                {getLigaDF(ligaPlayerList, teamId)
                                    ? getLigaDF(ligaPlayerList, teamId).map((_df, index) => {
                                          return <PlayerComponentResp occurences={occurences} key={index} playermatch={_df}></PlayerComponentResp>;
                                      })
                                    : null}
                            </PositionWrapper>
                            <PositionWrapper key="GK">
                                <PlayerComponentResp occurences={occurences} playermatch={getLigaGK(ligaPlayerList, teamId)}></PlayerComponentResp>
                            </PositionWrapper>
                        </FieldContainer>
                        <SubsContainer $isHome={isHome}>
                            {ligaPlayerSubsList
                                ? ligaPlayerSubsList.map((subPlayer, index) => {
                                      return (
                                          <SubsWrapper key={index}>
                                              <PlayerComponentResp occurences={occurences} isSub={true} playermatch={getLigaSubs(subPlayer, teamId)} imgSize={40}></PlayerComponentResp>
                                          </SubsWrapper>
                                      );
                                  })
                                : null}
                        </SubsContainer>
                    </>
                )}
            </ComponentContainer>
        </>
    );
};
