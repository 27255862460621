import { useEffect, useRef, useState } from "react";
import "reactjs-popup/dist/index.css";
import { ExportCalendarComponent } from "../components/app/ExportCalendarComponent";
import { LoadingComponent } from "../components/app/LoadingComponent";
import { ShortcutButton } from "../components/app/ShortcutButton";
import { MatchListComponent } from "../components/league/MatchListComponent";
import { SquadComponent } from "../components/team/SquadComponent";
import { TeamDetailsComponent } from "../components/team/TeamDetailsComponent";
import "../styles/teamdetails.scss";
import "../styles/variables.scss";
import { TeamDetailsTab } from "../types/CustomTypes";

import {
    GetLiga2V2CurrentRound,
    ManageLiga2V2TeamMatchesSessionData,
    ManageLiga2V2TeamSquadSessionData,
    ManageLiga2V2TeamStatisticsSessionData,
    ManageLiga2V2TeamsSessionData,
} from "../helpers/ApiResponseLiga2V2";
import { Liga2MatchInfoV2, Liga2TeamSquadV2, Liga2TeamStatisticsV2, Liga2TeamV2 } from "../types/Liga2TypesV2";

const TeamDetailsPage = () => {
    const [teamDetailsTab, teamDetailsTabSet] = useState<TeamDetailsTab>(1);
    const [teamId, teamIdSet] = useState<string | undefined>(undefined);

    const [liga2v2currentround, liga2v2currentroundSet] = useState<number>(0);

    const [liga2V2TeamList, liga2V2TeamListSet] = useState<Liga2TeamV2[] | undefined>(undefined);
    const [liga2V2Team, liga2V2TeamSet] = useState<Liga2TeamV2 | undefined>(undefined);
    const [liga2V2TeamSquad, liga2V2TeamSquadSet] = useState<Liga2TeamSquadV2 | undefined>(undefined);
    const [liga2V2TeamStats, liga2V2TeamStatsSet] = useState<Liga2TeamStatisticsV2[] | undefined>(undefined);
    const [liga2V2TeamMatches, liga2V2TeamMatchesSet] = useState<Liga2MatchInfoV2[] | undefined>(undefined);

    const inputRef = useRef(null);
    const [matchListIsUp, matchListIsUpSet] = useState<boolean>(false);

    useEffect(() => {
        const getData = async () => {
            var url = new URL(window.location.href);
            const _teamId = url.searchParams.get("id");
            if (_teamId) teamIdSet(_teamId);

            const _liga2V2TeamsList = await ManageLiga2V2TeamsSessionData();
            liga2V2TeamListSet(_liga2V2TeamsList);

            const _teamSelected = _liga2V2TeamsList.find((t) => t.id === _teamId);
            liga2V2TeamSet(_teamSelected);

            const _liga2V2TeamSquad = await ManageLiga2V2TeamSquadSessionData(_teamId);
            liga2V2TeamSquadSet(_liga2V2TeamSquad);

            const _liga2V2TeamStats = await ManageLiga2V2TeamStatisticsSessionData(_teamId);
            liga2V2TeamStatsSet(_liga2V2TeamStats);

            const _liga2V2TeamMatches = await ManageLiga2V2TeamMatchesSessionData(_teamId);
            liga2V2TeamMatchesSet(_liga2V2TeamMatches);

            const _currentRound = await GetLiga2V2CurrentRound();
            liga2v2currentroundSet(_currentRound);
        };

        getData();
    }, []);

    useEffect(() => {
        if (matchListIsUp) {
            inputRef.current.scrollIntoView({ behavior: "smooth" });
        }
        matchListIsUpSet(false);
        return () => {};
    }, [matchListIsUp]);

    function squadClick(): void {
        teamDetailsTabSet(TeamDetailsTab.squad);
    }

    function matchesClick(): void {
        teamDetailsTabSet(TeamDetailsTab.matches);
    }

    function doShit() {
        matchListIsUpSet(true);
    }

    return (
        <>
            {liga2V2TeamSquad && liga2V2TeamStats && liga2V2TeamMatches && liga2V2Team && liga2V2TeamSquad && liga2V2TeamList ? (
                <div className="teamDetailsPage">
                    <div className="teamDetailsPage--content">
                        <TeamDetailsComponent team={liga2V2Team}></TeamDetailsComponent>
                        <div className="teamDetailsPage--content--shortcuts">
                            <ShortcutButton disabled={teamDetailsTab === TeamDetailsTab.squad} text="Squad" {...(teamId && { onClickAction: () => squadClick() })}></ShortcutButton>
                            <ShortcutButton disabled={teamDetailsTab === TeamDetailsTab.matches} text="List" {...(teamId && { onClickAction: () => matchesClick() })}></ShortcutButton>
                        </div>

                        <div className="teamDetailsPage--content--data">
                            {teamDetailsTab === TeamDetailsTab.squad ? <SquadComponent squad={liga2V2TeamSquad}></SquadComponent> : null}

                            {teamDetailsTab === TeamDetailsTab.matches ? (
                                <>
                                    <ExportCalendarComponent teamListV2={liga2V2TeamList} team={liga2V2Team} matchList={liga2V2TeamMatches}></ExportCalendarComponent>
                                    <div className="teamDetailsPage--content--data__match">
                                        <MatchListComponent
                                            onRendComp={doShit}
                                            currentRound={liga2v2currentround}
                                            inputRef={inputRef}
                                            teamsList={liga2V2TeamList}
                                            matchListCal={liga2V2TeamMatches}></MatchListComponent>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : (
                <LoadingComponent></LoadingComponent>
            )}
        </>
    );
};

export default TeamDetailsPage;
