import moment from "moment";
import "moment/locale/pt";
import { CssVariables, DropdownItems, Liga2FormatsV2, Liga2ImgSize, MediaSize, PerformanceResult } from "../types/CustomTypes";
import { Geometry } from "../types/GoogleTypes";
import { Liga2Img, Liga2Team, Liga2TeamsImagesResponse, Liga2TeamsImagesV2Response, Liga2TeamsResponse } from "../types/Liga2Types";
import { Liga2OccurrenceV2, Liga2TeamV2 } from "../types/Liga2TypesV2";
import { ManageLiga2V2TeamsSessionData } from "./ApiResponseLiga2V2";
import { LIGA2OFFICIAL_COMPETITIONID, LIGA2OFFICIAL_SEASONID, dateFormatMatchComponent } from "./Constants";

const customMediaQuery = (maxWidth: number) => `@media (max-width: ${maxWidth - 1}px)`;

export const mediaQuery = {
    lg: customMediaQuery(MediaSize.lg),
    md: customMediaQuery(MediaSize.md),
    sm: customMediaQuery(MediaSize.xs),
};

export function stringFormat(template: string, ...args: any[]) {
    return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
    });
}

export function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getPerformanceColor(result: PerformanceResult) {
    switch (result) {
        case PerformanceResult.win:
            return CssVariables.performance_green;
        case PerformanceResult.draw:
            return CssVariables.performance_grey;
        case PerformanceResult.loss:
            return CssVariables.performance_red;
    }
}

export function getImgForTeam(_imgList: Liga2TeamsImagesResponse, _id: string, is35?: boolean): Liga2Img {
    const teamImg = _imgList.find((i) => i.id === _id);
    const returnImg = is35 ? teamImg?.img35 : teamImg?.img50;
    return returnImg ? returnImg : { pos: "", size: "", url: "" };
}

export function getImgForTeamV2(_imgList: Liga2TeamsImagesV2Response, _id: string, size: Liga2ImgSize): Liga2Img | undefined {
    const teamImg = _imgList.filter((i) => i.id === _id);

    switch (size) {
        case Liga2ImgSize.img35:
            return teamImg.find((i) => i.img35 !== undefined)?.img35;
        case Liga2ImgSize.img50:
            return teamImg.find((i) => i.img50 !== undefined)?.img50;
        case Liga2ImgSize.img120:
            return teamImg.find((i) => i.img120 !== undefined)?.img120;
        default:
            return teamImg.find((i) => i.img200 !== undefined)?.img200;
    }
}

export function getDateTimeFromDateAndTime(_inputDate: string, _inputTime: string): Date {
    return new Date(new Date(_inputDate).toDateString() + " " + _inputTime);
}

export function getDateInFormat(_originalDate?: Date, _dateFormat?: string) {
    moment.locale("pt");
    return _dateFormat ? moment(_originalDate).format(_dateFormat) : moment(_originalDate).format(dateFormatMatchComponent);
}

export function getSofascoreRatingColor(_rating: number) {
    if (_rating < 6) return CssVariables.sofascore_rating_red;
    if (_rating < 6.5) return CssVariables.sofascore_rating_orange;
    if (_rating < 7) return CssVariables.sofascore_rating_yellow;
    if (_rating < 8) return CssVariables.sofascore_rating_yellowgreen;
    if (_rating < 9) return CssVariables.sofascore_rating_green;
    return CssVariables.sofascore_rating_blue;
}

export function getDdlItems(_value: number[], _label: string[], _noSorting?: boolean) {
    var _itemListDDL: DropdownItems[] = [];

    for (let index = 0; index < _value.length; index++) {
        var _itemDDL: DropdownItems = {
            label: _label[index],
            value: _value[index],
        };
        _itemListDDL.push(_itemDDL);
    }

    return _noSorting ? _itemListDDL : _itemListDDL.sort((a, b) => a.label.localeCompare(b.label));
}

export function getTeamFromId(_id: string, _teams: Liga2TeamsResponse): Liga2Team | undefined {
    return _teams.teams.find((i) => i.id === _id) ?? undefined;
}

export function formatDateTimeICS(date: Date) {
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1);
    const day = pad(date.getUTCDate());
    const hour = pad(date.getUTCHours());
    const minute = pad(date.getUTCMinutes());
    const second = pad(date.getUTCSeconds());
    return `${year}${month}${day}T${hour}${minute}${second}Z`;
}

function pad(i: number) {
    return i < 10 ? `0${i}` : `${i}`;
}

export function downloadBlob(fileName: string, blob: Blob): void {
    const anchor = window.document.createElement("a");
    anchor.href = window.URL.createObjectURL(blob);
    anchor.setAttribute("download", `${fileName}`);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
}

export function getLocationFromGeometry(_geo: Geometry): string {
    return _geo.location.lat + "\\, " + _geo.location.lng;
}

export function getUrlApiForLigaV2(urlApi: string, seasonId?: string, competitionId?: string) {
    var url = new URL(urlApi);
    url.searchParams.set("season_id", LIGA2OFFICIAL_SEASONID);
    url.searchParams.set("competition_id", LIGA2OFFICIAL_COMPETITIONID);
    return url.href;
}

export function getUrlApiForLigaV2Parameter(urlApi: string, hasCompetitionId: boolean, paramlabem: string, paramvalue: string) {
    var url = new URL(urlApi);
    url.searchParams.set("season_id", LIGA2OFFICIAL_SEASONID);
    url.searchParams.set("competition_id", LIGA2OFFICIAL_COMPETITIONID);
    url.searchParams.set(paramlabem, paramvalue);
    return url.href;
}

//V2:
export function getImageFormatLiga2V2(imgUrl: string, size: Liga2FormatsV2): string {
    if (imgUrl) {
        const index = imgUrl.lastIndexOf("/") + 1;
        const output = [imgUrl.slice(0, index), size, imgUrl.slice(index)].join("");
        return output;
    } else return "";
}

export function getImgFromIdForTeamLiga2(_teamList: Liga2TeamV2[], _id: string, size: number): string | undefined {
    const teamData = _teamList.find((i) => i.id === _id);
    const sizeFormat = mediaFormatTeamLogoFromSize(size);

    if (teamData) {
        return getImageFormatLiga2V2(teamData.imgUrl, sizeFormat);
    } else return undefined;
}

export function getTeamFromIdLiga2(_teamList: Liga2TeamV2[], _id: string): Liga2TeamV2 {
    return _teamList.find((i) => i.id === _id);
}

export function mediaFormatTeamLogoFromSize(_size: number): Liga2FormatsV2 {
    switch (true) {
        case _size < 157:
            return Liga2FormatsV2.thumbnail;
        case _size < 501:
            return Liga2FormatsV2.small;
        case _size < 751:
            return Liga2FormatsV2.medium;
        case _size < 1001:
            return Liga2FormatsV2.large;
        default:
            return Liga2FormatsV2.default;
    }
}

export function mediaFormatPlayerLogoFromSize(_size: number): Liga2FormatsV2 {
    switch (true) {
        case _size < 45:
            return Liga2FormatsV2.xsmall;
        case _size < 157:
            return Liga2FormatsV2.thumbnail;
        case _size < 201:
            return Liga2FormatsV2.small;
        default:
            return Liga2FormatsV2.default;
    }
}

export function getImgForPlayerLiga2(_playerImgUrl: string, size: number): string | undefined {
    const sizeFormat = mediaFormatPlayerLogoFromSize(size);
    return getImageFormatLiga2V2(_playerImgUrl, sizeFormat);
}

export function getImgForTeamLiga2(_teamImgUrl: string, size: number): string | undefined {
    const sizeFormat = mediaFormatTeamLogoFromSize(size);
    return getImageFormatLiga2V2(_teamImgUrl, sizeFormat);
}

export async function getTeamFromName(_teamName: string) {
    const _liga2V2TeamsList = await ManageLiga2V2TeamsSessionData();
    return _liga2V2TeamsList.find((t) => t.name === _teamName);
}

export function getTeamFormFromString(_inputForm: string): PerformanceResult[] {
    const _output: PerformanceResult[] = [];

    _inputForm.split("").forEach((element) => {
        switch (element) {
            case "V":
                _output.push(PerformanceResult.win);
                break;
            case "E":
                _output.push(PerformanceResult.draw);
                break;
            case "D":
                _output.push(PerformanceResult.loss);
                break;
        }
    });

    return _output;
}

export function isPlayerSub(_ligaPlayerId: number, _matchOccurences: Liga2OccurrenceV2[]): boolean {
    return _matchOccurences.find((i) => i.id === _ligaPlayerId) ? true : false;
}
