import React from "react";
import styled from "styled-components";
import { getImgFromIdForTeamLiga2, mediaQuery } from "../../helpers/Helpers";
import { Liga2StandingsV2, Liga2TeamV2 } from "../../types/Liga2TypesV2";

interface Props {
    sortedcolumn: number;
    standings: Liga2StandingsV2[];
    teamList: Liga2TeamV2[];
    matchWeek?: number;
}

const Container = styled.div<{}>`
    width: fit-content;
    padding: 5px;
    font-family: monospace;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const Table = styled.table<{}>`
    border-spacing: 0px;
    border-collapse: collapse;
    margin: 10px;
    ${mediaQuery.sm} {
        margin: 0px;
    }
`;

const Thead = styled.thead<{}>``;

const Th = styled.th<{ $sortby?: number }>`
    padding: 10px;
    text-align: center;
    font-size: 18px;
    ${mediaQuery.md} {
        font-size: 15px;
    }

    ${mediaQuery.sm} {
        font-size: 13px;
    }
`;

const Tbody = styled.tbody<{}>``;

const Tr = styled.tr<{}>`
    cursor: pointer;
`;

const Td = styled.td<{ $sortby?: number }>`
    border: 1px solid #0c785e;
    padding: 5px;
    text-align: center;

    font-size: 18px;
    ${mediaQuery.md} {
        font-size: 15px;
    }

    ${mediaQuery.sm} {
        font-size: 13px;
    }
`;

const TeamImg = styled.div<{ $img: string | undefined }>`
    background-image: url(${(props) => (props.$img ? props.$img : null)});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 55px;
    width: 55px;
    margin-right: 5px;
`;

const TeamName = styled.span<{}>`
    font-size: 18px;

    ${mediaQuery.md} {
        font-size: 16px;
    }

    ${mediaQuery.sm} {
        font-size: 13px;
    }
`;

const TeamWithImgHorizontal = styled.div<{}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
`;

function teamClick(_teamId: string): void {
    var url = new URL("/teamdetails", window.location.href);
    url.searchParams.set("id", _teamId);
    window.location.href = url.href;
}

export const StandingsComponent: React.FC<Props> = ({ sortedcolumn, standings, teamList, matchWeek }) => {
    return (
        <>
            <Container>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Team</Th>
                            <Th>P</Th>
                            {matchWeek ? <Th>W</Th> : null}
                        </Tr>
                    </Thead>

                    <Tbody>
                        {standings
                            ? standings?.map((row, index) => {
                                  return (
                                      <Tr key={row.id} onClick={() => teamClick(row.id)}>
                                          <Td $sortby={sortedcolumn}>{row.place}</Td>
                                          <Td $sortby={sortedcolumn}>
                                              <TeamWithImgHorizontal>
                                                  <TeamImg $img={getImgFromIdForTeamLiga2(teamList, row.id, 50)}></TeamImg>
                                                  <TeamName>{row.name}</TeamName>
                                              </TeamWithImgHorizontal>
                                          </Td>
                                          <Td $sortby={sortedcolumn}>{row.points}</Td>
                                          {matchWeek ? <Td $sortby={sortedcolumn}>{matchWeek}</Td> : null}
                                      </Tr>
                                  );
                              })
                            : null}
                    </Tbody>
                </Table>
            </Container>
        </>
    );
};
