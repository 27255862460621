import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getDateInFormat } from "../../helpers/Helpers";
import { MediaSize } from "../../types/CustomTypes";
import { Liga2MatchInfoV2, Liga2TeamV2 } from "../../types/Liga2TypesV2";
import { TeamComponentInline } from "../team/TeamComponentInline";

interface Props {
    match: Liga2MatchInfoV2;
    teamsList: Liga2TeamV2[];
}

const Container = styled.div<{}>`
    width: 450px;
    background: #ffffff;
    padding: 2px;
    display: inline-block;
`;

const TeamWrapper = styled.div<{}>`
    background-color: #fff;
    margin: auto;
    width: 40%;
    display: flex;
`;
const ScoreWrapper = styled.div<{}>`
    background-color: #fff;
    margin: auto;
    width: 80px;
`;

const TVWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
`;

const DateWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    text-transform: uppercase;
    > span {
        border-bottom: 1px dashed #0c785e;
    }
`;

const TeamsWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
`;

const Border = styled.div<{}>`
    background-color: #fff;
    border: 1px solid #0c785e;
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: center;
`;

const TeamDescText = styled.span<{}>`
    width: 50%;
`;

const TeamDescText100 = styled.span<{}>`
    width: fit-content;
    padding: 0px 5px;
    margin: auto;
`;

export const MatchComponentTable: React.FC<Props> = ({ match, teamsList }) => {
    const [isMobile, isMobileSet] = useState<boolean>(window.innerWidth < MediaSize.xl);
    const [isMobileM, isMobileMSet] = useState<boolean>(window.innerWidth < MediaSize.md);
    const [isMobileS, isMobileSSet] = useState<boolean>(window.innerWidth < MediaSize.sm);
    const [isMobileXS, isMobileXSSet] = useState<boolean>(window.innerWidth < MediaSize.xs);

    useEffect(() => {
        const handleResize = () => {
            isMobileSet(window.innerWidth < MediaSize.lg);
            isMobileMSet(window.innerWidth < MediaSize.md);
            isMobileSSet(window.innerWidth < MediaSize.sm);
            isMobileXSSet(window.innerWidth < MediaSize.xs);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <Container>
                <Border>
                    {match.matchDate ? (
                        <DateWrapper>
                            <TeamDescText100>{getDateInFormat(match.matchDate)}</TeamDescText100>
                        </DateWrapper>
                    ) : null}
                    {isMobileM ? (
                        <>
                            {isMobileS ? (
                                <>
                                    {isMobileXS ? (
                                        <TeamsWrapper>
                                            <TeamWrapper>
                                                <TeamComponentInline imgSize={40} team={match.homeTeamId.toString()} hastext="true" hasclick="false" teamsList={teamsList} imageLeft={true}></TeamComponentInline>
                                            </TeamWrapper>
                                            <ScoreWrapper>
                                                <TeamDescText>{<TeamDescText>{match.homeTeamGoals + " - " + match.awayTeamGoals}</TeamDescText>}</TeamDescText>
                                            </ScoreWrapper>
                                            <TeamWrapper>
                                                <TeamComponentInline
                                                    imgSize={40}
                                                    team={match.awayTeamId.toString()}
                                                    hastext="true"
                                                    hasclick="false"
                                                    teamsList={teamsList}
                                                    imageLeft={false}></TeamComponentInline>
                                            </TeamWrapper>
                                        </TeamsWrapper>
                                    ) : (
                                        <TeamsWrapper>
                                            <TeamWrapper>
                                                <TeamComponentInline imgSize={50} team={match.homeTeamId.toString()} hastext="true" hasclick="false" teamsList={teamsList} imageLeft={true}></TeamComponentInline>
                                            </TeamWrapper>
                                            <ScoreWrapper>
                                                <TeamDescText>{<TeamDescText>{match.homeTeamGoals + " - " + match.awayTeamGoals}</TeamDescText>}</TeamDescText>
                                            </ScoreWrapper>
                                            <TeamWrapper>
                                                <TeamComponentInline
                                                    imgSize={50}
                                                    team={match.awayTeamId.toString()}
                                                    hastext="true"
                                                    hasclick="false"
                                                    teamsList={teamsList}
                                                    imageLeft={false}></TeamComponentInline>
                                            </TeamWrapper>
                                        </TeamsWrapper>
                                    )}
                                </>
                            ) : (
                                <TeamsWrapper>
                                    <TeamWrapper>
                                        <TeamComponentInline imgSize={75} team={match.homeTeamId.toString()} hastext="true" hasclick="false" teamsList={teamsList} imageLeft={true}></TeamComponentInline>
                                    </TeamWrapper>
                                    <ScoreWrapper>
                                        <TeamDescText>{<TeamDescText>{match.homeTeamGoals + " - " + match.awayTeamGoals}</TeamDescText>}</TeamDescText>
                                    </ScoreWrapper>
                                    <TeamWrapper>
                                        <TeamComponentInline imgSize={75} team={match.awayTeamId.toString()} hastext="true" hasclick="false" teamsList={teamsList} imageLeft={false}></TeamComponentInline>
                                    </TeamWrapper>
                                </TeamsWrapper>
                            )}
                        </>
                    ) : (
                        <>
                            {isMobile ? (
                                <TeamsWrapper>
                                    <TeamWrapper>
                                        <TeamComponentInline imgSize={75} team={match.homeTeamId.toString()} hastext="true" hasclick="false" teamsList={teamsList} imageLeft={true}></TeamComponentInline>
                                    </TeamWrapper>
                                    <ScoreWrapper>
                                        <TeamDescText>{<TeamDescText>{match.homeTeamGoals + " - " + match.awayTeamGoals}</TeamDescText>}</TeamDescText>
                                    </ScoreWrapper>
                                    <TeamWrapper>
                                        <TeamComponentInline imgSize={75} team={match.awayTeamId.toString()} hastext="true" hasclick="false" teamsList={teamsList} imageLeft={false}></TeamComponentInline>
                                    </TeamWrapper>
                                </TeamsWrapper>
                            ) : (
                                <TeamsWrapper>
                                    <TeamWrapper>
                                        <TeamComponentInline imgSize={90} team={match.homeTeamId.toString()} hastext="true" hasclick="false" teamsList={teamsList} imageLeft={true}></TeamComponentInline>
                                    </TeamWrapper>
                                    <ScoreWrapper>
                                        <TeamDescText>{<TeamDescText>{match.homeTeamGoals + " - " + match.awayTeamGoals}</TeamDescText>}</TeamDescText>
                                    </ScoreWrapper>
                                    <TeamWrapper>
                                        <TeamComponentInline imgSize={90} team={match.awayTeamId.toString()} hastext="true" hasclick="false" teamsList={teamsList} imageLeft={false}></TeamComponentInline>
                                    </TeamWrapper>
                                </TeamsWrapper>
                            )}
                        </>
                    )}
                </Border>
            </Container>
        </>
    );
};
