import { useEffect, useState } from "react";
import { LoadingComponent } from "../components/app/LoadingComponent";
import { FieldTeamComponent } from "../components/league/FieldTeamComponent";
import { FieldTeamComponentResp } from "../components/league/FieldTeamComponentResp";
import { MatchListComponent } from "../components/league/MatchListComponent";
import { StadiumComponent } from "../components/league/StadiumComponent";
import { TeamMatchScoreComponent } from "../components/league/TeamMatchScoreComponent";
import { TeamMatchStatsComponent } from "../components/league/TeamMatchStatsComponent";
import { PlayerComponent } from "../components/player/PlayerComponent";
import {
    GetLiga2V2StandingsByRound,
    ManageLiga2V2MatchDetailsData,
    ManageLiga2V2MatchFormationsData,
    ManageLiga2V2MatchH2HData,
    ManageLiga2V2TeamSquadSessionData,
    ManageLiga2V2TeamsSessionData,
} from "../helpers/ApiResponseLiga2V2";
import "../styles/matchdetails.scss";
import { MediaSize } from "../types/CustomTypes";
import { Liga2MatchDetailsV2, Liga2MatchFormationsV2, Liga2MatchInfoV2, Liga2PlayerDetailsV2, Liga2TeamSquadV2, Liga2TeamV2 } from "../types/Liga2TypesV2";

const MatchDetailsPage = () => {
    const [liga2V2TeamList, liga2V2TeamListSet] = useState<Liga2TeamV2[] | undefined>(undefined);
    const [liga2V2MatchDetails, liga2V2MatchDetailsSet] = useState<Liga2MatchDetailsV2 | undefined>(undefined);
    const [liga2V2MatchFormations, liga2V2MatchFormationsSet] = useState<Liga2MatchFormationsV2 | undefined>(undefined);
    const [liga2V2MatchH2HData, liga2V2MatchH2HDataSet] = useState<Liga2MatchInfoV2[] | undefined>(undefined);

    const [hometeamPlaceBeforeMatch, hometeamPlaceBeforeMatchSet] = useState<number | undefined>(undefined);
    const [awayteamPlaceBeforeMatch, awayteamPlaceBeforeMatchSet] = useState<number | undefined>(undefined);
    const [hometeamPlaceAfterMatch, hometeamPlaceAfterMatchSet] = useState<number | undefined>(undefined);
    const [awayteamPlaceAfterMatch, awayteamPlaceAfterMatchSet] = useState<number | undefined>(undefined);

    const [liga2V2TeamSquadH, liga2V2TeamSquadHSet] = useState<Liga2TeamSquadV2 | undefined>(undefined);
    const [liga2V2TeamSquadA, liga2V2TeamSquadASet] = useState<Liga2TeamSquadV2 | undefined>(undefined);

    const [isMobile, isMobileWSet] = useState<boolean>(window.innerWidth < MediaSize.lg);

    useEffect(() => {
        const getData = async () => {
            var url = new URL(window.location.href);
            const _matchId = Number.parseInt(url.searchParams.get("matchid"));
            const _matchweek = Number.parseInt(url.searchParams.get("week"));

            const _liga2v2teams = await ManageLiga2V2TeamsSessionData();
            liga2V2TeamListSet(_liga2v2teams);

            const _liga2v2matchdetails = await ManageLiga2V2MatchDetailsData(_matchweek, _matchId);
            liga2V2MatchDetailsSet(_liga2v2matchdetails);
            console.log(_liga2v2matchdetails);

            const _liga2v2matchformations = await ManageLiga2V2MatchFormationsData(_matchweek, _matchId);
            liga2V2MatchFormationsSet(_liga2v2matchformations);

            const _liga2V2TeamSquadH = await ManageLiga2V2TeamSquadSessionData(_liga2v2matchdetails.homeTeamId.toString());
            liga2V2TeamSquadHSet(_liga2V2TeamSquadH);
            const _liga2V2TeamSquadA = await ManageLiga2V2TeamSquadSessionData(_liga2v2matchdetails.awayTeamId.toString());
            liga2V2TeamSquadASet(_liga2V2TeamSquadA);

            const _liga2V2MatchH2HData = await ManageLiga2V2MatchH2HData(_liga2v2matchdetails.homeTeamId.toString(), _liga2v2matchdetails.awayTeamId.toString());
            liga2V2MatchH2HDataSet(_liga2V2MatchH2HData);

            if (_matchweek === 1) {
                const _standingsAfterMatch = await GetLiga2V2StandingsByRound(_matchweek);
                const _hometeamPlaceAfterMatch = _standingsAfterMatch.find((t) => t.id === _liga2v2matchdetails.homeTeamId.toString()).place;
                const _awayteamPlaceAfterMatch = _standingsAfterMatch.find((t) => t.id === _liga2v2matchdetails.awayTeamId.toString()).place;
                hometeamPlaceAfterMatchSet(_hometeamPlaceAfterMatch);
                awayteamPlaceAfterMatchSet(_awayteamPlaceAfterMatch);

                hometeamPlaceBeforeMatchSet(0);
                awayteamPlaceBeforeMatchSet(0);
            } else {
                const _standingsBeforeMatch = await GetLiga2V2StandingsByRound(_matchweek - 1);
                const _standingsAfterMatch = await GetLiga2V2StandingsByRound(_matchweek);

                const _hometeamPlaceBeforeMatch = _standingsBeforeMatch.find((t) => t.id === _liga2v2matchdetails.homeTeamId.toString()).place;
                const _awayteamPlaceBeforeMatch = _standingsBeforeMatch.find((t) => t.id === _liga2v2matchdetails.awayTeamId.toString()).place;
                hometeamPlaceBeforeMatchSet(_hometeamPlaceBeforeMatch);
                awayteamPlaceBeforeMatchSet(_awayteamPlaceBeforeMatch);

                const _hometeamPlaceAfterMatch = _standingsAfterMatch.find((t) => t.id === _liga2v2matchdetails.homeTeamId.toString()).place;
                const _awayteamPlaceAfterMatch = _standingsAfterMatch.find((t) => t.id === _liga2v2matchdetails.awayTeamId.toString()).place;
                hometeamPlaceAfterMatchSet(_hometeamPlaceAfterMatch);
                awayteamPlaceAfterMatchSet(_awayteamPlaceAfterMatch);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            isMobileWSet(window.innerWidth < MediaSize.lg);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        return () => {};
    }, []);

    function getMVP(): Liga2PlayerDetailsV2 {
        const _playerID = liga2V2MatchDetails.manOfTheMatchId;

        let _playerDet = liga2V2MatchDetails.manOfTheMatchIsHomeTeam
            ? liga2V2MatchFormations.homeTeam.participants.find((i) => i.id === _playerID)
            : liga2V2MatchFormations.awayTeam.participants.find((i) => i.id === _playerID);

        if (!_playerDet) {
            _playerDet = liga2V2MatchDetails.manOfTheMatchIsHomeTeam ? liga2V2MatchDetails.homeTeamSubs.find((i) => i.id === _playerID) : liga2V2MatchDetails.awayTeamSubs.find((i) => i.id === _playerID);
        }

        const _teamId = liga2V2MatchDetails.manOfTheMatchIsHomeTeam ? liga2V2MatchDetails.homeTeamId : liga2V2MatchDetails.awayTeamId;

        const _output: Liga2PlayerDetailsV2 = {
            id: _playerDet.id,
            number: _playerDet.number,
            name: _playerDet.name,
            fullName: _playerDet.fullName,
            position: _playerDet.position,
            nationality: _playerDet.nationality,
            mediaUrl: _playerDet.mediaUrl,
            mediaBodyUrl: _playerDet.mediaBodyUrl,
            teamId: _teamId,
        };
        return _output;
    }

    return (
        <>
            <div className="matchDetailsPage">
                {liga2V2TeamList && liga2V2MatchDetails && liga2V2MatchFormations && liga2V2TeamSquadH && liga2V2TeamSquadA && liga2V2MatchH2HData ? (
                    <>
                        <div className="matchDetailsPage__formations">
                            {isMobile ? (
                                <>
                                    <div className="matchDetailsPage__formations--teams">
                                        <div className="teams__stats">
                                            <TeamMatchScoreComponent
                                                awayTeamAfterPlace={awayteamPlaceAfterMatch}
                                                homeTeamAfterPlace={hometeamPlaceAfterMatch}
                                                awayTeamPlace={awayteamPlaceBeforeMatch}
                                                homeTeamPlace={hometeamPlaceBeforeMatch}
                                                matchHistory={liga2V2MatchH2HData}
                                                matchDetails={liga2V2MatchDetails}
                                                teamsList={liga2V2TeamList}
                                                homeTeamId={liga2V2MatchDetails.homeTeamId}
                                                awayTeamId={liga2V2MatchDetails.awayTeamId}></TeamMatchScoreComponent>
                                        </div>
                                    </div>

                                    <div className="matchDetailsPage__formations--stats">
                                        <MatchListComponent teamsList={liga2V2TeamList} matchListHistory={liga2V2MatchH2HData}></MatchListComponent>
                                        <TeamMatchStatsComponent
                                            matchDetails={liga2V2MatchDetails}
                                            teamsList={liga2V2TeamList}
                                            homeTeamId={liga2V2MatchDetails.homeTeamId}
                                            awayTeamId={liga2V2MatchDetails.awayTeamId}></TeamMatchStatsComponent>
                                    </div>

                                    <div className="matchDetailsPage__formations--extra">
                                        <div className="extra__mvpstadium">
                                            <div className="extra__mvpstadium--mvp">
                                                <PlayerComponent playermatch={getMVP()} isMvp={true} imgSize={200}></PlayerComponent>
                                            </div>
                                            <div className="extra__mvpstadium--stadium">
                                                <StadiumComponent details={liga2V2MatchDetails.stadium}></StadiumComponent>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="matchDetailsPage__formations--players">
                                        <div className="players__component home">
                                            <FieldTeamComponentResp
                                                teamsList={liga2V2TeamList}
                                                occurences={liga2V2MatchDetails.occurrences}
                                                ligaPlayerList={liga2V2MatchFormations.homeTeam}
                                                ligaPlayerSubsList={liga2V2MatchDetails.homeTeamSubs}
                                                teamId={liga2V2MatchDetails.homeTeamId.toString()}
                                                isHome={true}></FieldTeamComponentResp>
                                        </div>
                                        <div className="players__component away">
                                            <FieldTeamComponentResp
                                                teamsList={liga2V2TeamList}
                                                occurences={liga2V2MatchDetails.occurrences}
                                                ligaPlayerList={liga2V2MatchFormations.awayTeam}
                                                ligaPlayerSubsList={liga2V2MatchDetails.awayTeamSubs}
                                                teamId={liga2V2MatchDetails.awayTeamId.toString()}
                                                isHome={false}></FieldTeamComponentResp>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="matchDetailsPage__formations--teams">
                                        <div className="teams__stats">
                                            <TeamMatchScoreComponent
                                                awayTeamPlace={awayteamPlaceBeforeMatch}
                                                homeTeamPlace={hometeamPlaceBeforeMatch}
                                                awayTeamAfterPlace={awayteamPlaceAfterMatch}
                                                homeTeamAfterPlace={hometeamPlaceAfterMatch}
                                                matchHistory={liga2V2MatchH2HData}
                                                matchDetails={liga2V2MatchDetails}
                                                teamsList={liga2V2TeamList}
                                                homeTeamId={liga2V2MatchDetails.homeTeamId}
                                                awayTeamId={liga2V2MatchDetails.awayTeamId}></TeamMatchScoreComponent>
                                        </div>
                                    </div>

                                    <div className="matchDetailsPage__formations--stats">
                                        <MatchListComponent teamsList={liga2V2TeamList} matchListHistory={liga2V2MatchH2HData}></MatchListComponent>
                                        <TeamMatchStatsComponent
                                            matchDetails={liga2V2MatchDetails}
                                            teamsList={liga2V2TeamList}
                                            homeTeamId={liga2V2MatchDetails.homeTeamId}
                                            awayTeamId={liga2V2MatchDetails.awayTeamId}></TeamMatchStatsComponent>
                                        <PlayerComponent playermatch={getMVP()} isMvp={true} imgSize={200}></PlayerComponent>
                                        <StadiumComponent details={liga2V2MatchDetails.stadium}></StadiumComponent>
                                    </div>

                                    <div className="matchDetailsPage__formations--players">
                                        <div className="players__component home">
                                            <FieldTeamComponent
                                                isHome={true}
                                                occurences={liga2V2MatchDetails.occurrences}
                                                ligaPlayerSubsList={liga2V2MatchDetails.homeTeamSubs}
                                                ligaPlayerList={liga2V2MatchFormations.homeTeam}
                                                teamId={liga2V2MatchDetails.homeTeamId.toString()}></FieldTeamComponent>
                                        </div>
                                        <div className="players__component away">
                                            <FieldTeamComponent
                                                isHome={false}
                                                occurences={liga2V2MatchDetails.occurrences}
                                                ligaPlayerSubsList={liga2V2MatchDetails.awayTeamSubs}
                                                ligaPlayerList={liga2V2MatchFormations.awayTeam}
                                                teamId={liga2V2MatchDetails.awayTeamId.toString()}></FieldTeamComponent>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <LoadingComponent></LoadingComponent>
                )}
            </div>
        </>
    );
};

export default MatchDetailsPage;
