import { useEffect, useState } from "react";
import { CustomDropdown } from "../components/app/CustomDropdown";
import { LoadingComponent } from "../components/app/LoadingComponent";
import { RefHistoryComponent } from "../components/refs/RefHistoryComponent";
import { RefereeComponent } from "../components/refs/RefereeComponent";
import { ManageLiga2V2AllTeamsSessionData, ManageLiga2V2RefDetailsData, ManageLiga2V2RefMatchesData } from "../helpers/ApiResponseLiga2V2";
import { getDdlItems } from "../helpers/Helpers";
import "../styles/home.scss";
import "../styles/refdetails.scss";
import { Liga2Seasons } from "../types/CustomTypes";
import { Liga2RefereeV2, Liga2TeamV2, Liga2V2RefMatchesV2 } from "../types/Liga2TypesV2";

const RefDetailsPage = () => {
    const [liga2V2TeamList, liga2V2TeamListSet] = useState<Liga2TeamV2[] | undefined>(undefined);
    const [liga2seasonSelected, liga2seasonSelectedSet] = useState<number>(Liga2Seasons[0][0]);
    const [liga2seasonSelectedLabel, liga2seasonSelectedLabelSet] = useState<string>(Liga2Seasons[0][1]);

    const [liga2refereev2, liga2refereev2Set] = useState<Liga2RefereeV2 | undefined>(undefined);
    const [liga2v2refmatchesv2, liga2v2refmatchesv2Set] = useState<Liga2V2RefMatchesV2 | undefined>(undefined);

    useEffect(() => {
        const getData = async () => {
            var url = new URL(window.location.href);
            const _refId = Number.parseInt(url.searchParams.get("id"));

            const _liga2V2TeamsList = await ManageLiga2V2AllTeamsSessionData();
            liga2V2TeamListSet(_liga2V2TeamsList);

            const _refDetails = await ManageLiga2V2RefDetailsData(_refId);
            liga2refereev2Set(_refDetails);

            const _refMatches = await ManageLiga2V2RefMatchesData(_refId, liga2seasonSelected!.toString());
            liga2v2refmatchesv2Set(_refMatches);

            setDDL();
        };
        getData();
    }, []);

    useEffect(() => {
        const getseasonSelected = async () => {
            var url = new URL(window.location.href);
            const _refId = Number.parseInt(url.searchParams.get("id"));

            const _refDetails = await ManageLiga2V2RefMatchesData(_refId!, liga2seasonSelected!.toString());
            liga2v2refmatchesv2Set(_refDetails);
        };
        getseasonSelected();

        return () => {};
    }, [liga2seasonSelected]);

    function setDDL() {
        const _value: number[] = [];
        const _label: string[] = [];

        for (let item in Liga2Seasons) {
            _value.push(Liga2Seasons[item][0]);
            _label.push("Season " + Liga2Seasons[item][1]);
        }

        const _valueSorted = _value.sort((n1, n2) => n2 - n1);
        const _labelSorted = _label.sort((one, two) => (one > two ? -1 : 1));

        return getDdlItems(_valueSorted, _labelSorted, true);
    }

    function onchangeDDL(_valueS: string) {
        const _value: number = +_valueS;

        if (liga2seasonSelected !== _value) {
            liga2v2refmatchesv2Set(undefined);
            liga2seasonSelectedSet(_value);

            for (var i = 0, len = Liga2Seasons.length; i < len; i++) {
                if (Liga2Seasons[i][0] === _value) {
                    liga2seasonSelectedLabelSet(Liga2Seasons[i][1]);
                    break;
                }
            }
        }
    }

    return (
        <>
            <div className="refDetailsPage">
                <div className="refDetailsPage--content">
                    <div className="refDetailsPage--content__ddl">
                        <CustomDropdown ddItems={setDDL()} onChangeDrop={onchangeDDL}></CustomDropdown>
                    </div>
                    {liga2V2TeamList && liga2refereev2 && liga2v2refmatchesv2 ? (
                        <>
                            {liga2refereev2 ? (
                                <div className="refDetailsPage--content__refInfo">
                                    <div className="refInfo--img">
                                        <RefereeComponent hasClick={false} dataRef={liga2refereev2}></RefereeComponent>
                                    </div>
                                    <div className="refInfo--extra">
                                        <div className="refInfo--extra__details">
                                            <span>{liga2refereev2.fullName}</span>
                                            <span>{liga2refereev2.affiliation}</span>
                                            <span>{liga2refereev2.category}</span>
                                            <span>Tempo útil - {liga2v2refmatchesv2.usefullFixtureTimeAveragePercentage.toFixed(2)}%</span>
                                            <div className="refInfo--cards">
                                                <div className="refInfo--cards__yellow">
                                                    <span>{liga2v2refmatchesv2.yellowCards}</span>
                                                </div>
                                                <div className="refInfo--cards__doubleyellow">
                                                    <span>{liga2v2refmatchesv2.doubleYellowCards}</span>
                                                </div>
                                                <div className="refInfo--cards__red">
                                                    <span>{liga2v2refmatchesv2.redCards}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {liga2v2refmatchesv2 ? (
                                <div className="refDetailsPage--content__matchDetails">
                                    <RefHistoryComponent teamsList={liga2V2TeamList} matchList={liga2v2refmatchesv2}></RefHistoryComponent>
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <LoadingComponent></LoadingComponent>
                    )}
                </div>
            </div>
        </>
    );
};

export default RefDetailsPage;
