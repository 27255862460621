import moment from "moment";
import { Liga2TeamStatisticsTypeId, Liga2WeeklyLeadersRechartType } from "../types/CustomTypes";
import {
    FixtureV2,
    LeagueDelegate,
    Liga2CompetitionV2,
    Liga2DelegateV2,
    Liga2FormationTeamV2,
    Liga2MatchDetailsV2,
    Liga2MatchFormationsV2,
    Liga2MatchInfoV2,
    Liga2NominationsV2,
    Liga2OccurrenceV2,
    Liga2ParticipantV2,
    Liga2PlayerDetailsV2,
    Liga2RefereeV2,
    Liga2RoundsV2,
    Liga2SeasonsV2,
    Liga2SquadMemberV2,
    Liga2StadiumV2,
    Liga2StandingsByRoundsV2,
    Liga2StandingsV2,
    Liga2StatisticV2,
    Liga2TeamSquadV2,
    Liga2TeamStatisticsInformationV2,
    Liga2TeamStatisticsV2,
    Liga2TeamV2,
    Liga2V2MatchDetailsResponse,
    Liga2V2MatchDynamicDetailsResponse,
    Liga2V2MatchFormationsResponse,
    Liga2V2MatchRefsV2,
    Liga2V2NominationsResponse,
    Liga2V2RefMatchesResponse,
    Liga2V2RefMatchesV2,
    Liga2V2RoundMatchesResponse,
    Liga2V2RoundsResponse,
    Liga2V2SeasonsResponse,
    Liga2V2StandingsResponse,
    Liga2V2TeamMatchesResponse,
    Liga2V2TeamSquadResponse,
    Liga2V2TeamStatisticsResponse,
    Liga2V2TeamsResponse,
    Referee,
} from "../types/Liga2TypesV2";
import { getApiDataCors } from "./ApiData";
import {
    LIGA2OFFICIAL_COMPETITIONID,
    LIGA2OFFICIAL_COMPETITIONID1,
    LIGA2OFFICIAL_COMPETITIONID2,
    LIGA2OFFICIAL_MATCH_DETAILS,
    LIGA2OFFICIAL_MATCH_STATISTICS as LIGA2OFFICIAL_MATCH_DYNAMIC,
    LIGA2OFFICIAL_MATCH_FORMATIONS,
    LIGA2OFFICIAL_MATCH_H2H,
    LIGA2OFFICIAL_NOMINATIONS,
    LIGA2OFFICIAL_REFDETAILS,
    LIGA2OFFICIAL_REFMATCHES,
    LIGA2OFFICIAL_ROUNDS,
    LIGA2OFFICIAL_ROUND_MATCHES,
    LIGA2OFFICIAL_SEASONID,
    LIGA2OFFICIAL_SEASONS,
    LIGA2OFFICIAL_STANDINGS,
    LIGA2OFFICIAL_STANDINGS_BY_ROUND,
    LIGA2OFFICIAL_TEAMS,
    LIGA2OFFICIAL_TEAM_MATCHES,
    LIGA2OFFICIAL_TEAM_SQUAD,
    LIGA2OFFICIAL_TEAM_STATISTICS,
    LIGA2_URL,
    LIGA2_USECACHE,
    apiDelay,
} from "./Constants";
import { delay, getUrlApiForLigaV2, getUrlApiForLigaV2Parameter, stringFormat } from "./Helpers";

export async function ManageLiga2V2StandingsSessionData(_round?: number): Promise<Liga2StandingsV2[]> {
    let _output: Liga2StandingsV2[] = [];
    let _outputAPI: Liga2V2StandingsResponse;

    let urlStandings = _round! ? LIGA2_URL + LIGA2OFFICIAL_STANDINGS_BY_ROUND : LIGA2_URL + LIGA2OFFICIAL_STANDINGS;
    let urlApi: string;

    if (_round!) {
        const urlApiFormatted = stringFormat(urlStandings, _round);
        urlStandings = urlApiFormatted;
        urlApi = getUrlApiForLigaV2Parameter(urlStandings, true, "round_id", _round.toString());
    } else {
        urlApi = getUrlApiForLigaV2(urlStandings);
    }
    const sessionID = _round! ? "L2_STANDINGS_" + _round : "L2_STANDINGS";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2StandingsV2[];
    } else {
        _outputAPI = await getApiDataCors(urlApi);

        _outputAPI.standingsTable.forEach((element) => {
            const _outputElement: Liga2StandingsV2 = {
                id: element.external_id,
                name: element.team_name,
                place: element.position,
                points: element.points,
            };
            _output.push(_outputElement);
        });

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2TeamsSessionData(): Promise<Liga2TeamV2[]> {
    let _output: Liga2TeamV2[] = [];

    const urlApi = getUrlApiForLigaV2(LIGA2_URL + LIGA2OFFICIAL_TEAMS);
    const sessionID = "L2_TEAMS";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamV2[];
    } else {
        let _liga2V2TeamsResponse: Liga2V2TeamsResponse = await getApiDataCors(urlApi);
        if (_liga2V2TeamsResponse!) {
            _liga2V2TeamsResponse.forEach((_team) => {
                const _tempTeam: Liga2TeamV2 = {
                    id: _team.external_id.toString(),
                    name: _team.name,
                    fullname: _team.fullName,
                    colorPrimary: _team.teamColour,
                    colorSecondary: _team.teamSecondaryColour,
                    abbreviation: _team.abbreviation,
                    imgUrl: _team.logo,
                    imgNegativeUrl: _team.negativeLogo,
                    imgAlternative: _team.alternateLogo,
                };
                _output.push(_tempTeam);
            });
        }

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2RoundsSessionData(): Promise<Liga2RoundsV2[]> {
    let _output: Liga2RoundsV2[] = [];

    const urlApi = getUrlApiForLigaV2(LIGA2_URL + LIGA2OFFICIAL_ROUNDS);
    const sessionID = "L2_ROUNDS";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2RoundsV2[];
    } else {
        const liga2v2roundsresponse: Liga2V2RoundsResponse = await getApiDataCors(urlApi);
        liga2v2roundsresponse.forEach((_round) => {
            const _outputElement: Liga2RoundsV2 = {
                number: _round.round_number,
                initialDate: moment(_round.initial_date).toDate(),
                finalDate: moment(_round.final_date).toDate(),
                isCurrent: _round.is_current_round,
                isPlayed: _round.has_been_played,
            };
            _output.push(_outputElement);
        });

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2TeamMatchesSessionData(_team: string): Promise<Liga2MatchInfoV2[]> {
    let _output: Liga2MatchInfoV2[] = [];

    const urlApi = getUrlApiForLigaV2Parameter(getUrlApiForLigaV2(LIGA2_URL + LIGA2OFFICIAL_TEAM_MATCHES), false, "team_id", _team.toString());

    const sessionID = "L2_TEAM_MATCHES" + _team;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchInfoV2[];
    } else {
        const liga2teammatches: Liga2V2TeamMatchesResponse = await getApiDataCors(urlApi);
        liga2teammatches.forEach((_match) => {
            const _outputElement: Liga2MatchInfoV2 = {
                id: _match.fixtureNumber.toString(),
                matchweekNumber: _match.matchweekNumber,
                matchDate: moment(_match.matchDate).toDate(),
                homeTeamGoals: _match.homeTeamGoals,
                awayTeamGoals: _match.awayTeamGoals,
                fixtureStateTypeId: _match.fixtureStateTypeId,
                fixtureSubStateTypeId: _match.fixtureSubStateTypeId,
                fixtureDateIsDefined: _match.fixtureDateIsDefined,
                homeTeamId: Number.parseInt(_match.homeTeam.external_id),
                awayTeamId: Number.parseInt(_match.awayTeam.external_id),
                seasonCode: _match.seasonCode,
            };
            _output.push(_outputElement);
        });

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2TeamStatisticsSessionData(_team: string): Promise<Liga2TeamStatisticsV2[]> {
    let _output: Liga2TeamStatisticsV2[] = [];

    const urlApi = getUrlApiForLigaV2Parameter(getUrlApiForLigaV2(LIGA2_URL + LIGA2OFFICIAL_TEAM_STATISTICS), true, "team_id", _team.toString());

    const sessionID = "L2_TEAM_STATS_" + _team;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamStatisticsV2[];
    } else {
        const liga2v2teamstatisticsresponse: Liga2V2TeamStatisticsResponse = await getApiDataCors(urlApi);
        liga2v2teamstatisticsresponse.forEach((_teamstatisticsv2) => {
            const _outputElement_StatisticsInformation: Liga2TeamStatisticsInformationV2[] = [];
            _teamstatisticsv2.teamStatisticsInformation.forEach((_teamStatisticsInformation) => {
                const _outputElement_StatisticsInformation_Element: Liga2TeamStatisticsInformationV2 = {
                    statisticTypeId: _teamStatisticsInformation.statisticTypeId,
                    statisticDescription: Liga2TeamStatisticsTypeId[_teamStatisticsInformation.statisticTypeId],
                    total: _teamStatisticsInformation.total,
                };
                _outputElement_StatisticsInformation.push(_outputElement_StatisticsInformation_Element);
            });

            const _outputElement: Liga2TeamStatisticsV2 = {
                id: _teamstatisticsv2.id,
                statisticGroupDescription: _teamstatisticsv2.statisticGroupDescription,
                teamStatisticsInformation: _outputElement_StatisticsInformation,
            };

            _output.push(_outputElement);
        });
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2TeamSquadSessionData(_team: string): Promise<Liga2TeamSquadV2> {
    let _output: Liga2TeamSquadV2 = { coaches: undefined, players: undefined };

    const urlApi = getUrlApiForLigaV2Parameter(getUrlApiForLigaV2(LIGA2_URL + LIGA2OFFICIAL_TEAM_SQUAD), false, "team_id", _team);

    const sessionID = "L2_TEAM_SQUAD_" + _team;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamSquadV2;
    } else {
        const liga2v2teamsquadresponse: Liga2V2TeamSquadResponse = await getApiDataCors(urlApi);

        const _outputElementPlayer: Liga2SquadMemberV2[] = [];
        const _outputElementCoach: Liga2SquadMemberV2[] = [];

        liga2v2teamsquadresponse.players.forEach((_player) => {
            const _tempPlayer: Liga2SquadMemberV2 = {
                id: _player.external_id,
                number: _player.shirtNumber,
                name: _player.name,
                position: _player.position,
                nationality: _player.countryIso,
                mediaUrl: _player.photosFormats ? _player.photosFormats.url : null,
                mediaBodyUrl: _player.fullBodyImageFormats ? _player.fullBodyImageFormats.url : null,
            };
            _outputElementPlayer.push(_tempPlayer);
        });

        liga2v2teamsquadresponse.technical_team.forEach((_technical_team) => {
            const _tempCoach: Liga2SquadMemberV2 = {
                id: _technical_team.external_id,
                name: _technical_team.name,
                position: _technical_team.position,
                nationality: _technical_team.countryIso,
            };
            _outputElementCoach.push(_tempCoach);
        });

        _output.coaches = _outputElementCoach;
        _output.players = _outputElementPlayer;

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2SeasonsSessionData(): Promise<Liga2SeasonsV2[]> {
    let _output: Liga2SeasonsV2[] = [];

    const urlApi = getUrlApiForLigaV2(LIGA2_URL + LIGA2OFFICIAL_SEASONS);

    const sessionID = "L2_SEASONS";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2SeasonsV2[];
    } else {
        const liga2v2seasonsresponse: Liga2V2SeasonsResponse = await getApiDataCors(urlApi);

        liga2v2seasonsresponse.forEach((_season) => {
            const _outputCompetitions: Liga2CompetitionV2[] = [];
            _season.competitions.forEach((_competition) => {
                const _outputCompetitionElement: Liga2CompetitionV2 = {
                    id: _competition.externalId,
                    name: _competition.name,
                    primaryColor: _competition.primaryColor,
                    textColor: _competition.textColor,
                    logoUrl: _competition.logo,
                    smallLogoUrl: _competition.smallLogo,
                    alternateLogoUrl: _competition.alternateLogo,
                    promotions: _competition.promotions,
                };
                _outputCompetitions.push(_outputCompetitionElement);
            });

            const _outputElement: Liga2SeasonsV2 = {
                id: _season.id,
                name: _season.name,
                is_current_season: _season.is_current_season,
                initial_date: _season.initial_date,
                final_date: _season.final_date,
                competitions: _outputCompetitions,
            };

            _output.push(_outputElement);
        });

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2RoundMatchesSessionData(_round: number): Promise<Liga2MatchInfoV2[]> {
    let _output: Liga2MatchInfoV2[] = [];

    const urlApi = getUrlApiForLigaV2Parameter(getUrlApiForLigaV2(LIGA2_URL + LIGA2OFFICIAL_ROUND_MATCHES), true, "round_id", _round.toString());

    const sessionID = "L2_R_MATCHES_" + _round;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchInfoV2[];
    } else {
        const liga2v2roundmatchesresponse: Liga2V2RoundMatchesResponse = await getApiDataCors(urlApi);

        liga2v2roundmatchesresponse.forEach((_match) => {
            const _outputElement: Liga2MatchInfoV2 = {
                id: _match.fixtureNumber.toString(),
                seasonCode: _match.seasonCode,
                matchweekNumber: _match.matchweekNumber,
                matchDate: moment(_match.matchDate).toDate(),
                homeTeamGoals: _match.homeTeamGoals,
                awayTeamGoals: _match.awayTeamGoals,
                fixtureStateTypeId: _match.fixtureStateTypeId,
                fixtureSubStateTypeId: _match.fixtureSubStateTypeId,
                fixtureDateIsDefined: _match.fixtureDateIsDefined,
                homeTeamId: Number.parseInt(_match.homeTeam.external_id),
                awayTeamId: Number.parseInt(_match.awayTeam.external_id),
            };

            _output.push(_outputElement);
        });
        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output.sort((a, b) => (a.matchDate < b.matchDate ? -1 : a.matchDate > b.matchDate ? 1 : 0));
}

export async function ManageLiga2V2MatchDetailsData(_round: number, _fixture: number): Promise<Liga2MatchDetailsV2> {
    let _output: Liga2MatchDetailsV2;

    var url = new URL(LIGA2_URL + LIGA2OFFICIAL_MATCH_DETAILS);
    url.searchParams.set("competition_id", LIGA2OFFICIAL_COMPETITIONID);
    url.searchParams.set("season_id", LIGA2OFFICIAL_SEASONID);
    url.searchParams.set("round_id", _round.toString());
    url.searchParams.set("fixture_id", _fixture.toString());
    const urlApi = url.href;

    var url2 = new URL(LIGA2_URL + LIGA2OFFICIAL_MATCH_DYNAMIC);
    url2.searchParams.set("competition_id", LIGA2OFFICIAL_COMPETITIONID);
    url2.searchParams.set("season_id", LIGA2OFFICIAL_SEASONID);
    url2.searchParams.set("round_id", _round.toString());
    url2.searchParams.set("fixture_id", _fixture.toString());
    const urlApi2 = url2.href;

    const sessionID = "L2_M_DETAILS_" + _round + "_" + _fixture;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchDetailsV2;
    } else {
        const liga2v2matchdetailsresponse: Liga2V2MatchDetailsResponse = await getApiDataCors(urlApi);
        const liga2v2matchstatisticsresponse: Liga2V2MatchDynamicDetailsResponse = await getApiDataCors(urlApi2);

        const _homeSubs = getTeamSubsFromMatchDetails(liga2v2matchdetailsresponse, true);
        const _awaySubs = getTeamSubsFromMatchDetails(liga2v2matchdetailsresponse, false);
        const _mvp = getMVPDetails(liga2v2matchstatisticsresponse.manOfTheMatchEntityId, liga2v2matchdetailsresponse);
        const _isMVPHomeTeam = _mvp.teamId === parseInt(liga2v2matchdetailsresponse.homeTeam.external_id);

        _output = {
            refs: getLiga2MatchRefsV2FromLiga2V2MatchDetailsResponse(liga2v2matchdetailsresponse),
            awayTeamDoubleYellowCards: liga2v2matchdetailsresponse.awayTeamSecondYellowCards,
            awayTeamYellowCards: liga2v2matchstatisticsresponse.homeTeamDoubleYellowCardsNumber,
            awayTeamForm: liga2v2matchdetailsresponse.awayTeamForm,
            awayTeamGoals: liga2v2matchdetailsresponse.awayTeamGoals,
            awayTeamId: Number.parseInt(liga2v2matchdetailsresponse.awayTeam.external_id),
            awayTeamRedCards: liga2v2matchdetailsresponse.awayTeamRedCards,
            broadcastOperator: liga2v2matchdetailsresponse.broadcastOperator,
            canceledFixture: liga2v2matchdetailsresponse.canceledFixture,
            fixtureDateIsDefined: liga2v2matchdetailsresponse.fixtureDateIsDefined,
            fixtureStateTypeId: liga2v2matchdetailsresponse.fixtureStateTypeId,
            fixtureSubStateTypeId: liga2v2matchdetailsresponse.fixtureSubStateTypeId,
            hasOfficialResult: liga2v2matchstatisticsresponse.hasOfficialResult,
            homeTeamDoubleYellowCards: liga2v2matchdetailsresponse.homeTeamSecondYellowCards,
            homeTeamForm: liga2v2matchdetailsresponse.homeTeamForm,
            homeTeamGoals: liga2v2matchdetailsresponse.homeTeamGoals,
            homeTeamId: Number.parseInt(liga2v2matchdetailsresponse.homeTeam.external_id),
            homeTeamRedCards: liga2v2matchdetailsresponse.homeTeamRedCards,
            homeTeamYellowCards: liga2v2matchstatisticsresponse.homeTeamYellowCardsNumber,
            id: _fixture.toString(),
            manOfTheMatchId: _mvp.id,
            manOfTheMatchImgUrl: _mvp.mediaUrl,
            manOfTheMatchName: _mvp.name,
            manOfTheMatchIsHomeTeam: _isMVPHomeTeam,
            matchDate: moment(liga2v2matchdetailsresponse.matchDate).toDate(),
            matchweekNumber: _round,
            notPlayedFixture: liga2v2matchdetailsresponse.notPlayedFixture,
            percentageTime: liga2v2matchstatisticsresponse.percentageTime,
            pitchEvaluationAverage: liga2v2matchdetailsresponse.pitchEvaluationAverage,
            postponedFixture: liga2v2matchdetailsresponse.postponedFixture,
            stoppageTime: liga2v2matchstatisticsresponse.stoppageTime,
            timeFirstHalf: liga2v2matchstatisticsresponse.timeFirstHalf,
            timeSecondHalf: liga2v2matchstatisticsresponse.timeSecondHalf,
            occurrences: getOccurencesFromApiResponse(liga2v2matchstatisticsresponse),
            statistics: getStatisticsFromApiResponse(liga2v2matchstatisticsresponse),
            fixtureUsefullTimeDescription: liga2v2matchstatisticsresponse.fixtureUsefullTimeDescription,
            minutes: liga2v2matchstatisticsresponse.minutes,
            seasonCode: liga2v2matchstatisticsresponse.seasonCode,
            stadium: getStadiumDetails(liga2v2matchdetailsresponse, liga2v2matchstatisticsresponse),

            awayTeamSubs: _awaySubs,
            homeTeamSubs: _homeSubs,
        };

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2MatchFormationsData(_round: number, _fixture: number): Promise<Liga2MatchFormationsV2> {
    let _output: Liga2MatchFormationsV2;

    var url = new URL(LIGA2_URL + LIGA2OFFICIAL_MATCH_FORMATIONS);
    url.searchParams.set("competition_id", LIGA2OFFICIAL_COMPETITIONID);
    url.searchParams.set("season_id", LIGA2OFFICIAL_SEASONID);
    url.searchParams.set("round_id", _round.toString());
    url.searchParams.set("fixture_id", _fixture.toString());
    const urlApi = url.href;

    const sessionID = "L2_M_FORMATIONS_" + _round + "_" + _fixture;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchFormationsV2;
    } else {
        const liga2v2matchdetailsresponse: Liga2V2MatchFormationsResponse = await getApiDataCors(urlApi);

        let _homeTeam: Liga2FormationTeamV2 = { participants: [], formationDescription: liga2v2matchdetailsresponse.homeTeam.formationDescription };
        let _awayTeam: Liga2FormationTeamV2 = { participants: [], formationDescription: liga2v2matchdetailsresponse.awayTeam.formationDescription };

        _homeTeam.participants = [];
        liga2v2matchdetailsresponse.homeTeam.participants.forEach((element) => {
            _homeTeam.participants.push({
                playerId: element.playerId,
                positionId: element.positionId,
                isCaptain: element.captain != undefined,
                id: element.playerId,
                name: element.shirtName,
                nationality: element.player.countryIso,
                position: element.player.position,
                fullName: element.player.fullName,
                mediaBodyUrl: element.player.fullBodyImage,
                mediaUrl: element.player.photos,
                number: element.player.shirtNumber,
            });
        });

        _awayTeam.participants = [];
        liga2v2matchdetailsresponse.awayTeam.participants.forEach((element) => {
            _awayTeam.participants.push({
                playerId: element.playerId,
                positionId: element.positionId,
                isCaptain: element.captain != undefined,
                id: element.playerId,
                name: element.shirtName,
                nationality: element.player.countryIso,
                position: element.player.position,
                fullName: element.player.fullName,
                mediaBodyUrl: element.player.fullBodyImage,
                mediaUrl: element.player.photos,
                number: element.player.shirtNumber,
            });
        });

        _output = {
            awayTeam: _awayTeam,
            homeTeam: _homeTeam,
        };

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2MatchH2HData(_teamHome: string, _teamAway: string): Promise<Liga2MatchInfoV2[]> {
    let _output: Liga2MatchInfoV2[] = [];

    var url = new URL(LIGA2_URL + LIGA2OFFICIAL_MATCH_H2H);
    url.searchParams.set("hometeam_id", _teamHome);
    url.searchParams.set("awayteam_id", _teamAway);
    const urlApi = url.href;

    const sessionID = "L2_M_H2H_" + _teamHome + "_" + _teamAway;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchInfoV2[];
    } else {
        const liga2v2historyresponse: FixtureV2[] = await getApiDataCors(urlApi);
        liga2v2historyresponse.forEach((_match) => {
            const _outputElement: Liga2MatchInfoV2 = {
                matchDate: moment(_match.matchDate).toDate(),
                homeTeamGoals: _match.homeTeamGoals,
                awayTeamGoals: _match.awayTeamGoals,
                homeTeamId: Number.parseInt(_match.homeTeam.external_id),
                awayTeamId: Number.parseInt(_match.awayTeam.external_id),
                id: _match.fixtureNumber.toString(),
                matchweekNumber: _match.matchweekNumber,
                fixtureStateTypeId: _match.fixtureStateTypeId,
                fixtureSubStateTypeId: _match.fixtureSubStateTypeId,
                fixtureDateIsDefined: _match.fixtureDateIsDefined,
                seasonCode: _match.seasonCode,
            };
            _output.push(_outputElement);
        });

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2NominationsData(_round: number): Promise<Liga2NominationsV2[]> {
    let _output: Liga2NominationsV2[] = [];

    var url = new URL(LIGA2_URL + LIGA2OFFICIAL_NOMINATIONS);
    url.searchParams.set("competition_id", LIGA2OFFICIAL_COMPETITIONID);
    url.searchParams.set("season_id", LIGA2OFFICIAL_SEASONID);
    url.searchParams.set("round_id", _round.toString());
    const urlApi = url.href;

    const sessionID = "L2_NOMINATIONS_" + _round;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2NominationsV2[];
    } else {
        const liga2v2nominationsresponse: Liga2V2NominationsResponse = await getApiDataCors(urlApi);

        liga2v2nominationsresponse.forEach((_nomination) => {
            const _outputElement: Liga2NominationsV2 = {
                match: {
                    id: _nomination.fixture.fixtureNumber.toString(),
                    seasonCode: _nomination.fixture.seasonCode,
                    matchweekNumber: _nomination.fixture.matchweekNumber,
                    matchDate: new Date(_nomination.fixture.matchDate),
                    homeTeamGoals: _nomination.fixture.homeTeamGoals,
                    awayTeamGoals: _nomination.fixture.awayTeamGoals,
                    fixtureStateTypeId: _nomination.fixture.fixtureStateTypeId,
                    fixtureSubStateTypeId: _nomination.fixture.fixtureSubStateTypeId,
                    fixtureDateIsDefined: _nomination.fixture.fixtureDateIsDefined,
                    homeTeamId: Number.parseInt(_nomination.fixture.homeTeam.external_id),
                    awayTeamId: Number.parseInt(_nomination.fixture.awayTeam.external_id),
                },

                referee: getRefFromLigaRef(_nomination.referee),
                refereeAux1: getRefFromLigaRef(_nomination.refereeAux1),
                refereeAux2: getRefFromLigaRef(_nomination.refereeAux2),
                fourthReferee: getRefFromLigaRef(_nomination.fourthReferee),
                var: getRefFromLigaRef(_nomination.var),
                aVar: getRefFromLigaRef(_nomination.aVar),
                fieldDelegate: getDelFromLigaDel(_nomination.fieldDelegate),
                leagueDelegate: getDelFromLigaDel(_nomination.leagueDelegate),
                traineeDelegate: getDelFromLigaDel(_nomination.traineeDelegate),
                observerName: _nomination.observerName,
            };

            _output.push(_outputElement);
        });

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output.sort((a, b) => new Date(a.match.matchDate).getTime() - new Date(b.match.matchDate).getTime());
}

export async function ManageLiga2V2RefDetailsData(_refId: number): Promise<Liga2RefereeV2> {
    let _output: Liga2RefereeV2;

    var url = new URL(LIGA2_URL + LIGA2OFFICIAL_REFDETAILS);
    url.searchParams.set("season_id", LIGA2OFFICIAL_SEASONID);
    url.searchParams.set("ref_id", _refId.toString());
    const urlApi = url.href;

    const sessionID = "L2_REF_" + _refId;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2RefereeV2;
    } else {
        const liga2v2refdetailsresponse: Referee = await getApiDataCors(urlApi);
        _output = getRefFromLigaRef(liga2v2refdetailsresponse);

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2RefMatchesData(_refId: number, _seasonId: string): Promise<Liga2V2RefMatchesV2> {
    let _output: Liga2V2RefMatchesV2;

    var url = new URL(LIGA2_URL + LIGA2OFFICIAL_REFMATCHES);
    url.searchParams.set("season_id", _seasonId);
    url.searchParams.set("ref_id", _refId.toString());
    const urlApi = url.href;

    const sessionID = "L2_REF_" + _refId + "_" + LIGA2OFFICIAL_SEASONID;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2V2RefMatchesV2;
    } else {
        const liga2v2refmatchesresponse: Liga2V2RefMatchesResponse = await getApiDataCors(urlApi);
        _output = {
            usefullFixtureTimeAverage: getMinutesFromSeconds(liga2v2refmatchesresponse.usefullFixtureTimeAverageInSeconds),
            fixtureTotalAverage: getMinutesFromSeconds(liga2v2refmatchesresponse.fixtureTotalAverageInSeconds),
            usefullFixtureTimeAveragePercentage: (liga2v2refmatchesresponse.usefullFixtureTimeAverageInSeconds / liga2v2refmatchesresponse.fixtureTotalAverageInSeconds) * 100,
            redCards: liga2v2refmatchesresponse.redCards,
            doubleYellowCards: liga2v2refmatchesresponse.doubleYellowCards,
            yellowCards: liga2v2refmatchesresponse.yellowCards,
            fixtures: getLiga2MatchInfoV2ListFromFixtureV2List(liga2v2refmatchesresponse.fixtures),
        };

        // if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

export async function ManageLiga2V2AllTeamsSessionData(): Promise<Liga2TeamV2[]> {
    let _output: Liga2TeamV2[] = [];

    var url = new URL(LIGA2_URL + LIGA2OFFICIAL_TEAMS);
    url.searchParams.set("competition_id", LIGA2OFFICIAL_COMPETITIONID1);
    url.searchParams.set("season_id", LIGA2OFFICIAL_SEASONID);
    const urlApi1 = url.href;

    var url = new URL(LIGA2_URL + LIGA2OFFICIAL_TEAMS);
    url.searchParams.set("competition_id", LIGA2OFFICIAL_COMPETITIONID2);
    url.searchParams.set("season_id", LIGA2OFFICIAL_SEASONID);
    const urlApi2 = url.href;

    const sessionID = "ALL_TEAMS_" + LIGA2OFFICIAL_SEASONID;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamV2[];
    } else {
        let _liga2V2TeamsResponse1: Liga2V2TeamsResponse = await getApiDataCors(urlApi1);
        let _liga2V2TeamsResponse2: Liga2V2TeamsResponse = await getApiDataCors(urlApi2);
        let _liga2V2TeamsResponse0 = await ManageLiga2V2TeamsSessionData();

        if (_liga2V2TeamsResponse1! && _liga2V2TeamsResponse2) {
            _liga2V2TeamsResponse1.forEach((_team) => {
                const _tempTeam: Liga2TeamV2 = {
                    id: _team.external_id.toString(),
                    name: _team.name,
                    fullname: _team.fullName,
                    colorPrimary: _team.teamColour,
                    colorSecondary: _team.teamSecondaryColour,
                    abbreviation: _team.abbreviation,
                    imgUrl: _team.logo,
                    imgNegativeUrl: _team.negativeLogo,
                    imgAlternative: _team.alternateLogo,
                };
                _output.push(_tempTeam);
            });
            _liga2V2TeamsResponse2.forEach((_team) => {
                const _tempTeam: Liga2TeamV2 = {
                    id: _team.external_id.toString(),
                    name: _team.name,
                    fullname: _team.fullName,
                    colorPrimary: _team.teamColour,
                    colorSecondary: _team.teamSecondaryColour,
                    abbreviation: _team.abbreviation,
                    imgUrl: _team.logo,
                    imgNegativeUrl: _team.negativeLogo,
                    imgAlternative: _team.alternateLogo,
                };
                _output.push(_tempTeam);
            });
            _liga2V2TeamsResponse0.forEach((_team) => {
                _output.push(_team);
            });
        }

        if (LIGA2_USECACHE) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output;
}

//Liga2V2RefMatchesResponse
//handle types

export async function GetLiga2V2StandingsByRound(_round: number): Promise<Liga2StandingsV2[]> {
    return await ManageLiga2V2StandingsSessionData(_round);
}

export async function GetLiga2V2StandingsAllRounds(): Promise<Liga2StandingsByRoundsV2[]> {
    let _output: Liga2StandingsByRoundsV2[] = [];

    const playedRounds = await GetLiga2V2PlayedRounds();
    await Promise.all(
        playedRounds.map(async (_round) => {
            const _roundStandings = await ManageLiga2V2StandingsSessionData(_round.number);
            const _outputElement: Liga2StandingsByRoundsV2 = {
                round: _round.number,
                standings: _roundStandings,
            };

            await delay(apiDelay);
            _output.push(_outputElement);
        })
    );

    return _output.sort((n1, n2) => n1.round - n2.round)!;
}

export async function GetLiga2V2CurrentRound(): Promise<number> {
    const liga2V2RoundsSessionData = await ManageLiga2V2RoundsSessionData();
    return liga2V2RoundsSessionData.find((i) => i.isCurrent === true).number;
}

export async function GetLiga2V2PlayedRounds(): Promise<Liga2RoundsV2[]> {
    const liga2V2RoundsSessionData = await ManageLiga2V2RoundsSessionData();
    const playedRounds = liga2V2RoundsSessionData.filter((i) => i.isPlayed === true).sort((a, b) => a.number - b.number);
    return playedRounds;
}

export async function GetLiga2V2IsLastRound(): Promise<boolean> {
    const liga2V2RoundsSessionData = await ManageLiga2V2RoundsSessionData();
    const currentRound = liga2V2RoundsSessionData.find((i) => i.isCurrent === true).number;
    const lastrRound = Math.max(...liga2V2RoundsSessionData.map((o) => o.number));
    return currentRound === lastrRound;
}

function getOccurencesFromApiResponse(_inputApi: Liga2V2MatchDynamicDetailsResponse): Liga2OccurrenceV2[] {
    const _output: Liga2OccurrenceV2[] = [];
    if (_inputApi.occurrences) {
        _inputApi.occurrences.forEach((element) => {
            const _outputElement: Liga2OccurrenceV2 = {
                occurrenceTypeId: element.occurrenceTypeId,
                isHomeTeam: element.isHomeTeam,
                minute: element.minute,
                stoppageTime: element.stoppageTime,
                half: element.half,
                name: element.name,
                embed: element.embed,
                id: element.entityId,
            };
            _output.push(_outputElement);
        });
    }
    return _output;
}

function getStatisticsFromApiResponse(_inputApi: Liga2V2MatchDynamicDetailsResponse): Liga2StatisticV2[] {
    const _output: Liga2StatisticV2[] = [];

    if (_inputApi.statistics) {
        _inputApi.statistics.forEach((element) => {
            const _outputElement: Liga2StatisticV2 = {
                awayTeamValue: element.awayTeamValue,
                homeTeamValue: element.homeTeamValue,
                statisticName: Liga2TeamStatisticsTypeId[element.statisticTypeId],
            };
            _output.push(_outputElement);
        });
    }
    return _output;
}

export function getPlayerDetails(_playerId: number, _teamId: string, _squad: Liga2FormationTeamV2): Liga2PlayerDetailsV2 {
    const _playerDetails = _squad.participants.find((i) => i.id === _playerId);

    const _output: Liga2PlayerDetailsV2 = {
        id: _playerDetails.id,
        number: _playerDetails.number,
        name: _playerDetails.name,
        fullName: _playerDetails.fullName,
        position: _playerDetails.position,
        nationality: _playerDetails.nationality,
        mediaUrl: _playerDetails.mediaUrl,
        mediaBodyUrl: _playerDetails.mediaBodyUrl,
        teamId: Number.parseInt(_teamId),
    };

    return _output;
}

export async function getChartDataForWeeklyLeaders(): Promise<Liga2WeeklyLeadersRechartType[]> {
    let _output: Liga2WeeklyLeadersRechartType[] = [];

    const playedRounds = await GetLiga2V2StandingsAllRounds();

    playedRounds.forEach((_round) => {
        const _outputElement: Liga2WeeklyLeadersRechartType = {};
        _outputElement["name"] = _round.round;
        _round.standings.forEach((element) => {
            _outputElement[element.name] = element.place;
        });

        _output.push(_outputElement);
    });

    return _output!;
}

export async function getChartDataForPoints(): Promise<Liga2WeeklyLeadersRechartType[]> {
    let _output: Liga2WeeklyLeadersRechartType[] = [];

    const playedRounds = await GetLiga2V2StandingsAllRounds();

    playedRounds.forEach((_round) => {
        const _outputElement: Liga2WeeklyLeadersRechartType = {};
        _outputElement["name"] = _round.round;
        _round.standings.forEach((element) => {
            _outputElement[element.name] = element.points;
        });

        _output.push(_outputElement);
    });

    return _output!;
}

function getTeamSubsFromMatchDetails(Liga2V2MatchDetailsResponse: Liga2V2MatchDetailsResponse, _homeTeam: boolean): Liga2ParticipantV2[] {
    const _output: Liga2ParticipantV2[] = [];

    if (_homeTeam) {
        Liga2V2MatchDetailsResponse.homeTeamParticipants.forEach((element) => {
            if (element.player != null && element.intervenientTypeId === 2) {
                const _outputElement: Liga2ParticipantV2 = {
                    id: parseInt(element.player.external_id),
                    name: element.player.name,
                    positionId: 0,
                    nationality: element.player.countryIso,
                    position: "Suplente",
                    fullName: element.player.fullName,
                    mediaBodyUrl: element.player.fullBodyImage,
                    mediaUrl: element.player.photos,
                    number: element.player.shirtNumber,
                };
                _output.push(_outputElement);
            }
        });
    } else {
        Liga2V2MatchDetailsResponse.awayTeamParticipants.forEach((element) => {
            if (element.player != null && element.intervenientTypeId === 2) {
                const _outputElement: Liga2ParticipantV2 = {
                    id: parseInt(element.player.external_id),
                    name: element.player.name,
                    positionId: 0,
                    nationality: element.player.countryIso,
                    position: "Suplente",
                    fullName: element.player.fullName,
                    mediaBodyUrl: element.player.fullBodyImage,
                    mediaUrl: element.player.photos,
                    number: element.player.shirtNumber,
                };
                _output.push(_outputElement);
            }
        });
    }

    return _output;
}

function getMVPDetails(_mvpId: number, _liga2v2matchdetailsresponse: Liga2V2MatchDetailsResponse): Liga2SquadMemberV2 {
    const mvpDetailsA = _liga2v2matchdetailsresponse.awayTeamParticipants.find((i) => i.externalId === _mvpId.toString());
    const mvpDetailsH = _liga2v2matchdetailsresponse.homeTeamParticipants.find((i) => i.externalId === _mvpId.toString());

    if (mvpDetailsA === undefined) {
        const _output: Liga2SquadMemberV2 = {
            id: parseInt(mvpDetailsH.externalId),
            name: mvpDetailsH.name,
            mediaUrl: mvpDetailsH.photo,
            teamId: parseInt(_liga2v2matchdetailsresponse.homeTeam.external_id),
        };
        return _output;
    } else {
        const _output: Liga2SquadMemberV2 = {
            id: parseInt(mvpDetailsA.externalId),
            name: mvpDetailsA.name,
            mediaUrl: mvpDetailsA.photo,
            teamId: parseInt(_liga2v2matchdetailsresponse.awayTeam.external_id),
        };
        return _output;
    }
}

function getStadiumDetails(_liga2v2matchdetailsresponse: Liga2V2MatchDetailsResponse, _liga2v2matchstatisticsresponse: Liga2V2MatchDynamicDetailsResponse): Liga2StadiumV2 {
    const _output: Liga2StadiumV2 = {
        name: _liga2v2matchdetailsresponse.stadium.name,
        address: _liga2v2matchdetailsresponse.stadium.address,
        capacity: _liga2v2matchdetailsresponse.stadium.capacity,
        imgUrl: _liga2v2matchdetailsresponse.stadium.media,
        id: _liga2v2matchdetailsresponse.stadiumId,
        averageRating: _liga2v2matchdetailsresponse.pitchEvaluationAverage,
        spectators: _liga2v2matchstatisticsresponse.spectators,
        averageSpectators: _liga2v2matchstatisticsresponse.spectatorsPercentage,
    };

    return _output;
}

function getRefFromLigaRef(_refDetails: Referee): Liga2RefereeV2 {
    let _output: Liga2RefereeV2;

    if (_refDetails) {
        _output = {
            id: Number.parseInt(_refDetails.external_id),
            name: _refDetails.name,
            fullName: _refDetails.fullName,
            nationality: _refDetails.countryIso,
            mediaUrl: _refDetails.fullBodyImage,
            birthday: moment(_refDetails.birthday).toDate(),
            category: _refDetails.category,
            affiliation: _refDetails.affiliation,
        };
    }

    return _output;
}

function getDelFromLigaDel(_delDetails: LeagueDelegate): Liga2DelegateV2 {
    let _output: Liga2DelegateV2;

    if (_delDetails) {
        _output = {
            id: Number.parseInt(_delDetails.external_id),
            name: _delDetails.name,
            fullName: _delDetails.fullName,
        };
    }

    return _output;
}

function getMinutesFromSeconds(_secondsInput: number): string {
    const _minutes = Math.floor(_secondsInput / 60);
    const _seconds = Math.floor(_secondsInput % 60);

    return _minutes + ":" + _seconds;
}

function getLiga2MatchInfoV2ListFromFixtureV2List(_fixtureListInput: FixtureV2[]): Liga2MatchInfoV2[] {
    const _output: Liga2MatchInfoV2[] = [];

    _fixtureListInput.forEach((element) => {
        _output.push({
            id: element.fixtureNumber.toString(),
            seasonCode: element.seasonCode,
            matchweekNumber: element.matchweekNumber,
            matchDate: new Date(element.fixtureDate),
            homeTeamGoals: element.homeTeamGoals,
            awayTeamGoals: element.awayTeamGoals,
            fixtureStateTypeId: element.fixtureStateTypeId,
            fixtureSubStateTypeId: element.fixtureSubStateTypeId,
            fixtureDateIsDefined: element.fixtureDateIsDefined,
            homeTeamId: Number.parseInt(element.homeTeam.external_id),
            awayTeamId: Number.parseInt(element.awayTeam.external_id),
            competitionName: element.competitionName,
        });
    });

    return _output;
}

function getLiga2MatchRefsV2FromLiga2V2MatchDetailsResponse(_inputData: Liga2V2MatchDetailsResponse): Liga2V2MatchRefsV2 {
    const _output: Liga2V2MatchRefsV2 = {
        refereeId: _inputData.refereeId,
        refereeName: _inputData.refereeName,
        firstAssistantRefereeId: _inputData.firstAssistantRefereeId,
        firstAssistantRefereeName: _inputData.firstAssistantRefereeName,
        secondAssistantRefereeId: _inputData.secondAssistantRefereeId,
        secondAssistantRefereeName: _inputData.secondAssistantRefereeName,
        fourthRefereeId: _inputData.fourthRefereeId,
        fourthRefereeName: _inputData.fourthRefereeName,
        firstObserverId: _inputData.firstObserverId,
        firstObserverName: _inputData.firstObserverName,
        secondObserverId: _inputData.secondObserverId,
        secondObserverName: _inputData.secondObserverName,
        firstDelegateId: _inputData.firstDelegateId,
        firstDelegateName: _inputData.firstDelegateName,
        secondDelegateId: _inputData.secondDelegateId,
        secondDelegateName: _inputData.secondDelegateName,
        thirdDelegateId: _inputData.thirdDelegateId,
        thirdDelegateName: _inputData.thirdDelegateName,
        varId: _inputData.varId,
        varName: _inputData.varName,
        aVarId: _inputData.aVarId,
        aVarName: _inputData.aVarName,
    };

    return _output;
}
