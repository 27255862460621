import { useEffect } from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";

interface Props {
    text?: string;
    onClickAction?: Function;
    disabled?: boolean;
}

const Wrapper = styled.div<{}>`
    padding: 5px;
    margin: 5px;
    width: fit-content;
    display: flex;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;
const Toggle = styled.label<{}>`
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;
const Toggle_Input = styled.input.attrs({ type: "checkbox" })`
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;
const Toggle_Span = styled.span<{}>`
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;
const TextContainer = styled.div<{}>`
    margin: auto 10px;
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;
const TextSpan = styled.span<{}>`
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

export const Liga2Toggle: React.FC<Props> = ({ text, onClickAction, disabled }) => {
    function checkboxOnchange(_value: boolean) {
        onClickAction(_value);
    }

    useEffect(() => {
        return () => {};
    }, []);

    return (
        <>
            <Wrapper>
                {text ? (
                    <TextContainer>
                        <TextSpan>{text}</TextSpan>
                    </TextContainer>
                ) : null}
                <Toggle className="switch">
                    <Toggle_Input onChange={(event) => checkboxOnchange(event.target.checked)}></Toggle_Input>
                    <Toggle_Span className="slider round"></Toggle_Span>
                </Toggle>
            </Wrapper>
        </>
    );
};
