import axios from "axios";

export async function getApiDataGeneric(_apiEndpoint: string) {
    var _apiResult = undefined;
    try {
        const res = await axios.get(_apiEndpoint);
        _apiResult = res.data;
    } catch (err) {
        console.log("err");
    }
    return _apiResult!;
}

export async function getApiDataCors(_apiEndpoint: string) {
    var _apiResult = undefined;
    let config = {
        headers: { "Access-Control-Allow-Origin": "*" },
    };

    try {
        const res = await axios.get(_apiEndpoint, config);
        _apiResult = res.data;
    } catch (err) {
        console.log(err);
    }
    return _apiResult!;
}
