import styled from "styled-components";
import { LIGA2OFFICIAL_PLAYERIMAGEDEFAULT, SOFASCORE_PLAYER_IMAGE, SOFASCORE_TEAM_IMAGE, SOFASCORE__URL } from "../../helpers/Constants";
import { getImgForPlayerLiga2, getSofascoreRatingColor, mediaQuery, stringFormat } from "../../helpers/Helpers";
import ballImg from "../../img/ball.png";
import mvpImg from "../../img/mvp.png";
import redImg from "../../img/redcard.svg";
import subImg from "../../img/sub.png";
import yellowImg from "../../img/yellowcard.svg";
import { CssVariables, Liga2OccurrenceTypeId } from "../../types/CustomTypes";
import { Liga2OccurrenceV2, Liga2PlayerDetailsV2 } from "../../types/Liga2TypesV2";
import { SofascoreTeamOfTheWeekPlayerV2 } from "../../types/SofaScoreTypes";

interface Props {
    playeroftheweek?: SofascoreTeamOfTheWeekPlayerV2 | undefined;
    playermatch?: Liga2PlayerDetailsV2 | undefined;
    imgSize?: number;
    isSub?: boolean;
    isMvp?: boolean;
    borderRadius?: boolean;
    occurences?: Liga2OccurrenceV2[];
}

const Container = styled.div<{ $playerImgSize?: boolean; $isMvp?: boolean }>`
    width: ${(props) => (props.$playerImgSize ? "100%" : "100px")};
    text-align: center;
    padding: ${(props) => (props.$playerImgSize ? "0px" : "0px 5px")};
    position: relative;
    margin: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        ${(props) => (props.$playerImgSize ? "width: 140px;" : null)};
    }

    ${mediaQuery.sm} {
        ${(props) => (props.$playerImgSize ? "width: 120px;" : null)};
    }
`;

const PlayerImageWrapper = styled.div<{ $playerImgSize?: boolean }>`
    height: ${(props) => (props.$playerImgSize ? "100%" : "100px")};
    width: ${(props) => (props.$playerImgSize ? "100%" : "100px")};
    overflow: hidden;
    display: flex;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        width: 140px;
        height: 140px;
    }

    ${mediaQuery.sm} {
        width: 120px;
        height: 120px;
    }
`;

const PlayerImage = styled.div<{ $playerImg: string; $playerImgSize?: string; $borderRadius?: boolean }>`
    background-image: url(${(props) => (props.$playerImg ? props.$playerImg : LIGA2OFFICIAL_PLAYERIMAGEDEFAULT)});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: ${(props) => (props.$playerImgSize ? props.$playerImgSize : "80px")};
    width: ${(props) => (props.$playerImgSize ? props.$playerImgSize : "80px")};
    margin: auto;
    ${(props) => (props.$borderRadius ? "border-radius:50%" : null)};

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        width: 140px;
        height: 140px;
    }

    ${mediaQuery.sm} {
        width: 120px;
        height: 120px;
    }
`;

const TeamImage = styled.div<{ $teamImg: string }>`
    background-image: url(${(props) => props.$teamImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 36px;
    width: 36px;
    margin-left: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 18px;
        height: 18px;
    }
`;

const MvpImage = styled.div<{ $isMvp: boolean }>`
    background-image: url(${mvpImg});
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    height: 40%;
    width: 100%;
    top: 0px;
    right: 0px;
    position: absolute;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const NameWrapper = styled.div<{ $backgroundColor?: boolean; $isMvp?: boolean }>`
    margin: auto;
    font-size: large;
    text-overflow: ellipsis;
    padding: ${(props) => (props.$backgroundColor ? "5px" : "0px")};
    ${(props) => (props.$isMvp ? "max-height: 2lh;" : "height: 2lh;")};

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
        background-color: transparent;
    }
`;

const RatingWrapper = styled.div<{}>`
    width: fit-content;
    display: flex;
    position: absolute;
    top: 0px;
    left: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        top: 10px;
        left: 5px;
        font-size: small;
    }
`;

const TeamWrapper = styled.div<{}>`
    width: fit-content;
    display: flex;
    position: absolute;
    top: 70px;
    right: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        top: 40px;
    }
`;

const Rating = styled.span<{ $rating: number }>`
    border-radius: 25%;
    width: fit-content;
    padding: 5px;
    background-color: ${(props) => getSofascoreRatingColor(props.$rating)};
    border: 2px solid ${CssVariables.black};
    display: flex;
    align-items: center;
    color: ${CssVariables.black};
    font-family: ${CssVariables.font_family_base};
    font-weight: bold;
    font-size: ${CssVariables.sofascore_rating_size};

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 2px;
        font-size: ${CssVariables.font_size_sm};
        border-width: 1px;
    }
`;

const SpanText = styled.span<{ $backgroundColor?: boolean }>`
    margin: auto;
    color: ${(props) => (props.$backgroundColor ? `${CssVariables.text_color}` : `${CssVariables.black}`)};
    font-weight: bold;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${CssVariables.font_size_lg};

    text-shadow: ${(props) =>
        props.$backgroundColor
            ? `1px 0 ${CssVariables.text_shadow}, -1px 0 ${CssVariables.text_shadow}, 0 1px ${CssVariables.text_shadow}, 0 -1px ${CssVariables.text_shadow}, 1px 1px ${CssVariables.text_shadow}, -1px -1px ${CssVariables.text_shadow}, 1px -1px ${CssVariables.text_shadow}, -1px 1px ${CssVariables.text_shadow}`
            : "none"};

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const PlayerSubWrapper = styled.div<{ $playerImgSize?: number }>`
    border: 1px solid black;
    border-radius: 50%;
    width: fit-content;
    height: fit-content;
    bottom: 50px;
    left: ${(props) => (props.$playerImgSize ? "20%" : 0)};
    position: absolute;
    background: ${CssVariables.white};
`;

const PlayerSubImg = styled.div<{ $playerImgSize?: number }>`
    background-image: url(${subImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: ${(props) => (props.$playerImgSize ? props.$playerImgSize + "px" : "30px")};
    width: ${(props) => (props.$playerImgSize ? props.$playerImgSize + "px" : "30px")};
`;

const PlayerYellowWrapper = styled.div<{ $playerImgSize?: number }>`
    width: fit-content;
    height: fit-content;
    top: 0px;
    right: 15px;
    position: absolute;
`;

const PlayerYellowImg = styled.div<{ $playerImgSize?: number }>`
    background-image: url(${yellowImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: ${(props) => (props.$playerImgSize ? props.$playerImgSize + "px" : "30px")};
    width: ${(props) => (props.$playerImgSize ? props.$playerImgSize / 2 + "px" : "15px")};
`;

const PlayerDoubleYellowWrapper = styled.div<{ $playerImgSize?: number }>`
    width: fit-content;
    height: fit-content;
    top: 0px;
    right: 15px;
    position: absolute;
    display: flex;
`;

const PlayerRedWrapper = styled.div<{ $playerImgSize?: number }>`
    width: fit-content;
    height: fit-content;
    top: 0px;
    right: 15px;
    position: absolute;
`;

const PlayerRedImg = styled.div<{ $playerImgSize?: number }>`
    background-image: url(${redImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: ${(props) => (props.$playerImgSize ? props.$playerImgSize + "px" : "30px")};
    width: ${(props) => (props.$playerImgSize ? props.$playerImgSize + "px" : "30px")};
`;

const GoalWrapper = styled.div<{ $playerImgSize?: number }>`
    width: fit-content;
    height: fit-content;
    bottom: 50px;
    right: ${(props) => (props.$playerImgSize ? "20%" : 0)};
    position: absolute;
`;

const GoalImg = styled.div<{ $playerImgSize?: number }>`
    background-image: url(${ballImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: ${(props) => (props.$playerImgSize ? props.$playerImgSize + "px" : "30px")};
    width: ${(props) => (props.$playerImgSize ? props.$playerImgSize + "px" : "30px")};
    border: 2px solid white;
    border-radius: 50%;
    position: relative;
`;

const GoalNumber = styled.span<{}>`
    font-size: ${CssVariables.font_size_xl};
    color: ${CssVariables.white};
    font-weight: bold;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: ${CssVariables.main_color_red};
    border-radius: 50%;
    width: 22px;
    height: 22px;
`;

function getPlayerImg(_playerId: number) {
    const urlSofascorePlayerImage = SOFASCORE__URL + SOFASCORE_PLAYER_IMAGE;
    const urlPlayerImage = stringFormat(urlSofascorePlayerImage, _playerId.toString());
    return urlPlayerImage;
}

function getTeamImg(_teamId: number) {
    const urlSofascoreTeamImage = SOFASCORE__URL + SOFASCORE_TEAM_IMAGE;
    const urlTeamImage = stringFormat(urlSofascoreTeamImage, _teamId.toString());
    return urlTeamImage;
}

export const PlayerComponent: React.FC<Props> = ({ playeroftheweek, playermatch, imgSize, isSub, isMvp, occurences, borderRadius }) => {
    const wasSubbedIn = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Substituição Sai"]);
    const wasSubbedOut = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Substituição Entra"]);
    const hasYellow = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Cartão Amarelo"]);
    const hasDoubleYellow = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Segundo Amarelo"]);
    const hasRed = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Cartão Vermelho"]);
    const hasGoal = occurences && occurences.find((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Golo"]);
    const howManyGoals = occurences && occurences.filter((i) => i.id === playermatch.id && i.occurrenceTypeId === Liga2OccurrenceTypeId["Golo"]).length;

    return (
        <>
            {playeroftheweek ? (
                <Container key={playeroftheweek.id}>
                    <PlayerImageWrapper>
                        <PlayerImage $borderRadius={borderRadius} $playerImg={getPlayerImg(playeroftheweek.id)}></PlayerImage>
                    </PlayerImageWrapper>
                    <NameWrapper>
                        <SpanText>{playeroftheweek.name}</SpanText>
                    </NameWrapper>
                    <RatingWrapper>
                        <Rating $rating={playeroftheweek.rating}>{playeroftheweek.rating}</Rating>
                    </RatingWrapper>
                    <TeamWrapper>
                        <TeamImage $teamImg={getTeamImg(playeroftheweek.teamId)}></TeamImage>
                    </TeamWrapper>
                </Container>
            ) : null}

            {playermatch ? (
                <Container key={playermatch.id} $playerImgSize={imgSize ? true : false} $isMvp={isMvp}>
                    <PlayerImageWrapper $playerImgSize={imgSize ? true : false}>
                        <PlayerImage $playerImgSize={imgSize ? imgSize + "px" : null} $playerImg={getImgForPlayerLiga2(playermatch.mediaUrl, imgSize ? imgSize : 50)}></PlayerImage>
                    </PlayerImageWrapper>
                    <NameWrapper $isMvp={isMvp} $backgroundColor={!isSub}>
                        <SpanText $backgroundColor={!isSub && !isMvp}>{playermatch.name}</SpanText>
                    </NameWrapper>
                    {isMvp ? (
                        <MvpImage $isMvp={isMvp}></MvpImage>
                    ) : (
                        <>
                            {wasSubbedIn || wasSubbedOut ? (
                                <PlayerSubWrapper $playerImgSize={imgSize ? imgSize / 3 : null}>
                                    <PlayerSubImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerSubImg>
                                </PlayerSubWrapper>
                            ) : null}

                            {hasDoubleYellow ? (
                                <PlayerDoubleYellowWrapper $playerImgSize={imgSize ? imgSize / 3 : null}>
                                    <PlayerYellowImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerYellowImg>
                                    <PlayerYellowImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerYellowImg>
                                </PlayerDoubleYellowWrapper>
                            ) : (
                                <>
                                    {hasYellow ? (
                                        <PlayerYellowWrapper $playerImgSize={imgSize ? imgSize / 3 : null}>
                                            <PlayerYellowImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerYellowImg>
                                        </PlayerYellowWrapper>
                                    ) : null}
                                </>
                            )}

                            {hasRed ? (
                                <PlayerRedWrapper $playerImgSize={imgSize ? imgSize / 3 : null}>
                                    <PlayerRedImg $playerImgSize={imgSize ? imgSize / 3 : null}></PlayerRedImg>
                                </PlayerRedWrapper>
                            ) : null}

                            {hasGoal ? (
                                <GoalWrapper $playerImgSize={imgSize ? imgSize / 3 : null}>
                                    <GoalImg $playerImgSize={imgSize ? imgSize / 3 : null}>{howManyGoals > 1 ? <GoalNumber>{howManyGoals}</GoalNumber> : null}</GoalImg>
                                </GoalWrapper>
                            ) : null}
                        </>
                    )}
                </Container>
            ) : null}
        </>
    );
};
