import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getDateInFormat, getTeamFormFromString, mediaQuery } from "../../helpers/Helpers";
import { CssVariables, MediaSize } from "../../types/CustomTypes";
import { Liga2MatchDetailsV2, Liga2MatchInfoV2, Liga2TeamV2 } from "../../types/Liga2TypesV2";
import { RefereeMatchComponent } from "../refs/RefereeMatchComponent";
import { PerformanceComponent } from "../team/PerformanceComponent";
import { TeamComponent } from "../team/TeamComponent";

interface Props {
    matchDetails: Liga2MatchDetailsV2;
    homeTeamId: number;
    awayTeamId: number;
    teamsList: Liga2TeamV2[];

    matchHistory?: Liga2MatchInfoV2[];

    homeTeamPlace?: number;
    awayTeamPlace?: number;
    homeTeamAfterPlace?: number;
    awayTeamAfterPlace?: number;
}

const ComponentContainer = styled.div<{}>`
    display: flex;
    text-align: center;
    width: 100%;

    ${mediaQuery.md} {
        display: block;
    }

    ${mediaQuery.sm} {
        display: block;
    }
`;

const ComponentWrapper = styled.div<{}>`
    display: flex;
    margin: auto;
    width: fit-content;
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const StatsContainer = styled.div<{}>`
    margin: auto;
    width: fit-content;
    ${mediaQuery.md} {
        max-width: 115px;
    }

    ${mediaQuery.sm} {
        width: 100%;
        max-width: 65px;
    }
`;

const StatsWrapper = styled.div<{}>`
    display: block;
    margin: 10px 0;
`;

const StatsLabelContainer = styled.div<{}>`
    margin: auto;
`;

const StatsLabelWrapper = styled.div<{}>``;

const StatsLabelText = styled.span<{}>`
    white-space: nowrap;
    font-size: ${CssVariables.font_size_matchdetails_score};

    ${mediaQuery.lg} {
        font-size: ${CssVariables.font_size_matchdetails_score_lg};
    }

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_matchdetails_score_md};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_matchdetails_score_sm};
    }
`;

const StatsValueContainer = styled.div<{}>`
    display: flex;
`;

const StatsValueWrapper = styled.div<{}>`
    margin: 0px 20px;

    ${mediaQuery.lg} {
        margin: 0px 10px;
    }

    ${mediaQuery.md} {
        margin: 0px 10px;
    }

    ${mediaQuery.sm} {
        margin: 0px 5px;
    }
`;

const StatsValueText = styled.span<{}>`
    display: inline-block;
    font-size: ${CssVariables.font_size_matchdetails_score};

    ${mediaQuery.lg} {
        font-size: ${CssVariables.font_size_matchdetails_score_lg};
    }

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_matchdetails_score_md};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_matchdetails_score_sm};
    }
`;

const DateWrapper = styled.div<{}>`
    display: block;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        word-spacing: 65px;
    }
`;

const DateText = styled.span<{}>`
    display: inline-block;
    font-size: ${CssVariables.font_size_xl};

    ${mediaQuery.lg} {
        font-size: ${CssVariables.font_size_lg};
    }

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_md};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
    }
`;

const TeamWrapper = styled.div<{}>`
    display: inline-block;
    width: fit-content;
    margin: 25px 0px;
    position: relative;
`;

const PlaceWrapper = styled.div<{ $isRight: boolean }>`
    position: absolute;
    display: flex;
    top: 15px;
    ${(props) => (props.$isRight ? "right: 0;" : "left: 0;")};

    margin-inline: auto;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: ${CssVariables.white};
    border: 1px solid ${CssVariables.black};

    ${mediaQuery.lg} {
        width: 30px;
        height: 30px;
        bottom: 40px;
    }

    ${mediaQuery.md} {
        width: 22px;
        height: 22px;
        top: 8px;
    }

    ${mediaQuery.sm} {
        width: 17px;
        height: 17px;
    }
`;

const PlaceNumber = styled.span<{}>`
    margin: auto;
    font-size: ${CssVariables.font_size_xl};

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_lg};
    }

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_md};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
    }
`;

const PlaceAfterWrapper = styled.div<{ $isRight: boolean }>`
    position: absolute;
    display: flex;
    bottom: 42px;
    ${(props) => (props.$isRight ? "right: 0;" : "left: 0;")};

    margin-inline: auto;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: ${CssVariables.white};
    border: 1px solid ${CssVariables.black};

    ${mediaQuery.lg} {
        width: 30px;
        height: 30px;
        bottom: 40px;
    }

    ${mediaQuery.md} {
        width: 22px;
        height: 22px;
        bottom: 32px;
    }

    ${mediaQuery.sm} {
        width: 17px;
        height: 17px;
        bottom: 25px;
    }
`;

const PlaceAfterNumber = styled.span<{}>`
    margin: auto;
    font-size: ${CssVariables.font_size_xl};

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_md};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
    }
`;

const RefereelistWrapper = styled.div<{}>`
    ${mediaQuery.md} {
        width: fit-content;
        margin: auto;
    }

    ${mediaQuery.sm} {
        width: fit-content;
        margin: auto;
    }
`;

export const TeamMatchScoreComponent: React.FC<Props> = ({ matchDetails, awayTeamId, homeTeamId, teamsList, matchHistory, awayTeamPlace, homeTeamPlace, awayTeamAfterPlace, homeTeamAfterPlace }) => {
    const [isMobileL, isMobileLSet] = useState<boolean>(window.innerWidth < MediaSize.lg);
    const [isMobileM, isMobileMSet] = useState<boolean>(window.innerWidth < MediaSize.md);
    const [isMobileS, isMobileSSet] = useState<boolean>(window.innerWidth < MediaSize.sm);
    const [isMobileXS, isMobileXSSet] = useState<boolean>(window.innerWidth < MediaSize.xs);

    useEffect(() => {
        const handleResize = () => {
            isMobileLSet(window.innerWidth < MediaSize.lg);
            isMobileMSet(window.innerWidth < MediaSize.md);
            isMobileSSet(window.innerWidth < MediaSize.sm);
            isMobileXSSet(window.innerWidth < MediaSize.xs);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <ComponentContainer>
            <ComponentWrapper>
                <TeamWrapper>
                    {homeTeamPlace > 0 ? (
                        <PlaceWrapper $isRight={false}>
                            <PlaceNumber>{homeTeamPlace}</PlaceNumber>
                        </PlaceWrapper>
                    ) : null}

                    <PlaceAfterWrapper $isRight={false}>
                        <PlaceAfterNumber>{homeTeamAfterPlace}</PlaceAfterNumber>
                    </PlaceAfterWrapper>
                    {isMobileL ? (
                        <>
                            {isMobileM ? (
                                <>
                                    {isMobileS ? (
                                        <>
                                            {isMobileXS ? (
                                                <TeamComponent team={homeTeamId.toString()} hastext="false" hasclick="false" imgSize={70} teamsList={teamsList}></TeamComponent>
                                            ) : (
                                                <TeamComponent team={homeTeamId.toString()} hastext="false" hasclick="false" imgSize={100} teamsList={teamsList}></TeamComponent>
                                            )}
                                        </>
                                    ) : (
                                        <TeamComponent team={homeTeamId.toString()} hastext="false" hasclick="false" imgSize={120} teamsList={teamsList}></TeamComponent>
                                    )}
                                </>
                            ) : (
                                <TeamComponent team={homeTeamId.toString()} hastext="false" hasclick="false" imgSize={140} teamsList={teamsList}></TeamComponent>
                            )}
                        </>
                    ) : (
                        <>
                            <TeamComponent team={homeTeamId.toString()} hastext="false" hasclick="false" imgSize={200} teamsList={teamsList}></TeamComponent>
                        </>
                    )}

                    <PerformanceComponent performances={getTeamFormFromString(matchDetails.homeTeamForm)}></PerformanceComponent>
                </TeamWrapper>

                <StatsContainer>
                    <DateWrapper>
                        <DateText>{getDateInFormat(matchDetails.matchDate)}</DateText>
                    </DateWrapper>
                    <StatsWrapper key={-1}>
                        <StatsValueContainer>
                            <StatsValueWrapper>
                                <StatsValueText>{matchDetails.homeTeamGoals}</StatsValueText>
                            </StatsValueWrapper>
                            <StatsLabelContainer>
                                <StatsLabelWrapper>
                                    <StatsLabelText>-</StatsLabelText>
                                </StatsLabelWrapper>
                            </StatsLabelContainer>
                            <StatsValueWrapper>
                                <StatsValueText>{matchDetails.awayTeamGoals}</StatsValueText>
                            </StatsValueWrapper>
                        </StatsValueContainer>
                    </StatsWrapper>
                </StatsContainer>

                <TeamWrapper>
                    {awayTeamPlace > 0 ? (
                        <PlaceWrapper $isRight={true}>
                            <PlaceNumber>{awayTeamPlace}</PlaceNumber>
                        </PlaceWrapper>
                    ) : null}
                    <PlaceAfterWrapper $isRight={true}>
                        <PlaceAfterNumber>{awayTeamAfterPlace}</PlaceAfterNumber>
                    </PlaceAfterWrapper>
                    {isMobileL ? (
                        <>
                            {isMobileM ? (
                                <>
                                    {isMobileS ? (
                                        <>
                                            {isMobileXS ? (
                                                <TeamComponent team={awayTeamId.toString()} hastext="false" hasclick="false" imgSize={70} teamsList={teamsList}></TeamComponent>
                                            ) : (
                                                <TeamComponent team={awayTeamId.toString()} hastext="false" hasclick="false" imgSize={100} teamsList={teamsList}></TeamComponent>
                                            )}
                                        </>
                                    ) : (
                                        <TeamComponent team={awayTeamId.toString()} hastext="false" hasclick="false" imgSize={120} teamsList={teamsList}></TeamComponent>
                                    )}
                                </>
                            ) : (
                                <TeamComponent team={awayTeamId.toString()} hastext="false" hasclick="false" imgSize={140} teamsList={teamsList}></TeamComponent>
                            )}
                        </>
                    ) : (
                        <>
                            <TeamComponent team={awayTeamId.toString()} hastext="false" hasclick="false" imgSize={200} teamsList={teamsList}></TeamComponent>
                        </>
                    )}

                    <PerformanceComponent performances={getTeamFormFromString(matchDetails.awayTeamForm)}></PerformanceComponent>
                </TeamWrapper>
            </ComponentWrapper>
            <RefereelistWrapper>
                <RefereeMatchComponent refMatch={matchDetails.refs}></RefereeMatchComponent>
            </RefereelistWrapper>
        </ComponentContainer>
    );
};
