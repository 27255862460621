import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { MediaSize } from "../../types/CustomTypes";
import { Liga2NominationsV2, Liga2TeamV2 } from "../../types/Liga2TypesV2";
import { MatchComponent } from "../league/MatchComponent";
import { RefereeTeamComponent } from "./RefereeTeamComponent";

interface Props {
    refList: Liga2NominationsV2[];
    teamsList: Liga2TeamV2[];
}

const Container = styled.div<{}>`
    width: fit-content;
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const Border = styled.div<{}>`
    background-color: #fff;
    border: 1px solid #0c785e;
    margin: 15px;
`;

const Wrapper = styled.div<{}>`
    display: flex;
    align-items: center;
`;

// #region Responsive
const ContainerResp = styled.div<{}>``;
const BorderResp = styled.div<{}>`
    background-color: #fff;
    border: 1px solid #0c785e;
    margin: 15px 5px;
`;

const WrapperResp = styled.div<{}>`
    width: fit-content;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 100%;
        text-align: center;
    }
`;

// #endregion

export const MatchListRefereesComponent: React.FC<Props> = ({ refList, teamsList }) => {
    const [pageW, pageWSet] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            pageWSet(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <>
            {pageW < MediaSize.md ? (
                <>
                    <ContainerResp>
                        {refList && refList.length > 0
                            ? refList?.map((refs, index) => {
                                  return (
                                      <BorderResp key={index}>
                                          <WrapperResp>
                                              <MatchComponent imgSize={30} noBorder={true} match={refs.match} teamsList={teamsList}></MatchComponent>
                                              <RefereeTeamComponent refs={refs}></RefereeTeamComponent>
                                          </WrapperResp>
                                      </BorderResp>
                                  );
                              })
                            : null}
                    </ContainerResp>
                </>
            ) : (
                <Container>
                    {refList && refList.length > 0
                        ? refList?.map((refs, index) => {
                              return (
                                  <Border key={index}>
                                      <Wrapper>
                                          <MatchComponent imgSize={50} noBorder={true} match={refs.match} teamsList={teamsList}></MatchComponent>
                                          <RefereeTeamComponent refs={refs}></RefereeTeamComponent>
                                      </Wrapper>
                                  </Border>
                              );
                          })
                        : null}
                </Container>
            )}
        </>
    );
};
