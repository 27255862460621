import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import fieldImg from "../../img/football_field_new1.png";
import { MediaSize } from "../../types/CustomTypes";
import { SofascorePlayerPosition, SofascoreTeamOfTheWeekPlayerV2, SofascoreTeamOfTheWeekV2 } from "../../types/SofaScoreTypes";
import { SofascorePlayerComponent } from "../player/SofascorePlayerComponent";

interface Props {
    playerlist: SofascoreTeamOfTheWeekV2;
}

const ComponentContainer = styled.div<{}>`
    display: block;
    margin: 10px;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const ComponentWrapper = styled.div<{}>`
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const FieldContainer = styled.div<{}>`
    width: 600px;
    height: 900px;
    background-image: url(${fieldImg});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    margin: auto;

    ${mediaQuery.md} {
        width: 460px;
        height: 690px;
    }

    ${mediaQuery.sm} {
        width: 320px;
        height: 480px;
    }
`;

const FieldWrapper = styled.div<{}>`
    margin: auto;
    height: 100%;
    width: calc(100% - 14px);

    ${mediaQuery.md} {
        width: calc(100% - 14px);
    }

    ${mediaQuery.sm} {
        width: calc(100% - 14px);
    }
`;

const PositionWrapper = styled.div<{}>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: auto;
    height: 23%;
`;

function getGK(playerlist: SofascoreTeamOfTheWeekV2): SofascoreTeamOfTheWeekPlayerV2 | undefined {
    const _gk = playerlist.players.find((g) => g.position === SofascorePlayerPosition.goalkeeper);
    return _gk;
}

function getDF(playerlist: SofascoreTeamOfTheWeekV2): SofascoreTeamOfTheWeekPlayerV2[] {
    const _df = playerlist.players.filter((g) => g.position === SofascorePlayerPosition.defender);
    return _df;
}

function getMF(playerlist: SofascoreTeamOfTheWeekV2): SofascoreTeamOfTheWeekPlayerV2[] {
    const _mf = playerlist.players.filter((g) => g.position === SofascorePlayerPosition.midfielder);
    return _mf;
}

function getFW(playerlist: SofascoreTeamOfTheWeekV2): SofascoreTeamOfTheWeekPlayerV2[] {
    const _fw = playerlist.players.filter((g) => g.position === SofascorePlayerPosition.forward);
    return _fw;
}

export const SofascoreTeamComponent: React.FC<Props> = ({ playerlist }) => {
    const [isMobileM, isMobileMSet] = useState<boolean>(window.innerWidth < MediaSize.md);
    const [isMobileS, isMobileSSet] = useState<boolean>(window.innerWidth < MediaSize.sm);
    const [isMobileXS, isMobileXSSet] = useState<boolean>(window.innerWidth < MediaSize.xs);

    useEffect(() => {
        const handleResize = () => {
            isMobileMSet(window.innerWidth < MediaSize.md);
            isMobileSSet(window.innerWidth < MediaSize.sm);
            isMobileXSSet(window.innerWidth < MediaSize.xs);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <ComponentContainer>
                <ComponentWrapper>
                    {isMobileM ? (
                        <>
                            {isMobileS ? (
                                <>
                                    {isMobileXS ? (
                                        <>
                                            <FieldContainer>
                                                <FieldWrapper>
                                                    <PositionWrapper key="GK">
                                                        <SofascorePlayerComponent playeroftheweek={getGK(playerlist)}></SofascorePlayerComponent>
                                                    </PositionWrapper>
                                                    <PositionWrapper key="DF">
                                                        {getDF(playerlist)
                                                            ? getDF(playerlist).map((_df, index) => {
                                                                  return <SofascorePlayerComponent key={index} playeroftheweek={_df}></SofascorePlayerComponent>;
                                                              })
                                                            : null}
                                                    </PositionWrapper>
                                                    <PositionWrapper key="MF">
                                                        {getMF(playerlist)
                                                            ? getMF(playerlist).map((_mf, index) => {
                                                                  return <SofascorePlayerComponent key={index} playeroftheweek={_mf}></SofascorePlayerComponent>;
                                                              })
                                                            : null}
                                                    </PositionWrapper>
                                                    <PositionWrapper key="FW">
                                                        {getFW(playerlist)
                                                            ? getFW(playerlist).map((_fw, index) => {
                                                                  return <SofascorePlayerComponent key={index} playeroftheweek={_fw}></SofascorePlayerComponent>;
                                                              })
                                                            : null}
                                                    </PositionWrapper>
                                                </FieldWrapper>
                                            </FieldContainer>
                                        </>
                                    ) : (
                                        <>
                                            <FieldContainer>
                                                <FieldWrapper>
                                                    <PositionWrapper key="GK">
                                                        <SofascorePlayerComponent playeroftheweek={getGK(playerlist)}></SofascorePlayerComponent>
                                                    </PositionWrapper>
                                                    <PositionWrapper key="DF">
                                                        {getDF(playerlist)
                                                            ? getDF(playerlist).map((_df, index) => {
                                                                  return <SofascorePlayerComponent key={index} playeroftheweek={_df}></SofascorePlayerComponent>;
                                                              })
                                                            : null}
                                                    </PositionWrapper>
                                                    <PositionWrapper key="MF">
                                                        {getMF(playerlist)
                                                            ? getMF(playerlist).map((_mf, index) => {
                                                                  return <SofascorePlayerComponent key={index} playeroftheweek={_mf}></SofascorePlayerComponent>;
                                                              })
                                                            : null}
                                                    </PositionWrapper>
                                                    <PositionWrapper key="FW">
                                                        {getFW(playerlist)
                                                            ? getFW(playerlist).map((_fw, index) => {
                                                                  return <SofascorePlayerComponent key={index} playeroftheweek={_fw}></SofascorePlayerComponent>;
                                                              })
                                                            : null}
                                                    </PositionWrapper>
                                                </FieldWrapper>
                                            </FieldContainer>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <FieldContainer>
                                        <FieldWrapper>
                                            <PositionWrapper key="GK">
                                                <SofascorePlayerComponent playeroftheweek={getGK(playerlist)}></SofascorePlayerComponent>
                                            </PositionWrapper>
                                            <PositionWrapper key="DF">
                                                {getDF(playerlist)
                                                    ? getDF(playerlist).map((_df, index) => {
                                                          return <SofascorePlayerComponent key={index} playeroftheweek={_df}></SofascorePlayerComponent>;
                                                      })
                                                    : null}
                                            </PositionWrapper>
                                            <PositionWrapper key="MF">
                                                {getMF(playerlist)
                                                    ? getMF(playerlist).map((_mf, index) => {
                                                          return <SofascorePlayerComponent key={index} playeroftheweek={_mf}></SofascorePlayerComponent>;
                                                      })
                                                    : null}
                                            </PositionWrapper>
                                            <PositionWrapper key="FW">
                                                {getFW(playerlist)
                                                    ? getFW(playerlist).map((_fw, index) => {
                                                          return <SofascorePlayerComponent key={index} playeroftheweek={_fw}></SofascorePlayerComponent>;
                                                      })
                                                    : null}
                                            </PositionWrapper>
                                        </FieldWrapper>
                                    </FieldContainer>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <FieldContainer>
                                <FieldWrapper>
                                    <PositionWrapper key="GK">
                                        <SofascorePlayerComponent playeroftheweek={getGK(playerlist)}></SofascorePlayerComponent>
                                    </PositionWrapper>
                                    <PositionWrapper key="DF">
                                        {getDF(playerlist)
                                            ? getDF(playerlist).map((_df, index) => {
                                                  return <SofascorePlayerComponent key={index} playeroftheweek={_df}></SofascorePlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                    <PositionWrapper key="MF">
                                        {getMF(playerlist)
                                            ? getMF(playerlist).map((_mf, index) => {
                                                  return <SofascorePlayerComponent key={index} playeroftheweek={_mf}></SofascorePlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                    <PositionWrapper key="FW">
                                        {getFW(playerlist)
                                            ? getFW(playerlist).map((_fw, index) => {
                                                  return <SofascorePlayerComponent key={index} playeroftheweek={_fw}></SofascorePlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                </FieldWrapper>
                            </FieldContainer>
                        </>
                    )}
                </ComponentWrapper>
            </ComponentContainer>
        </>
    );
};
