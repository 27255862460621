import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { CssVariables } from "../../types/CustomTypes";
import { Liga2MatchDetailsV2, Liga2TeamV2 } from "../../types/Liga2TypesV2";

interface Props {
    matchDetails: Liga2MatchDetailsV2;
    homeTeamId: number;
    awayTeamId: number;
    teamsList: Liga2TeamV2[];
}

const ComponentContainer = styled.div<{}>`
    display: flex;
    text-align: center;
    width: fit-content;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_md};
    }
`;
const ComponentWrapper = styled.div<{}>`
    display: flex;
    margin: auto;
    width: fit-content;
`;

const StatsContainer = styled.div<{}>`
    margin: auto;
    padding: 20px 0;
`;

const StatsWrapper = styled.div<{}>`
    display: block;
    margin: 10px 0;
`;

const StatsLabelContainer = styled.div<{}>`
    margin: auto;
`;

const StatsLabelWrapper = styled.div<{}>``;

const StatsLabelText = styled.span<{}>`
    white-space: nowrap;
`;

const StatsValueContainer = styled.div<{}>`
    display: flex;
`;

const StatsValueWrapper = styled.div<{}>`
    margin: 0px 50px;
`;

const StatsValueText = styled.span<{}>`
    display: inline-block;
`;

const TeamWrapper = styled.div<{}>`
    display: inline-block;
`;

export const TeamMatchStatsComponent: React.FC<Props> = ({ matchDetails, awayTeamId, homeTeamId, teamsList }) => {
    return (
        <ComponentContainer>
            <ComponentWrapper>
                <StatsContainer>
                    {matchDetails.statistics.map((_statistic, index) => {
                        return (
                            <StatsWrapper key={index}>
                                <StatsValueContainer>
                                    <StatsValueWrapper>
                                        <StatsValueText>{_statistic.homeTeamValue}</StatsValueText>
                                    </StatsValueWrapper>
                                    <StatsLabelContainer>
                                        <StatsLabelWrapper>
                                            <StatsLabelText>{_statistic.statisticName}</StatsLabelText>
                                        </StatsLabelWrapper>
                                    </StatsLabelContainer>
                                    <StatsValueWrapper>
                                        <StatsValueText>{_statistic.awayTeamValue}</StatsValueText>
                                    </StatsValueWrapper>
                                </StatsValueContainer>
                            </StatsWrapper>
                        );
                    })}
                </StatsContainer>
            </ComponentWrapper>
        </ComponentContainer>
    );
};
