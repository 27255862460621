import styled from "styled-components";
import { LIGA2OFFICIAL_PLAYERIMAGEDEFAULT, SOFASCORE_PLAYER_IMAGE, SOFASCORE_TEAM_IMAGE, SOFASCORE__URL } from "../../helpers/Constants";
import { getSofascoreRatingColor, mediaQuery, stringFormat } from "../../helpers/Helpers";
import { CssVariables } from "../../types/CustomTypes";
import { SofascoreTeamOfTheWeekPlayerV2 } from "../../types/SofaScoreTypes";

interface Props {
    playeroftheweek?: SofascoreTeamOfTheWeekPlayerV2 | undefined;
}

const Container = styled.div<{}>`
    width: 85px;
    text-align: center;
    padding: "0px 5px";
    position: relative;
    margin: auto;

    ${mediaQuery.md} {
        width: 75px;
    }

    ${mediaQuery.sm} {
        width: 50px;
    }
`;

const PlayerImageWrapper = styled.div<{}>`
    height: 85px;
    width: 85px;
    overflow: hidden;
    display: flex;

    ${mediaQuery.md} {
        width: 75px;
        height: 75px;
    }

    ${mediaQuery.sm} {
        width: 50px;
        height: 50px;
    }
`;

const PlayerImage = styled.div<{ $playerImg: string }>`
    background-image: url(${(props) => (props.$playerImg ? props.$playerImg : LIGA2OFFICIAL_PLAYERIMAGEDEFAULT)});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 85px;
    width: 85px;
    margin: auto;
    border-radius: 50%;

    ${mediaQuery.md} {
        width: 75px;
        height: 75px;
    }

    ${mediaQuery.sm} {
        width: 50px;
        height: 50px;
    }
`;

const TeamImage = styled.div<{ $teamImg: string }>`
    background-image: url(${(props) => props.$teamImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 30px;
    width: 30px;
    margin-left: auto;

    ${mediaQuery.md} {
        width: 27px;
        height: 27px;
    }

    ${mediaQuery.sm} {
        width: 18px;
        height: 18px;
    }
`;

const NameWrapper = styled.div<{}>`
    margin: auto;
    font-size: large;
    text-overflow: ellipsis;
    padding: "0px";

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        background-color: transparent;
    }
`;

const RatingWrapper = styled.div<{}>`
    width: fit-content;
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const TeamWrapper = styled.div<{}>`
    width: fit-content;
    display: flex;
    position: absolute;
    bottom: 36px;
    right: 0px;

    ${mediaQuery.md} {
        bottom: 30px;
        right: 0px;
    }

    ${mediaQuery.sm} {
        bottom: 20px;
        right: 0px;
    }
`;

const Rating = styled.span<{ $rating: number }>`
    border-radius: 25%;
    width: fit-content;
    background-color: ${(props) => getSofascoreRatingColor(props.$rating)};
    border: 2px solid ${CssVariables.black};
    display: flex;
    align-items: center;
    color: ${CssVariables.black};
    font-family: ${CssVariables.font_family_base};
    font-weight: bold;
    padding: 2px;
    font-size: ${CssVariables.font_size_xl};
    border-width: 1px;

    ${mediaQuery.md} {
        padding: 2px;
        font-size: ${CssVariables.font_size_sm};
        border-width: 1px;
    }

    ${mediaQuery.sm} {
        padding: 2px;
        font-size: ${CssVariables.font_size_xxs};
        border-width: 1px;
    }
`;

const SpanText = styled.span<{}>`
    margin: auto;
    color: ${CssVariables.text_color};
    font-weight: bold;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${CssVariables.font_size_lg};
    height: 2lh;
    text-shadow: 1px 0 ${CssVariables.text_shadow}, -1px 0 ${CssVariables.text_shadow}, 0 1px ${CssVariables.text_shadow}, 0 -1px ${CssVariables.text_shadow}, 1px 1px ${CssVariables.text_shadow},
        -1px -1px ${CssVariables.text_shadow}, 1px -1px ${CssVariables.text_shadow}, -1px 1px ${CssVariables.text_shadow};

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_sm};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_xxs};
    }
`;

function getPlayerImg(_playerId: number) {
    const urlSofascorePlayerImage = SOFASCORE__URL + SOFASCORE_PLAYER_IMAGE;
    const urlPlayerImage = stringFormat(urlSofascorePlayerImage, _playerId.toString());
    return urlPlayerImage;
}

function getTeamImg(_teamId: number) {
    const urlSofascoreTeamImage = SOFASCORE__URL + SOFASCORE_TEAM_IMAGE;
    const urlTeamImage = stringFormat(urlSofascoreTeamImage, _teamId.toString());
    return urlTeamImage;
}

export const SofascorePlayerComponent: React.FC<Props> = ({ playeroftheweek }) => {
    return (
        <>
            {playeroftheweek ? (
                <Container key={playeroftheweek.id}>
                    <PlayerImageWrapper>
                        <PlayerImage $playerImg={getPlayerImg(playeroftheweek.id)}></PlayerImage>
                    </PlayerImageWrapper>
                    <NameWrapper>
                        <SpanText>{playeroftheweek.name}</SpanText>
                    </NameWrapper>
                    <RatingWrapper>
                        <Rating $rating={playeroftheweek.rating}>{playeroftheweek.rating}</Rating>
                    </RatingWrapper>
                    <TeamWrapper>
                        <TeamImage $teamImg={getTeamImg(playeroftheweek.teamId)}></TeamImage>
                    </TeamWrapper>
                </Container>
            ) : null}
        </>
    );
};
