import { Liga2TeamV2 } from "./Liga2TypesV2";

export type Temp = {};

export interface DropdownItems {
    label: string;
    value: number;
}

export enum CustomTime {
    Day = "D",
    Month = "M",
    Year = "Y",
}

export enum CustomWeekday {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
}

export enum MediaSize {
    xs = 480,
    sm = 640,
    md = 768,
    lg = 1024,
    xl = 1280,
}

export const CssVariables = {
    font_family_base: "Arial, sans-serif",
    font_family_mono: "'Courier New', monospace",
    main_color_green: "#0c785e",
    main_color_lightgreen: "#6db7a3",
    main_color_darkgreen: "#0c3c1c",
    main_color_red: "#e01119",
    field_color: "#186c14",
    white: "#ffffff",
    black: "#000000",
    grey: "#767e8f",
    performance_red: "#FAA0A0",
    performance_green: "#7CFC00",
    performance_grey: "#E5E4E2",
    sofascore_rating_red: "#eb1c23",
    sofascore_rating_orange: "#ff7b00",
    sofascore_rating_yellow: "#f4bb00",
    sofascore_rating_yellowgreen: "#c0cc00",
    sofascore_rating_green: "#5cb400",
    sofascore_rating_blue: "#009e9e",
    sofascore_rating_size: "20px",
    ref_height: 200,
    font_size_xxs: "8px",
    font_size_xs: "10px",
    font_size_sm: "12px",
    font_size_md: "13px",
    font_size_lg: "15px",
    font_size_xl: "18px",
    font_size_matchdetails_score: "80px",
    font_size_matchdetails_score_lg: "60px",
    font_size_matchdetails_score_md: "50px",
    font_size_matchdetails_score_sm: "30px",
    text_color: "#ffffff",
    text_shadow: "#000000",
};

export enum TeamDetailsTab {
    squad = 0,
    matches = 1,
    nextmatch = 2,
    lastmatch = 3,
    stats = 4,
}

export enum PerformanceResult {
    win = "V",
    draw = "E",
    loss = "D",
}

export interface PerformanceComponentInput {
    team: Liga2TeamV2;
    result: PerformanceResult;
}

export enum Liga2RefType {
    R = "arbitro",
    O = "observador",
    D = "delegado",
}

type Liga2SeasonsType = [number, string];

export const Liga2Seasons: Liga2SeasonsType[] = [
    [20242025, "2024 - 2025"],
    [20232024, "2023 - 2024"],
    [20222023, "2022 - 2023"],
    [20212022, "2021 - 2022"],
    [20202021, "2020 - 2021"],
    [20192020, "2019 - 2020"],
    [20182019, "2018 - 2019"],
    [20172018, "2017 - 2018"],
    [20162017, "2016 - 2017"],
    [20152016, "2015 - 2016"],
    [20142015, "2014 - 2015"],
    [20132014, "2013 - 2014"],
    [20122013, "2012 - 2013"],
    [20112012, "2011 - 2012"],
    [20102011, "2010 - 2011"],
    [20092010, "2009 - 2010"],
];

export enum Liga2ImgSize {
    img35 = "img35",
    img50 = "img50",
    img120 = "img120",
    img200 = "img200",
}

export interface Liga2CalendarType {
    team: string;
    calendarDetails: Liga2CalendarDetails[];
}

export type Liga2CalendarDetails = {
    date: Date;
    team: Liga2TeamV2;
    opponent: Liga2TeamV2;
    location?: string;
    name: string;
    dateIsDefined: boolean;
};

export enum Liga2TeamStatisticsTypeId {
    "Assistências" = 3,
    "Pontapés De Baliza" = 4,
    "Remates" = 5,
    "Posse de Bola" = 29,
    "Faltas" = 32,
    "Cantos" = 34,
    "Cruzamentos" = 35,
    "Cruzamentos Da Esquerda" = 37,
    "Cruzamentos Da Direita" = 38,
    "Cruzamentos Da Esquerda Com Sucesso" = 39,
    "Cruzamentos Da Direita Com Sucesso" = 40,
    "Cartões Amarelos" = 49,
    "Cartões Vermelhos" = 50,
    "Defesas Completas" = 55,
    "Defesas Incompletas" = 56,
    "Recuperações De Bola" = 139,
    "Bolas Perdidas" = 140,
    "Golos" = 142,
    "Penáltis" = 144,
    "Foras-De-Jogo" = 145,
    "Golos De Penálti" = 146,
    "Auto-Golos" = 147,
    "Penaltis Defendidos" = 303,
    "Defesas" = 9998,
    "Golos Sofridos" = 10102,
    "Remates Fora Da Área" = 10103,
    "Remates Dentro Da Área" = 10104,
    "Defesas Perigosas" = 10105,
    "Defesas Estáveis" = 10106,
    "Defesas Incompletas Estáveis" = 10108,
    "Defesas Incompletas Perigosas" = 10109,
    "Defesas Completas Estáveis" = 10110,
    "Defesas Completas Perigosas" = 10111,
    "Duplos Amarelos" = 10138,
    // "Cartões Amarelos" = 10139,
    "Remates Aos Ferros" = 10144,
    "Remates Para Fora" = 10145,
    "Remates Interceptados" = 10146,
    "Outros Golos Fora Da Área" = 10151,
    "Golos " = 10152,
    "Outros Golos Dentro Da Área" = 10153,
    "Golos De Pé Direito Fora Da Área" = 10154,
    "Golos De Pé Direito" = 10155,
    "Golos De Pé Direito Dentro Da Área" = 10156,
    "Golos De Cabeça Fora Da Área" = 10157,
    "Golos De Cabeça" = 10158,
    "Golos De Cabeça Dentro Da Área" = 10159,
    "Golos De Pé Esquerdo Fora Da Área" = 10160,
    "Golos De Pé Esquerdo" = 10161,
    "Golos De Pé Esquerdo Dentro Da Área" = 10162,
    "Remates À Baliza" = 999994,
    "Golos Fora Da Área" = 999997,
    "Golos Dentro Da Área" = 999998,
    "Cartões Totais" = 999999,
}

export enum Liga2OccurrenceTypeId {
    "Substituição Entra" = 0,
    "Substituição Sai" = 1,
    "Cartão Amarelo" = 2,
    "Cartão Vermelho" = 3,

    "Golo" = 4,
    "Autogolo" = 5,
    "Segundo Amarelo" = 6,
}

export enum Liga2FormatsV2 {
    default = "",
    large = "large_",
    medium = "medium_",
    small = "small_",
    thumbnail = "thumbnail_",
    xlarge = "xlarge_",
    xsmall = "xsmall_",
}

export enum Liga2TeamFormatsV2 {
    default = "",
    large = "large_",
    medium = "medium_",
    small = "small_",
    thumbnail = "thumbnail_",
}

export enum Liga2TeamWhiteFormatsV2 {
    default = "",
    small = "small_",
    thumbnail = "thumbnail_",
}

export enum Liga2CountryISO {
    "AF" = "Afghanistan",
    "AL" = "Albania",
    "DZ" = "Algeria",
    "AS" = "American Samoa",
    "AD" = "Andorra",
    "AO" = "Angola",
    "AI" = "Anguilla",
    "AQ" = "Antarctica",
    "AG" = "Antigua and Barbuda",
    "AR" = "Argentina",
    "AM" = "Armenia",
    "AW" = "Aruba",
    "AU" = "Australia",
    "AT" = "Austria",
    "AZ" = "Azerbaijan",
    "BS" = "Bahamas",
    "BH" = "Bahrain",
    "BD" = "Bangladesh",
    "BB" = "Barbados",
    "BY" = "Belarus",
    "BE" = "Belgium",
    "BZ" = "Belize",
    "BJ" = "Benin",
    "BM" = "Bermuda",
    "BT" = "Bhutan",
    "BO" = "Bolivia (Plurinational State of)",
    "BQ" = "Bonaire, Sint Eustatius and Saba",
    "BA" = "Bosnia and Herzegovina",
    "BW" = "Botswana",
    "BV" = "Bouvet Island",
    "BR" = "Brazil",
    "IO" = "British Indian Ocean Territory",
    "BN" = "Brunei Darussalam",
    "BG" = "Bulgaria",
    "BF" = "Burkina Faso",
    "BI" = "Burundi",
    "CV" = "Cabo Verde",
    "KH" = "Cambodia",
    "CM" = "Cameroon",
    "CA" = "Canada",
    "KY" = "Cayman Islands",
    "CF" = "Central African Republic",
    "TD" = "Chad",
    "CL" = "Chile",
    "CN" = "China",
    "CX" = "Christmas Island",
    "CC" = "Cocos (Keeling) Islands",
    "CO" = "Colombia",
    "KM" = "Comoros",
    "CD" = "Congo (the Democratic Republic of the)",
    "CG" = "Congo",
    "CK" = "Cook Islands",
    "CR" = "Costa Rica",
    "HR" = "Croatia",
    "CU" = "Cuba",
    "CW" = "Curaçao",
    "CY" = "Cyprus",
    "CZ" = "Czechia",
    "CI" = "Côte d'Ivoire",
    "DK" = "Denmark",
    "DJ" = "Djibouti",
    "DM" = "Dominica",
    "DO" = "Dominican Republic",
    "EC" = "Ecuador",
    "EG" = "Egypt",
    "SV" = "El Salvador",
    "GQ" = "Equatorial Guinea",
    "ER" = "Eritrea",
    "EE" = "Estonia",
    "SZ" = "Eswatini",
    "ET" = "Ethiopia",
    "FK" = "Falkland Islands [Malvinas]",
    "FO" = "Faroe Islands",
    "FJ" = "Fiji",
    "FI" = "Finland",
    "FR" = "France",
    "GF" = "French Guiana",
    "PF" = "French Polynesia",
    "TF" = "French Southern Territories",
    "GA" = "Gabon",
    "GM" = "Gambia",
    "GE" = "Georgia",
    "DE" = "Germany",
    "GH" = "Ghana",
    "GI" = "Gibraltar",
    "GR" = "Greece",
    "GL" = "Greenland",
    "GD" = "Grenada",
    "GP" = "Guadeloupe",
    "GU" = "Guam",
    "GT" = "Guatemala",
    "GG" = "Guernsey",
    "GN" = "Guinea",
    "GW" = "Guinea-Bissau",
    "GY" = "Guyana",
    "HT" = "Haiti",
    "HM" = "Heard Island and McDonald Islands",
    "VA" = "Holy See",
    "HN" = "Honduras",
    "HK" = "Hong Kong",
    "HU" = "Hungary",
    "IS" = "Iceland",
    "IN" = "India",
    "ID" = "Indonesia",
    "IR" = "Iran (Islamic Republic of)",
    "IQ" = "Iraq",
    "IE" = "Ireland",
    "IM" = "Isle of Man",
    "IL" = "Israel",
    "IT" = "Italy",
    "JM" = "Jamaica",
    "JP" = "Japan",
    "JE" = "Jersey",
    "JO" = "Jordan",
    "KZ" = "Kazakhstan",
    "KE" = "Kenya",
    "KI" = "Kiribati",
    "KP" = "Korea (the Democratic People's Republic of)",
    "KR" = "Korea (the Republic of)",
    "KW" = "Kuwait",
    "KG" = "Kyrgyzstan",
    "LA" = "Lao People's Democratic Republic",
    "LV" = "Latvia",
    "LB" = "Lebanon",
    "LS" = "Lesotho",
    "LR" = "Liberia",
    "LY" = "Libya",
    "LI" = "Liechtenstein",
    "LT" = "Lithuania",
    "LU" = "Luxembourg",
    "MO" = "Macao",
    "MG" = "Madagascar",
    "MW" = "Malawi",
    "MY" = "Malaysia",
    "MV" = "Maldives",
    "ML" = "Mali",
    "MT" = "Malta",
    "MH" = "Marshall Islands",
    "MQ" = "Martinique",
    "MR" = "Mauritania",
    "MU" = "Mauritius",
    "YT" = "Mayotte",
    "MX" = "Mexico",
    "FM" = "Micronesia (Federated States of)",
    "MD" = "Moldova (the Republic of)",
    "MC" = "Monaco",
    "MN" = "Mongolia",
    "ME" = "Montenegro",
    "MS" = "Montserrat",
    "MA" = "Morocco",
    "MZ" = "Mozambique",
    "MM" = "Myanmar",
    "NA" = "Namibia",
    "NR" = "Nauru",
    "NP" = "Nepal",
    "NL" = "Netherlands",
    "NC" = "New Caledonia",
    "NZ" = "New Zealand",
    "NI" = "Nicaragua",
    "NE" = "Niger",
    "NG" = "Nigeria",
    "NU" = "Niue",
    "NF" = "Norfolk Island",
    "MP" = "Northern Mariana Islands",
    "NO" = "Norway",
    "OM" = "Oman",
    "PK" = "Pakistan",
    "PW" = "Palau",
    "PS" = "Palestine, State of",
    "PA" = "Panama",
    "PG" = "Papua New Guinea",
    "PY" = "Paraguay",
    "PE" = "Peru",
    "PH" = "Philippines",
    "PN" = "Pitcairn",
    "PL" = "Poland",
    "PT" = "Portugal",
    "PR" = "Puerto Rico",
    "QA" = "Qatar",
    "MK" = "Republic of North Macedonia",
    "RO" = "Romania",
    "RU" = "Russian Federation",
    "RW" = "Rwanda",
    "RE" = "Réunion",
    "BL" = "Saint Barthélemy",
    "SH" = "Saint Helena, Ascension and Tristan da Cunha",
    "KN" = "Saint Kitts and Nevis",
    "LC" = "Saint Lucia",
    "MF" = "Saint Martin (French part)",
    "PM" = "Saint Pierre and Miquelon",
    "VC" = "Saint Vincent and the Grenadines",
    "WS" = "Samoa",
    "SM" = "San Marino",
    "ST" = "Sao Tome and Principe",
    "SA" = "Saudi Arabia",
    "SN" = "Senegal",
    "RS" = "Serbia",
    "SC" = "Seychelles",
    "SL" = "Sierra Leone",
    "SG" = "Singapore",
    "SX" = "Sint Maarten (Dutch part)",
    "SK" = "Slovakia",
    "SI" = "Slovenia",
    "SB" = "Solomon Islands",
    "SO" = "Somalia",
    "ZA" = "South Africa",
    "GS" = "South Georgia and the South Sandwich Islands",
    "SS" = "South Sudan",
    "ES" = "Spain",
    "LK" = "Sri Lanka",
    "SD" = "Sudan",
    "SR" = "Suriname",
    "SJ" = "Svalbard and Jan Mayen",
    "SE" = "Sweden",
    "CH" = "Switzerland",
    "SY" = "Syrian Arab Republic",
    "TW" = "Taiwan (Province of China)",
    "TJ" = "Tajikistan",
    "TZ" = "Tanzania, United Republic of",
    "TH" = "Thailand",
    "TL" = "Timor-Leste",
    "TG" = "Togo",
    "TK" = "Tokelau",
    "TO" = "Tonga",
    "TT" = "Trinidad and Tobago",
    "TN" = "Tunisia",
    "TR" = "Turkey",
    "TM" = "Turkmenistan",
    "TC" = "Turks and Caicos Islands",
    "TV" = "Tuvalu",
    "UG" = "Uganda",
    "UA" = "Ukraine",
    "AE" = "United Arab Emirates",
    "GB" = "United Kingdom of Great Britain and Northern Ireland",
    "UM" = "United States Minor Outlying Islands",
    "US" = "United States of America",
    "UY" = "Uruguay",
    "UZ" = "Uzbekistan",
    "VU" = "Vanuatu",
    "VE" = "Venezuela (Bolivarian Republic of)",
    "VN" = "Viet Nam",
    "VG" = "Virgin Islands (British)",
    "VI" = "Virgin Islands (U.S.)",
    "WF" = "Wallis and Futuna",
    "EH" = "Western Sahara",
    "YE" = "Yemen",
    "ZM" = "Zambia",
    "ZW" = "Zimbabwe",
    "AX" = "Åland Islands",
}

export interface Liga2RechartType {
    value: number;
    label: string;
}

export interface Liga2WeeklyLeadersRechartType {
    [key: string]: number;
}

export interface Liga2MatchH2HType {
    matchDate: Date;
    homeTeamGoals?: number;
    awayTeamGoals?: number;
    homeTeamId: number;
    awayTeamId: number;
}

export enum Liga2RefTypeV2 {
    R = "arbitro",
    O = "observador",
    D = "delegado",
    V = "var",
    A = "assistente",
    Q = "quarto",
}
