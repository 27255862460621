import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getPerformanceColor, mediaQuery } from "../../helpers/Helpers";
import { CssVariables, MediaSize, PerformanceResult } from "../../types/CustomTypes";

interface Props {
    performances: PerformanceResult[];
}

const ComponentContainer = styled.div<{}>`
    width: 100%;
    display: flex;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
    }
`;

const MatchContainer = styled.div<{ $result: PerformanceResult }>`
    background-color: ${(props) => getPerformanceColor(props.$result)};
    border-radius: 50%;
    display: flex;
    border: 1px solid ${CssVariables.black};
    margin: auto;
    width: 25px;
    height: 25px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        width: 18px;
        height: 18px;
        margin: auto;
    }

    ${mediaQuery.sm} {
        width: 12px;
        height: 12px;
        margin: auto;
    }
`;

const MatchWrapper = styled.div<{}>`
    width: fit-content;
    height: fit-content;
    margin: auto;
    line-height: 25px;

    ${mediaQuery.md} {
        line-height: 18px;
    }

    ${mediaQuery.sm} {
        line-height: 12px;
    }
`;

export const PerformanceComponent: React.FC<Props> = ({ performances }) => {
    const [pageW, pageWSet] = useState<number>(window.innerWidth);
    const [screenXS, screenXSSet] = useState<boolean>(window.innerWidth < MediaSize.xs);
    const [screenSM, screenSMSet] = useState<boolean>(window.innerWidth < MediaSize.md);

    useEffect(() => {
        const handleResize = () => {
            pageWSet(window.innerWidth);
            screenXSSet(window.innerWidth < MediaSize.xs);
            screenSMSet(window.innerWidth < MediaSize.md);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <ComponentContainer>
                {performances && performances.length > 0
                    ? performances.map((performance, index) => {
                          return (
                              <MatchContainer key={index} $result={performance}>
                                  <MatchWrapper>{performance}</MatchWrapper>
                              </MatchContainer>
                          );
                      })
                    : null}
            </ComponentContainer>
        </>
    );
};
