import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import seatsImg from "../../img/seats.png";
import starImg from "../../img/star.png";
import { CssVariables } from "../../types/CustomTypes";
import { Liga2StadiumV2 } from "../../types/Liga2TypesV2";

interface Props {
    details: Liga2StadiumV2;
    alignItems?: string;
}

const ComponentContainer = styled.div<{}>`
    display: inline-block;
    width: fit-content;
    text-align: center;
    margin: auto;

    ${mediaQuery.md} {
        width: fit-content;
    }

    ${mediaQuery.sm} {
        width: fit-content;
    }
`;

const ComponentWrapper = styled.div<{}>`
    display: block;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const StadiumImg = styled.div<{ $imgUrl: string }>`
    height: 200px;
    width: 355px;
    display: inline-block;
    background-image: url(${(props) => (props.$imgUrl ? props.$imgUrl : null)});
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;

    ${mediaQuery.md} {
        width: 200px;
        height: 100px;
    }
    ${mediaQuery.sm} {
        width: 200px;
        height: 100px;
    }
`;
const StadiumName = styled.span<{}>`
    display: block;
    margin: auto;
    padding: 1px;
    width: fit-content;
    background-color: ${CssVariables.white};

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_xs};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_xs};
    }
`;
const StadiumSetails = styled.div<{}>`
    display: flex;
    width: fit-content;
    margin: auto;
`;

const StadiumRating = styled.div<{}>`
    margin: 10px 20px;

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_xs};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_xs};
    }
`;
const StadiumRatingImg = styled.div<{}>`
    width: 24px;
    height: 24px;
    display: flex;
    margin: auto;
    background-image: url(${starImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    justify-content: center;
    align-items: center;
    color: ${CssVariables.black};

    ${mediaQuery.md} {
        width: 18px;
        height: 18px;
    }

    ${mediaQuery.sm} {
        width: 18px;
        height: 18px;
    }
`;
const StadiumSpectators = styled.span<{}>`
    display: block;
    margin: auto;
    width: fit-content;
    margin: 10px 20px;

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_xs};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_xs};
    }
`;
const StadiumSpectatorsImg = styled.div<{}>`
    width: 55px;
    height: 24px;
    display: block;
    margin: auto;
    background-image: url(${seatsImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    ${mediaQuery.md} {
        width: 42px;
        height: 18px;
    }

    ${mediaQuery.sm} {
        width: 42px;
        height: 18px;
    }
`;

export const StadiumComponent: React.FC<Props> = ({ details, alignItems }) => {
    return (
        <ComponentContainer>
            <StadiumImg $imgUrl={details.imgUrl}></StadiumImg>
            <ComponentWrapper>
                <StadiumName>{details.name}</StadiumName>
                <StadiumSetails>
                    <StadiumRating>
                        <StadiumRatingImg></StadiumRatingImg>
                        {details.averageRating}
                    </StadiumRating>
                    <StadiumSpectators>
                        <StadiumSpectatorsImg></StadiumSpectatorsImg>
                        {details.spectators} - {details.averageSpectators}%
                    </StadiumSpectators>
                </StadiumSetails>
            </ComponentWrapper>
        </ComponentContainer>
    );
};
