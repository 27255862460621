import React, { useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import { getDdlItems, mediaQuery } from "../../helpers/Helpers";
import { CssVariables, Liga2WeeklyLeadersRechartType } from "../../types/CustomTypes";
import { Liga2TeamV2 } from "../../types/Liga2TypesV2";
import { CustomDropdown } from "../app/CustomDropdown";
import { Liga2Toggle } from "../app/Liga2Toggle";

interface Props {
    chartDataStandings: Liga2WeeklyLeadersRechartType[];
    chartDataPoints: Liga2WeeklyLeadersRechartType[];
    teamList: Liga2TeamV2[];
}

const ComponentContainer = styled.div<{}>`
    width: 100%;
    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const ActionsContainer = styled.div<{}>`
    width: 100%;
    display: flex;
    position: relative;
    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const ToggleContainer = styled.div<{}>`
    width: fit-content;
    margin: auto 0;
    ´ ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const ToggleWrapper = styled.div<{}>`
    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const DDLContainer = styled.div<{}>`
    margin: auto;
    display: flex;
    width: fit-content;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const DDLWrapper = styled.div<{ $borderColor?: string }>`
    display: flex;

    > div {
        ${(props) => (props.$borderColor ? `border: 1px solid ${props.$borderColor};` : null)}
        ${(props) => (props.$borderColor ? "padding: 0px;" : null)}
        ${(props) => (props.$borderColor ? "margin: 10px;" : null)}
    }

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const ChartContainer = styled.div<{}>`
    width: 100%;
    height: 80vh;
    margin: auto;
    max-height: 600px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

export const LeadersByWeek: React.FC<Props> = ({ chartDataStandings, teamList, chartDataPoints }) => {
    const [teamSelected, teamSelectedSet] = useState<number>(0);
    const [teamSelectedCompare, teamSelectedCompareSet] = useState<number>(0);
    const [compareToggle, compareToggleSet] = useState<boolean>(false);
    const [pointsToggle, pointsToggleSet] = useState<boolean>(false);

    function getDDLItems() {
        const _value: number[] = [];
        const _label: string[] = [];

        Object.keys(chartDataStandings[0]).forEach(async (element, index) => {
            if (index > 0) {
                const _team = teamList.find((t) => t.name === element);
                const _teamId: number = parseInt(_team.id);
                _value.push(_teamId);
                _label.push(_team.name);
            }
        });

        return getDdlItems(_value, _label, false);
    }

    function onchangeDDL(_value: number) {
        teamSelectedSet(_value);
    }

    function onchangeDDLCompare(_value: number) {
        teamSelectedCompareSet(_value);
    }

    function getTeamFromDDL() {
        return teamList.find((t) => t.id === teamSelected.toString());
    }
    function getTeamFromDDLCompare() {
        return teamList.find((t) => t.id === teamSelectedCompare.toString());
    }

    function compareToggleClick(_value: boolean) {
        compareToggleSet(_value);
    }

    function pointsToggleClick(_value: boolean) {
        pointsToggleSet(_value);
    }

    return (
        <>
            <ComponentContainer>
                <ActionsContainer>
                    <ToggleContainer>
                        <ToggleWrapper>
                            <Liga2Toggle text="Compare" onClickAction={compareToggleClick}></Liga2Toggle>
                        </ToggleWrapper>
                    </ToggleContainer>
                    <DDLContainer>
                        <DDLWrapper $borderColor={compareToggle ? CssVariables.main_color_darkgreen : null}>
                            <CustomDropdown selectAll="ALL" ddItems={getDDLItems()} onChangeDrop={onchangeDDL}></CustomDropdown>
                        </DDLWrapper>
                        <DDLWrapper $borderColor={compareToggle ? CssVariables.main_color_red : null}>
                            {compareToggle ? <CustomDropdown ddItems={getDDLItems()} onChangeDrop={onchangeDDLCompare}></CustomDropdown> : null}
                        </DDLWrapper>
                    </DDLContainer>
                    <ToggleContainer>
                        <ToggleWrapper>
                            <Liga2Toggle text="By points" onClickAction={pointsToggleClick}></Liga2Toggle>
                        </ToggleWrapper>
                    </ToggleContainer>
                </ActionsContainer>

                <ChartContainer>
                    {pointsToggle ? (
                        <>
                            {teamSelected == 0 ? (
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart width={600} height={300} data={chartDataPoints}>
                                        {Object.keys(chartDataPoints[0]).map((id, index) => {
                                            if (index > 0) return <Line key={index} dataKey={`${id}`} />;
                                        })}
                                        <XAxis dataKey="name" interval={0} />
                                        <YAxis interval={0} tickCount={18} domain={[1, 18]} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            ) : (
                                <ResponsiveContainer>
                                    <LineChart width={900} height={900} data={chartDataPoints}>
                                        <Line key={1} dataKey={getTeamFromDDL().name} stroke={CssVariables.main_color_darkgreen} />
                                        {teamSelectedCompare && compareToggle ? <Line key={2} dataKey={getTeamFromDDLCompare().name} stroke={CssVariables.main_color_red} /> : null}
                                        <XAxis dataKey="name" interval={0} />
                                        <YAxis interval={0} tickCount={18} domain={[1, 18]} />
                                        <CartesianGrid strokeDasharray="4 1 2" />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            )}
                        </>
                    ) : (
                        <>
                            {teamSelected == 0 ? (
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart width={600} height={300} data={chartDataStandings}>
                                        {Object.keys(chartDataStandings[0]).map((id, index) => {
                                            if (index > 0) return <Line key={index} dataKey={`${id}`} />;
                                        })}
                                        <XAxis dataKey="name" interval={0} />
                                        <YAxis reversed interval={0} tickCount={18} domain={[1, 18]} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            ) : (
                                <ResponsiveContainer>
                                    <LineChart width={900} height={900} data={chartDataStandings}>
                                        <Line key={1} dataKey={getTeamFromDDL().name} stroke={CssVariables.main_color_darkgreen} />
                                        {teamSelectedCompare && compareToggle ? <Line key={2} dataKey={getTeamFromDDLCompare().name} stroke={CssVariables.main_color_red} /> : null}
                                        <XAxis dataKey="name" interval={0} />
                                        <YAxis reversed interval={0} tickCount={18} domain={[1, 18]} />
                                        <CartesianGrid strokeDasharray="4 1 2" />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            )}
                        </>
                    )}
                </ChartContainer>
            </ComponentContainer>
        </>
    );
};
