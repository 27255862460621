import React from "react";
import styled from "styled-components";
import { getImgFromIdForTeamLiga2, getTeamFromIdLiga2, mediaQuery } from "../../helpers/Helpers";
import { Liga2TeamV2 } from "../../types/Liga2TypesV2";

interface Props {
    team?: string;
    hastext: string;
    hasclick: string;
    teamsList: Liga2TeamV2[];
    onClickAction?: (event: React.MouseEvent<HTMLDivElement>) => void;
    imageLeft: boolean;
    imgSize?: number;
}

const Container = styled.div<{ $isleft: boolean }>`
    background: #ffffff;
    width: auto;
    margin: ${(props) => (props.$isleft ? "auto 0 auto auto" : "auto auto auto 0")};
    overflow: auto;
    text-align: ${(props) => (props.$isleft ? "right" : "left")};
`;

const Wrapper = styled.div<{ $hasclick: string }>`
    background-color: #fff;
    margin: auto;
    cursor: ${(props) => (props.$hasclick === "true" ? "pointer" : "auto")};
    display: flex;

    ${mediaQuery.lg} {
        width: 165px;
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const TeamImg = styled.div<{ $img: string | undefined; $size: number | undefined }>`
    background-image: url(${(props) => (props.$img ? props.$img : null)});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: auto;

    ${mediaQuery.lg} {
        height: 45px;
        width: 45px;
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const TeamDescText = styled.span<{}>`
    margin: auto 5px;
    white-space: nowrap;

    ${mediaQuery.lg} {
        width: 110px;
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

export const TeamComponentInline: React.FC<Props> = ({ team, hastext, hasclick, teamsList, onClickAction, imageLeft, imgSize }) => {
    return (
        <>
            <Container $isleft={imageLeft}>
                {imageLeft ? (
                    <Wrapper $hasclick={hasclick} {...(hasclick && team && onClickAction && { onClick: onClickAction })}>
                        {team && hastext === "true" ? <TeamDescText>{getTeamFromIdLiga2(teamsList, team) ? getTeamFromIdLiga2(teamsList, team).name : "lalalala"}</TeamDescText> : null}
                        <TeamImg $size={imgSize} $img={getImgFromIdForTeamLiga2(teamsList, team.toString(), imgSize)}></TeamImg>
                    </Wrapper>
                ) : (
                    <Wrapper $hasclick={hasclick} {...(hasclick && team && onClickAction && { onClick: onClickAction })}>
                        <TeamImg $size={imgSize} $img={getImgFromIdForTeamLiga2(teamsList, team.toString(), imgSize)}></TeamImg>
                        {team && hastext === "true" ? <TeamDescText>{getTeamFromIdLiga2(teamsList, team) ? getTeamFromIdLiga2(teamsList, team).name : "lalalala"}</TeamDescText> : null}
                    </Wrapper>
                )}
            </Container>
        </>
    );
};
