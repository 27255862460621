import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getPlayerDetails } from "../../helpers/ApiResponseLiga2V2";
import { mediaQuery } from "../../helpers/Helpers";
import fieldImg from "../../img/football_field_new1.png";
import { MediaSize } from "../../types/CustomTypes";
import { Liga2FormationTeamV2, Liga2OccurrenceV2, Liga2ParticipantV2, Liga2PlayerDetailsV2 } from "../../types/Liga2TypesV2";
import { SofascorePlayerPosition, SofascoreTeamOfTheWeekPlayerV2, SofascoreTeamOfTheWeekV2 } from "../../types/SofaScoreTypes";
import { PlayerComponent } from "../player/PlayerComponent";

interface Props {
    playerlist?: SofascoreTeamOfTheWeekV2;
    ligaPlayerList?: Liga2FormationTeamV2;
    ligaPlayerSubsList?: Liga2ParticipantV2[];
    teamId?: string;
    isHome?: boolean;
    occurences?: Liga2OccurrenceV2[];
}

const ComponentContainer = styled.div<{}>`
    display: block;
    margin: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const FieldContainer = styled.div<{}>`
    width: 520px;
    height: 790px;
    background-image: url(${fieldImg});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    margin: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const FieldWrapper = styled.div<{}>`
    width: fit-content;
    margin: auto;
    height: 100%;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: calc(100% - 14px);
    }
`;

const PositionWrapper = styled.div<{}>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: auto;
`;

const SubsContainer = styled.div<{}>`
    width: 100%;
    display: flex;
`;
const SubsWrapper = styled.div<{}>`
    flex-grow: 1;
`;
const SubsUl = styled.ul<{}>`
    list-style: none;
    padding: 0;
`;
const SubsLi = styled.li<{}>`
    float: left;
    width: 20%;
`;

function getGK(playerlist: SofascoreTeamOfTheWeekV2): SofascoreTeamOfTheWeekPlayerV2 | undefined {
    const _gk = playerlist.players.find((g) => g.position === SofascorePlayerPosition.goalkeeper);
    return _gk;
}

function getDF(playerlist: SofascoreTeamOfTheWeekV2): SofascoreTeamOfTheWeekPlayerV2[] {
    const _df = playerlist.players.filter((g) => g.position === SofascorePlayerPosition.defender);
    return _df;
}

function getMF(playerlist: SofascoreTeamOfTheWeekV2): SofascoreTeamOfTheWeekPlayerV2[] {
    const _mf = playerlist.players.filter((g) => g.position === SofascorePlayerPosition.midfielder);
    return _mf;
}

function getFW(playerlist: SofascoreTeamOfTheWeekV2): SofascoreTeamOfTheWeekPlayerV2[] {
    const _fw = playerlist.players.filter((g) => g.position === SofascorePlayerPosition.forward);
    return _fw;
}

function getLigaGK(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2 | undefined {
    return getPlayerDetails(playerlist.participants[0].playerId!, teamId, playerlist);
}

function getLigaDF(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2[] {
    const numOfDefenders = +playerlist.formationDescription[0];
    const output: Liga2PlayerDetailsV2[] = [];

    for (let index = numOfDefenders; index > 0; index--) {
        output.push(getPlayerDetails(playerlist.participants[index].playerId!, teamId, playerlist));
    }

    return output;
}

function getLigaMF(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2[] {
    const numOfDefenders = +playerlist.formationDescription[0] + 1;
    const numOfMidfelders = +playerlist.formationDescription[1];
    const output: Liga2PlayerDetailsV2[] = [];

    for (let index = 0; index < numOfMidfelders; index++) {
        output.push(getPlayerDetails(playerlist.participants[numOfDefenders + index].playerId!, teamId, playerlist));
    }

    return output;
}

function getLigaMF1(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2[] {
    const numOfDefenders = +playerlist.formationDescription[0] + +playerlist.formationDescription[1] + 1;
    const numOfMidfelders = +playerlist.formationDescription[2];
    const output: Liga2PlayerDetailsV2[] = [];

    for (let index = 0; index < numOfMidfelders; index++) {
        output.push(getPlayerDetails(playerlist.participants[numOfDefenders + index].playerId!, teamId, playerlist));
    }

    return output;
}

function getLigaFW(playerlist: Liga2FormationTeamV2, teamId: string): Liga2PlayerDetailsV2[] {
    const numOfAtt = +playerlist.formationDescription[playerlist.formationDescription.length - 1];
    const output: Liga2PlayerDetailsV2[] = [];

    for (let index = 0; index < numOfAtt; index++) {
        output.push(getPlayerDetails(playerlist.participants[10 - index].playerId!, teamId, playerlist));
    }

    return output;
}

function getLigaSubs(_player: Liga2ParticipantV2, _teamId: string): Liga2PlayerDetailsV2 {
    const _output: Liga2PlayerDetailsV2 = {
        id: _player.id,
        number: _player.number,
        name: _player.name,
        fullName: _player.fullName,
        position: _player.position,
        nationality: _player.nationality,
        mediaUrl: _player.mediaUrl,
        mediaBodyUrl: _player.mediaBodyUrl,
        teamId: Number.parseInt(_teamId),
    };
    return _output;
}

export const FieldTeamComponent: React.FC<Props> = ({ playerlist, ligaPlayerList, teamId, ligaPlayerSubsList, isHome, occurences }) => {
    const [isMobile, isMobileWSet] = useState<boolean>(window.innerWidth < MediaSize.md);
    const [pageW, pageWSet] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            pageWSet(window.innerWidth);
            isMobileWSet(window.innerWidth < MediaSize.md);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            {playerlist ? (
                <>
                    {isMobile ? (
                        <>
                            <FieldContainer>
                                <FieldWrapper>
                                    <PositionWrapper key="GK">
                                        <PlayerComponent imgSize={10} borderRadius={true} playeroftheweek={getGK(playerlist)}></PlayerComponent>
                                    </PositionWrapper>
                                    <PositionWrapper key="DF">
                                        {getDF(playerlist)
                                            ? getDF(playerlist).map((_df, index) => {
                                                  return <PlayerComponent imgSize={10} borderRadius={true} key={index} playeroftheweek={_df}></PlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                    <PositionWrapper key="MF">
                                        {getMF(playerlist)
                                            ? getMF(playerlist).map((_mf, index) => {
                                                  return <PlayerComponent imgSize={10} borderRadius={true} key={index} playeroftheweek={_mf}></PlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                    <PositionWrapper key="FW">
                                        {getFW(playerlist)
                                            ? getFW(playerlist).map((_fw, index) => {
                                                  return <PlayerComponent imgSize={10} borderRadius={true} key={index} playeroftheweek={_fw}></PlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                </FieldWrapper>
                            </FieldContainer>
                        </>
                    ) : (
                        <>
                            <FieldContainer>
                                <FieldWrapper>
                                    <PositionWrapper key="GK">
                                        <PlayerComponent borderRadius={true} playeroftheweek={getGK(playerlist)}></PlayerComponent>
                                    </PositionWrapper>
                                    <PositionWrapper key="DF">
                                        {getDF(playerlist)
                                            ? getDF(playerlist).map((_df, index) => {
                                                  return <PlayerComponent borderRadius={true} key={index} playeroftheweek={_df}></PlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                    <PositionWrapper key="MF">
                                        {getMF(playerlist)
                                            ? getMF(playerlist).map((_mf, index) => {
                                                  return <PlayerComponent borderRadius={true} key={index} playeroftheweek={_mf}></PlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                    <PositionWrapper key="FW">
                                        {getFW(playerlist)
                                            ? getFW(playerlist).map((_fw, index) => {
                                                  return <PlayerComponent borderRadius={true} key={index} playeroftheweek={_fw}></PlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                </FieldWrapper>
                            </FieldContainer>
                        </>
                    )}
                </>
            ) : (
                <>
                    <ComponentContainer>
                        <SubsContainer>
                            <SubsUl>
                                {ligaPlayerSubsList
                                    ? ligaPlayerSubsList.map((subPlayer, index) => {
                                          return (
                                              <SubsLi key={index}>
                                                  <SubsWrapper>
                                                      <PlayerComponent occurences={occurences} isSub={true} playermatch={getLigaSubs(subPlayer, teamId)} imgSize={40}></PlayerComponent>
                                                  </SubsWrapper>
                                              </SubsLi>
                                          );
                                      })
                                    : null}
                            </SubsUl>
                        </SubsContainer>
                        <FieldContainer>
                            <FieldWrapper>
                                <PositionWrapper key="GK">
                                    <PlayerComponent occurences={occurences} playermatch={getLigaGK(ligaPlayerList, teamId)}></PlayerComponent>
                                </PositionWrapper>
                                <PositionWrapper key="DF">
                                    {getLigaDF(ligaPlayerList, teamId)
                                        ? getLigaDF(ligaPlayerList, teamId).map((_df, index) => {
                                              return <PlayerComponent occurences={occurences} key={index} playermatch={_df}></PlayerComponent>;
                                          })
                                        : null}
                                </PositionWrapper>
                                <PositionWrapper key="MF">
                                    {getLigaMF(ligaPlayerList, teamId)
                                        ? getLigaMF(ligaPlayerList, teamId).map((_mf, index) => {
                                              return <PlayerComponent occurences={occurences} key={index} playermatch={_mf}></PlayerComponent>;
                                          })
                                        : null}
                                </PositionWrapper>
                                {ligaPlayerList.formationDescription.length === 4 ? (
                                    <PositionWrapper key="MF1">
                                        {getLigaMF1(ligaPlayerList, teamId)
                                            ? getLigaMF1(ligaPlayerList, teamId).map((_mf, index) => {
                                                  return <PlayerComponent occurences={occurences} key={index} playermatch={_mf}></PlayerComponent>;
                                              })
                                            : null}
                                    </PositionWrapper>
                                ) : null}
                                <PositionWrapper key="FW">
                                    {getLigaFW(ligaPlayerList, teamId)
                                        ? getLigaFW(ligaPlayerList, teamId).map((_fw, index) => {
                                              return <PlayerComponent occurences={occurences} key={index} playermatch={_fw}></PlayerComponent>;
                                          })
                                        : null}
                                </PositionWrapper>
                            </FieldWrapper>
                        </FieldContainer>
                    </ComponentContainer>
                </>
            )}
        </>
    );
};
