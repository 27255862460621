import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { HeaderComponent } from "./components/app/HeaderComponent";
import { ManageLiga2V2TeamsSessionData } from "./helpers/ApiResponseLiga2V2";
import Home from "./pages/Home";
import MatchDetailsPage from "./pages/MatchDetails";
import RefDetailsPage from "./pages/RefDetails";
import TeamDetailsPage from "./pages/TeamDetails";
import "./styles/app.scss";
import { Liga2TeamV2 } from "./types/Liga2TypesV2";

function App() {
    const [liga2TeamList, liga2TeamListSet] = useState<Liga2TeamV2[] | undefined>(undefined);

    useEffect(() => {
        const getData = async () => {
            const liga2Teams = await ManageLiga2V2TeamsSessionData();
            liga2TeamListSet(liga2Teams);
        };

        getData();

        return () => {};
    }, []);

    return (
        <>
            <div className="appLiga2header">{liga2TeamList ? <HeaderComponent teamsList={liga2TeamList}></HeaderComponent> : null}</div>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/teamdetails" element={<TeamDetailsPage />} />
                <Route path="/refdetails" element={<RefDetailsPage />} />
                <Route path="/matchdetails" element={<MatchDetailsPage />} />
            </Routes>
        </>
    );
}

export default App;
