import React from "react";
import styled from "styled-components";
import { getImgFromIdForTeamLiga2, getTeamFromIdLiga2 } from "../../helpers/Helpers";
import { Liga2TeamV2 } from "../../types/Liga2TypesV2";

interface Props {
    team?: string;
    hastext: string;
    hasclick: string;
    teamsList: Liga2TeamV2[];
    onClickAction?: (event: React.MouseEvent<HTMLDivElement>) => void;
    imgSize?: number;
    showTitle?: boolean;
}

const Container = styled.div<{}>`
    background: #ffffff;
    width: auto;
    padding: 5px;
    display: inline-block;
    margin: auto;
`;

const Wrapper = styled.div<{ $hasclick: string }>`
    background-color: #fff;
    margin: auto;
    cursor: ${(props) => (props.$hasclick === "true" ? "pointer" : "auto")};
`;

const TeamImg = styled.div<{ $img: string | undefined; $size: number | undefined }>`
    background-image: url(${(props) => (props.$img ? props.$img : null)});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: ${(props) => (props.$size ? props.$size + "px" : null)};
    width: ${(props) => (props.$size ? props.$size + "px" : null)};
    margin: auto;
`;

const TeamDescText = styled.span<{}>`
    margin: auto;
`;

export const TeamComponent: React.FC<Props> = ({ team, hastext, hasclick, teamsList, onClickAction, imgSize, showTitle }) => {
    return (
        <>
            <Container title={showTitle ? getTeamFromIdLiga2(teamsList, team).name : null}>
                <Wrapper $hasclick={hasclick} {...(hasclick && team && onClickAction && { onClick: onClickAction })}>
                    <TeamImg $size={imgSize} $img={getImgFromIdForTeamLiga2(teamsList, team.toString(), imgSize)}></TeamImg>

                    {team && hastext === "true" ? <TeamDescText>{getTeamFromIdLiga2(teamsList, team).name}</TeamDescText> : null}
                </Wrapper>
            </Container>
        </>
    );
};
