import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import redCard from "../../img/redcard.svg";
import yellowCard from "../../img/yellowcard.svg";
import { CssVariables, MediaSize } from "../../types/CustomTypes";
import { Liga2TeamV2, Liga2V2RefMatchesV2 } from "../../types/Liga2TypesV2";
import { MatchComponent } from "../league/MatchComponent";
import { MatchComponentTable } from "../league/MatchComponentTable";

interface Props {
    matchList: Liga2V2RefMatchesV2;
    teamsList: Liga2TeamV2[];
}

const ComponentContainer = styled.div<{}>`
    padding: 15px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const ComponentWrapper = styled.div<{}>``;

const MatchWrapper = styled.div<{}>``;
const TextSpan = styled.span<{}>`
    padding: 5px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_lg};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_md};
    }
`;

const Table = styled.table<{}>`
    border-spacing: 0px;
    border-collapse: collapse;
`;

const Thead = styled.thead<{}>``;

const Th = styled.th<{}>`
    min-width: 60px;
`;

const Tbody = styled.tbody<{}>``;

const Tr = styled.tr<{}>``;

const Td = styled.td<{}>`
    text-align: center;
    border: 1px solid blue;
    padding: 5px;
`;

const YellowCardImg = styled.div<{}>`
    width: 16px;
    height: 30px;
    background-image: url(${yellowCard});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
`;

const RedCardImg = styled.div<{}>`
    width: 16px;
    height: 30px;
    background-image: url(${redCard});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
`;

const DoubleYellowCardWrapper = styled.div<{}>`
    display: flex;
    margin: auto;
    width: fit-content;
`;

const MatchlistContatinerResp = styled.div<{}>`
    display: block;
    margin: 30px 0;
`;

const MatchlistWrapperResp = styled.div<{}>`
    margin: auto;
    width: fit-content;
`;

export const RefHistoryComponent: React.FC<Props> = ({ matchList, teamsList }) => {
    const [isMobileM, isMobileMSet] = useState<boolean>(window.innerWidth < MediaSize.md);
    const [isMobileS, isMobileSSet] = useState<boolean>(window.innerWidth < MediaSize.sm);
    const [isMobileXS, isMobileXSSet] = useState<boolean>(window.innerWidth < MediaSize.xs);

    useEffect(() => {
        const handleResize = () => {
            isMobileMSet(window.innerWidth < MediaSize.md);
            isMobileSSet(window.innerWidth < MediaSize.sm);
            isMobileXSSet(window.innerWidth < MediaSize.xs);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <>
            <ComponentContainer>
                <ComponentWrapper>
                    {isMobileM ? (
                        <>
                            {matchList
                                ? matchList.fixtures.map((match, index) => {
                                      return (
                                          <>
                                              {isMobileS ? (
                                                  <>
                                                      {isMobileXS ? (
                                                          <>
                                                              <MatchlistContatinerResp>
                                                                  <MatchlistWrapperResp>
                                                                      <TextSpan>{match.competitionName}</TextSpan>
                                                                      <TextSpan>-</TextSpan>
                                                                      <TextSpan>{match.matchweekNumber}</TextSpan>
                                                                  </MatchlistWrapperResp>
                                                                  <MatchlistWrapperResp>
                                                                      <MatchComponent match={match} matchweek={match.matchweekNumber} teamsList={teamsList} imgSize={30}></MatchComponent>
                                                                  </MatchlistWrapperResp>
                                                              </MatchlistContatinerResp>
                                                          </>
                                                      ) : (
                                                          <MatchlistContatinerResp>
                                                              <MatchlistWrapperResp>
                                                                  <TextSpan>{match.competitionName}</TextSpan>
                                                                  <TextSpan>{match.matchweekNumber}</TextSpan>
                                                              </MatchlistWrapperResp>
                                                              <MatchlistWrapperResp>
                                                                  <MatchComponent match={match} matchweek={match.matchweekNumber} teamsList={teamsList} imgSize={40}></MatchComponent>
                                                              </MatchlistWrapperResp>{" "}
                                                          </MatchlistContatinerResp>
                                                      )}
                                                  </>
                                              ) : (
                                                  <MatchlistContatinerResp>
                                                      <MatchlistWrapperResp>
                                                          <TextSpan>{match.competitionName}</TextSpan>
                                                          <TextSpan>{match.matchweekNumber}</TextSpan>
                                                      </MatchlistWrapperResp>
                                                      <MatchlistWrapperResp>
                                                          <MatchComponent match={match} matchweek={match.matchweekNumber} teamsList={teamsList} imgSize={50}></MatchComponent>
                                                      </MatchlistWrapperResp>{" "}
                                                  </MatchlistContatinerResp>
                                              )}
                                          </>
                                      );
                                  })
                                : null}
                        </>
                    ) : (
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th></Th>
                                    <Th></Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {matchList
                                    ? matchList.fixtures.map((match, index) => {
                                          return (
                                              <Tr key={index}>
                                                  <Td>
                                                      <TextSpan>{match.competitionName}</TextSpan>
                                                  </Td>
                                                  <Td>
                                                      <TextSpan>{match.matchweekNumber}</TextSpan>
                                                  </Td>
                                                  <Td>
                                                      <MatchComponentTable match={match} teamsList={teamsList}></MatchComponentTable>
                                                  </Td>
                                              </Tr>
                                          );
                                      })
                                    : null}
                            </Tbody>
                        </Table>
                    )}
                </ComponentWrapper>
            </ComponentContainer>
        </>
    );
};
