import { MediaSize } from "../types/CustomTypes";

const LIGA2_LOCALURL = "http://localhost:8000";
const LIGA2_FLYIOURL = "https://ligaportugal2api.fly.dev";
export const LIGA2_USECACHE: boolean = true;

export const SOFASCORE__URL = "https://www.sofascore.com/api/v1";
export const LIGA2_URL = LIGA2_FLYIOURL;

export const GOOGLE_API = "https://maps.googleapis.com/maps/api/geocode/json?address={0}&key=AIzaSyBoNltJiR2OKiOcrRYMqRIJU-n3pslD6SU";

export const apiDelay = 500;
export const isPhoneScreen = window.innerWidth < MediaSize.xs;
export const isTabletScreen = window.innerWidth < MediaSize.md && window.innerWidth >= MediaSize.xs;
export const isDesktopScreen = window.innerWidth >= MediaSize.md;
export const dateFormatWithTime = "YYYY-MM-DD HH:mm:ss";
export const dateFormatDateOnly = "YYYY-MM-DD";
export const dateFormatMatchComponent = "ddd DD/MMM HH:mm";
export const dateFormatMatchComponentSmaller = "YYYY-MM-DD";

//#region API list for sofascore:
// {COUNTRYID} - portugal - 44
export const SOFASCORE_TOURNAMENTS = "/category/44/unique-tournaments";
// {TOURNAMENTID} - 239
export const SOFASCORE_TOURNAMENTSEASONS = "/unique-tournament/239/seasons";
// {SEASONID}
export const SOFASCORE_TOURNAMENTSTANDINGS = "/unique-tournament/239/season/{0}/standings/total";
// {SEASONID} {PAGE}
export const SOFASCORE_TOURNAMENTEVENTSBEFORE = "/unique-tournament/239/season/{0}/events/last/{1}";
// {SEASONID} {PAGE}
export const SOFASCORE_TOURNAMENTEVENTSAFTER = "/unique-tournament/239/season/{0}/events/next/{1}";
// {EVENTID}
export const SOFASCORE_EVENTDETAILS = "/event/{0}";
// {EVENTID}
export const SOFASCORE_EVENTLINEUPS = "/event/{0}/lineups";
// {EVENTID}
export const SOFASCORE_EVENTSTATISTICS = "/event/{0}/statistics";
// {EVENTID} {PLAYERID}
export const SOFASCORE_EVENTPLAYERSTATISTICS = "/event/{0}/player/{1}/statistics";

// teamstats - https://www.sofascore.com/api/v1/team/5136/unique-tournament/239/season/63676/statistics/overall

export const SOFASCORE_TEAMOFTHEWEEK_ROUNDS = "/unique-tournament/239/season/{0}/team-of-the-week/rounds";
export const SOFASCORE_TEAMOFTHEWEEK = "/unique-tournament/239/season/{0}/team-of-the-week/{1}";
export const SOFASCORE_PLAYER_IMAGE = "/player/{0}/image";
export const SOFASCORE_TEAM_IMAGE = "/team/{0}/image";
//#endregion API list for sofascore:

//#region API list for liga2 scraping:
//
export const LIGA2_ROOT = "http://www.ligaportugal.pt";
export const LIGA2_STANDINGS = "/standings";
//
export const LIGA2_CURRENTWEEK = "/currentweek";
//
export const LIGA2_TEAMS = "/teams";
export const LIGA2_TEAMSV2 = "/teamsv2";
//
export const LIGA2_CALENDAR = "/calendar";
// {matchday}
export const LIGA2_MATCHREFS = "/matchrefs";
// {id} {season}
export const LIGA2_REFHISTORY = "/refhistory";
// {id}
export const LIGA2_TEAMDETAILS = "/teamdetails";
//
export const LIGA2_TEAMSIMAGES = "/teamsimages";
//
export const LIGA2_TEAMSIMAGESV2 = "/teamsimagesv2";
//
export const LIGA2_TEAMMATCHES = "/teammatches";
//
export const LIGA2_MATCHIDSTATS = "/matchidstats";
//
export const LIGA2_MATCHIDLIST = "/matchid";
//
export const LIGA2_LEAGUEPLAYERSTATS = "/leagueplayerstats";
//
export const LIGA2_SPECTATORHISTORY = "/spectatorhistory";

export const LIGA2_MATCHDETAILS_STATIC = "/matchstatic";
export const LIGA2_MATCHDETAILS_DYNAMIC = "/matchdynamic";
//export const LIGA2_WEEKDAY_STATS = "https://www.ligaportugal.pt/pt/liga/estatisticasjornada/epoca/20242025/competicao/2/jornada/{0}";
//#endregion API list for liga2 scraping:

//#region API list for liga2 official:
export const LIGA2OFFICIAL_STANDINGS = "/liga2official_standings";
export const LIGA2OFFICIAL_STANDINGS_BY_ROUND = "/liga2official_standings_byround";
export const LIGA2OFFICIAL_TEAMS = "/liga2official_teams";
export const LIGA2OFFICIAL_ROUNDS = "/liga2official_rounds";
export const LIGA2OFFICIAL_TEAM_STATISTICS = "/liga2official_teamstatistics";
export const LIGA2OFFICIAL_TEAM_SQUAD = "/liga2official_teamsquad";
export const LIGA2OFFICIAL_TEAM_MATCHES = "/liga2official_teammatches";
export const LIGA2OFFICIAL_SEASONS = "/liga2official_seasons";
export const LIGA2OFFICIAL_ROUND_MATCHES = "/liga2official_roundmatches";
export const LIGA2OFFICIAL_MATCH_DETAILS = "/liga2official_matchdetails";
export const LIGA2OFFICIAL_MATCH_STATISTICS = "/liga2official_matchdynamic";
export const LIGA2OFFICIAL_MATCH_FORMATIONS = "/liga2official_matchformations";
export const LIGA2OFFICIAL_MATCH_H2H = "/liga2official_matchh2h";
export const LIGA2OFFICIAL_NOMINATIONS = "/liga2official_nominations";
export const LIGA2OFFICIAL_REFDETAILS = "/liga2official_refdetails";
export const LIGA2OFFICIAL_REFMATCHES = "/liga2official_refmatches";

export const LIGA2OFFICIAL_SOFASCORE_TEAMOFTHEWEEK = "/liga2official_sofascore_teamoftheweek";

export const LIGA2OFFICIAL_COMPETITIONID = "ligaportugalmeusuper";
export const LIGA2OFFICIAL_COMPETITIONID1 = "ligaportugalbetclic";
export const LIGA2OFFICIAL_COMPETITIONID2 = "allianzcup";
export const LIGA2OFFICIAL_SEASONID = "20242025";
export const LIGA2OFFICIAL_PLAYERIMAGEDEFAULT = "https://www.ligaportugal.pt/media/players/placeholder.png";

//#endregion API list for liga2 official:
