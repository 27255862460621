import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import imgAss from "../../img/refteam/assistant_flag.png";
import imgDel from "../../img/refteam/briefcase.png";
import imgObs from "../../img/refteam/eye.png";
import imgFou from "../../img/refteam/fourth.png";
import imgVar from "../../img/refteam/var.png";
import imgRef from "../../img/refteam/whistle.png";
import { CssVariables, Liga2RefTypeV2 } from "../../types/CustomTypes";
import { Liga2V2MatchRefsV2 } from "../../types/Liga2TypesV2";

interface Props {
    refMatch: Liga2V2MatchRefsV2;
}

const ComponentContainer = styled.div<{}>`
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;
const ComponentWrapper = styled.div<{}>`
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const RefContainer = styled.div<{}>`
    margin: 10px;
    display: flex;
    align-items: center;
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;
const RefWrapper = styled.div<{}>`
    display: block;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;
const RefTitle = styled.div<{ $refType: Liga2RefTypeV2 }>`
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 40px;
    width: 40px;

    ${(props) => (props.$refType === Liga2RefTypeV2.A ? `background-image: url(${imgAss});` : null)};
    ${(props) => (props.$refType === Liga2RefTypeV2.D ? `background-image: url(${imgDel});` : null)};
    ${(props) => (props.$refType === Liga2RefTypeV2.O ? `background-image: url(${imgObs});` : null)};
    ${(props) => (props.$refType === Liga2RefTypeV2.Q ? `background-image: url(${imgFou});` : null)};
    ${(props) => (props.$refType === Liga2RefTypeV2.R ? `background-image: url(${imgRef});` : null)};
    ${(props) => (props.$refType === Liga2RefTypeV2.V ? `background-image: url(${imgVar});` : null)};

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        height: 30px;
        width: 30px;
    }
`;

const RefName = styled.span<{}>`
    margin-left: 10px;
    display: block;
    font-size: ${CssVariables.font_size_xl};

    ${mediaQuery.md} {
        font-size: ${CssVariables.font_size_lg};
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_md};
    }
`;

export const RefereeMatchComponent: React.FC<Props> = ({ refMatch }) => {
    return (
        <>
            {refMatch ? (
                <ComponentContainer>
                    <ComponentWrapper>
                        <RefContainer>
                            <RefTitle $refType={Liga2RefTypeV2.R}></RefTitle>
                            <RefWrapper>
                                <RefName>{refMatch.refereeName}</RefName>
                            </RefWrapper>
                        </RefContainer>
                        <RefContainer>
                            <RefTitle $refType={Liga2RefTypeV2.A}></RefTitle>
                            <RefWrapper>
                                <RefName>{refMatch.firstAssistantRefereeName}</RefName>
                                <RefName>{refMatch.secondAssistantRefereeName}</RefName>
                            </RefWrapper>
                        </RefContainer>
                        <RefContainer>
                            <RefTitle $refType={Liga2RefTypeV2.Q}></RefTitle>
                            <RefWrapper>
                                <RefName>{refMatch.fourthRefereeName}</RefName>
                            </RefWrapper>
                        </RefContainer>
                        <RefContainer>
                            <RefTitle $refType={Liga2RefTypeV2.V}></RefTitle>
                            <RefWrapper>
                                <RefName>{refMatch.varName}</RefName>
                                <RefName>{refMatch.aVarName}</RefName>
                            </RefWrapper>
                        </RefContainer>
                        <RefContainer>
                            <RefTitle $refType={Liga2RefTypeV2.D}></RefTitle>
                            <RefWrapper>
                                <RefName>{refMatch.firstDelegateName}</RefName>
                                {refMatch.secondDelegateName ? (
                                    <>
                                        <RefName>{refMatch.secondDelegateName}</RefName>
                                    </>
                                ) : null}
                            </RefWrapper>
                        </RefContainer>
                        <RefContainer>
                            <RefTitle $refType={Liga2RefTypeV2.O}></RefTitle>
                            <RefWrapper>
                                <RefName>{refMatch.firstObserverName}</RefName>
                                {refMatch.secondObserverName ? (
                                    <>
                                        <RefName>{refMatch.secondObserverName}</RefName>
                                    </>
                                ) : null}
                            </RefWrapper>
                        </RefContainer>
                    </ComponentWrapper>
                </ComponentContainer>
            ) : null}
        </>
    );
};
