import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { CssVariables } from "../../types/CustomTypes";
import { Liga2RefereeV2 } from "../../types/Liga2TypesV2";

interface Props {
    dataRef: Liga2RefereeV2;
    hasClick: boolean;
}

const ComponentContainer = styled.div<{}>`
    padding: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 0px;
        width: 100%;
    }
`;

const ComponentBorder = styled.div<{ $refid: boolean; $canClick: boolean }>`
    padding: 5px;
    border: ${(props) => (props.$refid ? `1px solid ${CssVariables.main_color_lightgreen}` : null)};
    box-shadow: ${(props) => (props.$refid ? "2px 2px 2px red" : null)};
    cursor: ${(props) => (props.$refid && props.$canClick ? "pointer" : null)};
`;

const ComponentWrapper = styled.div<{}>`
    width: ${CssVariables.ref_height * 1.5}px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        width: 100%;
        height: auto;
    }

    ${mediaQuery.sm} {
        width: 100%;
        height: auto;
    }
`;

const RefName = styled.span<{}>`
    display: inline-block;
    margin: 10px 0px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
    }
`;

const RefImg2 = styled.img<{}>`
    margin: auto;
    display: block;
    max-height: ${CssVariables.ref_height}px;
    width: auto;
    height: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
        width: 100%;
        height: auto;
        max-height: none;
    }

    ${mediaQuery.sm} {
        width: 100%;
        height: auto;
        max-height: none;
    }
`;

function refClick(_refId: string): void {
    var url = new URL("/refdetails", window.location.href);
    url.searchParams.set("id", _refId);
    window.location.href = url.href;
}

export const RefereeComponent: React.FC<Props> = ({ dataRef, hasClick }) => {
    return (
        <ComponentContainer onClick={() => (dataRef.id && hasClick ? refClick(dataRef.id.toString()) : null)}>
            <ComponentBorder $canClick={hasClick} $refid={dataRef.id ? true : false}>
                <ComponentWrapper>
                    <RefImg2 referrerPolicy="no-referrer" src={dataRef.mediaUrl}></RefImg2>
                    {dataRef.name ? <RefName>{dataRef.name}</RefName> : null}
                </ComponentWrapper>
            </ComponentBorder>
        </ComponentContainer>
    );
};
